import styled from '@emotion/styled';

import TopContentUnits from 'features/ContentUsage/components/TopContentUnits';
import TotalContentUsage from 'features/ContentUsage/components/TotalContentUsage';
import CategoriesUsage from 'features/ContentUsage/components/CategoriesUsage';
import SubcategoriesUsage from 'features/ContentUsage/components/SubcategoriesUsage';
import TopLists from 'features/ContentUsage/components/TopLists';
import { BREAKPOINTS } from 'theme/constants';
import { useTitles } from 'navigation/hooks';

const Wrapper = styled.div({
  marginBottom: 96,
});

const RowContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: 24,
  [BREAKPOINTS.XXL_MOBILE]: {
    flexDirection: 'column',
  },
});

const StyledCategoriesUsage = styled(CategoriesUsage)({
  flex: 1,
  marginLeft: 20,
  [BREAKPOINTS.XXL_MOBILE]: {
    marginLeft: 0,
    marginTop: 24,
  },
});

const StyledSubcategoriesUsage = styled(SubcategoriesUsage)({
  flex: 2,
});

const ContentUsage: React.FC = () => {
  useTitles('Content usage', 'Total spent viewing content');

  return (
    <Wrapper>
      <TopContentUnits />
      <TotalContentUsage />
      <RowContainer>
        <StyledSubcategoriesUsage />
        <StyledCategoriesUsage />
      </RowContainer>
      <RowContainer>
        <TopLists />
      </RowContainer>
    </Wrapper>
  );
};

export default ContentUsage;
