import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as MemberSettingsDots } from 'assets/team_settings.svg';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { ROLE } from 'features/Licensees/types';
import { getTeamMemberRoleByUid } from 'features/TeamMembers/selectors';

import MemberActionPopup from './MemberActionPopup';
import { getActionPopupVisibleUid } from '../selectors';
import {
  handleChangeRoleClick,
  handleRemoveTeamMemberClick,
  setActionPopupVisibleUid,
} from '../actions';

interface IOwnProps {
  uid: string;
}

interface IMapStateToProps {
  changeRoleText: string;
  isRoleActionVisible: boolean;
  isPopupVisible: boolean;
}

interface IMapDispatchToProps {
  onRemoveMember: (userUid: string, teamUid: string) => void;
  onTeamAdminClick: (userUid: string, teamUid: string) => void;
  onClick: () => void;
  onOutsideClick: () => void;
}

type TMemberAction = IMapDispatchToProps & IOwnProps & IMapStateToProps;

const MemberSettingsButton = styled.div({
  margin: 0,
  padding: '10px 10px',
  position: 'relative',
  cursor: 'pointer',
  display: 'inline-block',
});

const MemberAction: React.FC<TMemberAction> = ({
  onRemoveMember,
  onTeamAdminClick,
  onClick,
  onOutsideClick,
  uid,
  isRoleActionVisible,
  changeRoleText,
  isPopupVisible,
}) => {
  const { teamUid } = useParams();

  if (!teamUid) {
    return null;
  }

  return (
    <MemberSettingsButton onClick={onClick}>
      <MemberSettingsDots />
      <MemberActionPopup
        isVisible={isPopupVisible}
        onOutsideClick={onOutsideClick}
        onRemoveMember={() => onRemoveMember(uid, teamUid)}
        onTeamAdminClick={() => onTeamAdminClick(uid, teamUid)}
        isRoleActionVisible={isRoleActionVisible}
        changeRoleText={changeRoleText}
      />
    </MemberSettingsButton>
  );
};

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => {
  const role = getTeamMemberRoleByUid(state, uid);

  return {
    isPopupVisible: getActionPopupVisibleUid(state) === uid,
    isRoleActionVisible: role !== ROLE.OWNER,
    changeRoleText:
      role === ROLE.MEMBER ? 'Set as team lead' : 'Set as team member',
  };
};

const mapDispatchToProps = (dispatch: TDispatch, { uid }: IOwnProps) => ({
  onClick: () => dispatch(setActionPopupVisibleUid(uid)),
  onOutsideClick: () => dispatch(setActionPopupVisibleUid('')),
  onRemoveMember: (userUid: string, teamUid: string) =>
    dispatch(handleRemoveTeamMemberClick(userUid, teamUid)),
  onTeamAdminClick: (userUid: string, teamUid: string) =>
    dispatch(handleChangeRoleClick(userUid, teamUid)),
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(MemberAction);
