import styled from '@emotion/styled';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import PaymentForm from './PaymentForm';

import Text from 'components/Text';
import visa from 'assets/pngs/visa.png';
import mastercard from 'assets/pngs/mastercard.png';
import american_express from 'assets/pngs/american_express.png';

const Title = styled(Text)({
  marginTop: 32,
});

const ImagesContainer = styled.div({
  display: 'flex',
  marginTop: 12,
  marginBottom: 24,
});

const StyledImage = styled.img({
  marginRight: 20,
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC as string);

const Purchase: React.FC = () => (
  <Elements stripe={stripePromise}>
    <Title type="h1">Payment Details</Title>
    <ImagesContainer>
      <StyledImage src={visa} />
      <StyledImage src={mastercard} />
      <StyledImage src={american_express} />
    </ImagesContainer>
    <PaymentForm />
  </Elements>
);

export default Purchase;
