import { CATEGORIES, PERIODS } from 'utils/constants';

export interface IContentUsageApiResponse {
  date: string;
  play_time_seconds: number;
  play_count: number;
}

export interface ICategoryUsageApiResponse {
  category: CATEGORIES;
  play_time_seconds: number;
  play_count: number;
}

export interface ISubcategoryUsageApiResponse {
  sub_category: string;
  play_time_seconds: number;
  play_count: number;
}

export interface IPlaylistsUsageApiResponse {
  playlist: string;
  play_time_seconds: number;
  play_count: number;
}

export interface IMediaUsageApiResponse {
  media: string;
  play_time_seconds: number;
  play_count: number;
}

export type TMediaUsage = IMediaUsageApiResponse & { play_time: number };

export interface IContentUsage {
  contentUsagePeriod: PERIODS;
  categoryForSubcategories: TCategorySelect;
}

export enum CATEGORIES_EXTRA_OPTIONS {
  MOST_POPULAR = 'Most popular',
}

export type TCategorySelect = CATEGORIES | CATEGORIES_EXTRA_OPTIONS;

export type TPlaylistType = 'playlist' | 'course';
