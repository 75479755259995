import { createSelector } from 'reselect';

import { ALL_TEAMS, NAME } from './constants';

import { getTeamsObjectValues } from 'features/ManageTeams/selectors';
import { TNormalizedTeam } from 'features/ManageTeams/types';
import { IReduxState } from 'state/types';

export const getSelectedTeam = (state: IReduxState) => {
  const selectedTeam = state?.[NAME]?.selectedTeam;

  if (selectedTeam === 'all') {
    return undefined;
  }

  return selectedTeam;
};

export const getTeamOptions = createSelector(
  getTeamsObjectValues,
  (teams: TNormalizedTeam[]) => [
    ALL_TEAMS,
    ...teams.map((team) => ({
      value: team.uid,
      label: team.name,
    })),
  ]
);
