import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import Pagination from './Pagination';
import { BREAKPOINTS } from 'theme/constants';

export interface IHeader {
  name: keyof IDataItem;
  component: string | JSX.Element;
}

export interface IDataItem {
  [key: string]: JSX.Element | string;
  key: string;
}

interface ITable {
  header: IHeader[];
  data: IDataItem[];
  isLoading?: boolean;
  maxRows?: number;
}

const StyledTable = styled.table({
  width: '100%',
  borderCollapse: 'collapse',
});

const HeaderRow = styled.tr((props) => ({
  backgroundColor: props.theme.colors.formBackgroundGray,
  borderBottom: `1px solid rgba(138, 147, 159, 0.15)`,
  height: 60,
}));

const ComponentStyle = () => css`
  white-space: nowrap;
  padding: 0 2px;

  &:first-of-type {
    padding-left: 45px;
  }

  &:last-child {
    padding-right: 45px;
  }

  ${BREAKPOINTS.XL_TABLET} {
    &:first-of-type {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }
`;

const HeaderComponent = styled.th`
  ${ComponentStyle}
`;

const RowComponent = styled.td`
  padding: 5px 0;
  ${ComponentStyle}
`;

const ContentRow = styled.tr((props) => ({
  backgroundColor: props.theme.colors.formBackgroundGray,
  borderBottom: `1px solid rgba(138, 147, 159, 0.15)`,
  height: 68,
  ':hover': {
    backgroundColor: props.theme.colors.tableBackgroundGray,
  },
}));

const MessageContainer = styled.td((props) => ({
  backgroundColor: props.theme.colors.formBackgroundGray,
  textAlign: 'center',
  padding: '20px 0',
}));

MessageContainer.defaultProps = { colSpan: 3 };

const Table: (props: ITable) => React.ReactElement<ITable> = ({
  header,
  data,
  isLoading,
  maxRows = 10,
}) => {
  const pages = Math.ceil(data.length / maxRows);
  const shouldShowPagination = pages > 1;

  const [activePage, setActivePage] = useState(1);
  const [dataIndexStart, setDataIndexStart] = useState(0);
  const [dataIndexEnd, setDataIndexEnd] = useState(maxRows);

  const handleNextClick = () => {
    setDataIndexStart(dataIndexStart + maxRows);
    setDataIndexEnd(dataIndexEnd + maxRows);
    setActivePage(activePage + 1);
  };

  const handleBackClick = () => {
    setDataIndexStart(dataIndexStart - maxRows);
    setDataIndexEnd(dataIndexEnd - maxRows);
    setActivePage(activePage - 1);
  };

  useEffect(() => {
    setActivePage(1);
    setDataIndexStart(0);
    setDataIndexEnd(maxRows);
  }, [data.length]);

  return (
    <>
      <StyledTable>
        <thead>
          <HeaderRow>
            {header.map((col) => (
              <HeaderComponent key={col.name as string}>
                {col.component}
              </HeaderComponent>
            ))}
          </HeaderRow>
        </thead>
        <tbody>
          {!isLoading &&
            data.slice(dataIndexStart, dataIndexEnd).map((val) => (
              <ContentRow key={val.key}>
                {header.map((col) => (
                  <RowComponent key={col.name as string}>
                    {val[col.name]}
                  </RowComponent>
                ))}
              </ContentRow>
            ))}
          {!data.length && (
            <tr>
              <MessageContainer colSpan={header.length}>
                <Text align="center" weight="bold">
                  No Data
                </Text>
              </MessageContainer>
            </tr>
          )}
        </tbody>
      </StyledTable>
      <Pagination
        isVisible={shouldShowPagination && !isLoading}
        onNextClick={handleNextClick}
        onBackClick={handleBackClick}
        pages={pages}
        activePage={activePage}
        rowsPerPage={maxRows}
        amountOfRecords={data.length}
      />
    </>
  );
};

export default Table;
