import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { callCoreApi, METHODS, API_URLS } from 'api';

import {
  IVerifyResetPasswordTokenRequest,
  IResetPasswordRequest,
  IResetPasswordResponse,
} from './types';

export const useVerifyResetPasswordToken = () =>
  useMutation<unknown, AxiosError<IResetPasswordResponse>, string>({
    mutationFn: (token) =>
      callCoreApi<IVerifyResetPasswordTokenRequest>({
        method: METHODS.POST,
        url: API_URLS.VERIFY_PASSWORD_RESET_TOKEN,
        data: { token },
      }),
  });

export const useResetPassword = () =>
  useMutation<
    unknown,
    AxiosError<IResetPasswordResponse>,
    IResetPasswordRequest
  >({
    mutationFn: ({ password, token }) =>
      callCoreApi<IResetPasswordRequest>({
        method: METHODS.POST,
        url: API_URLS.RESET_PASSWORD,
        data: { password, token },
      }),
  });
