import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ReactComponent as TopCategory } from 'assets/contentUsage/top_category_list.svg';
import { ReactComponent as TopPlaylist } from 'assets/contentUsage/top_playlist_list.svg';
import { ReactComponent as TopContentUnit } from 'assets/contentUsage/top_content_unit_list.svg';
import { BREAKPOINTS } from 'theme/constants';
import { IReduxState } from 'state/types';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';

import TopListComponent from './TopListComponent';
import { usePlaylistsUsage, useMediaUsage } from '../queries';

interface IMapStateToProps {
  selectedTeam?: string;
}

type TTopList = IMapStateToProps;

const StyledTopListComponent = styled(TopListComponent)({
  margin: '0',
  [BREAKPOINTS.XXL_MOBILE]: {
    margin: '0 0 24px 0',
  },
});

const CoursesList = styled(StyledTopListComponent)({
  margin: '0 24px',
});

const TopList: React.FC<TTopList> = ({ selectedTeam }) => {
  const { data: playlistsData, isLoading: playlistsLoading } =
    usePlaylistsUsage('playlist', selectedTeam);
  const { data: coursesData, isLoading: coursesLoading } = usePlaylistsUsage(
    'course',
    selectedTeam
  );
  const { data: mediaData, isLoading: mediaLoading } =
    useMediaUsage(selectedTeam);

  return (
    <>
      <StyledTopListComponent
        title="Top content units"
        data={mediaData}
        itemIcon={<TopContentUnit />}
        isLoading={mediaLoading}
      />
      <CoursesList
        title="Top courses"
        data={coursesData}
        itemIcon={<TopCategory />}
        isLoading={coursesLoading}
      />
      <StyledTopListComponent
        title="Top playlists"
        data={playlistsData}
        itemIcon={<TopPlaylist />}
        isLoading={playlistsLoading}
      />
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  selectedTeam: getSelectedTeam(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(TopList);
