import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getFirstName = (state: IReduxState) =>
  state?.[NAME]?.firstName || '' || '';

export const getLastName = (state: IReduxState) =>
  state?.[NAME]?.lastName || '';

export const getEmail = (state: IReduxState) => state?.[NAME]?.email || '';

export const getPassword = (state: IReduxState) =>
  state?.[NAME]?.password || '';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const getCompanyName = (state: IReduxState) =>
  state?.[NAME]?.companyName || '';

export const getEmailError = (state: IReduxState) =>
  state?.[NAME]?.emailError || '';

export const getLastNameError = (state: IReduxState) =>
  state?.[NAME]?.lastNameError || '';

export const getFirstNameError = (state: IReduxState) =>
  state?.[NAME]?.firstNameError || '';

export const getPasswordError = (state: IReduxState) =>
  state?.[NAME]?.passwordError || '';

export const getCompanyNameError = (state: IReduxState) =>
  state?.[NAME]?.companyNameError || '';
