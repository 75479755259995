import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Text from 'components/Text';
import Modal from 'components/Modal';
import Button from 'components/Button';
import DropdownMultiSelect, { IOption } from 'components/DropdownMultiSelect';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { BREAKPOINTS } from 'theme/constants';

import {
  isNewMemberModalVisible,
  getSelectedNewMembers,
  getLicenseesOptions,
} from '../selectors';
import {
  setNewMemberModalVisible,
  addNewMember,
  setSelectedNewMembers,
} from '../actions';

interface IMapStateToProps {
  selectedOptions: IOption[];
  isModalVisible: boolean;
  isSubmitButtonDisabled: boolean;
  options: IOption[];
}

interface IMapDispatchToProps {
  onSubmit: () => void;
  onCancel: () => void;
  onSelect: (value: IOption[]) => void;
}

interface IOwnProps {
  teamUid: string;
}

type TAddNewMemberModal = IMapStateToProps & IMapDispatchToProps & IOwnProps;

const StyledModal = styled(Modal)({
  maxWidth: 400,
  width: '100%',
});

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  marginTop: 24,
  justifyContent: 'center',
  width: '100%',
  [BREAKPOINTS.XL_MOBILE]: {
    alignItems: 'center',
    flexDirection: 'column-reverse',
  },
});

const CancelButton = styled(Button)({
  marginRight: 8,
  maxWidth: 140,
  [BREAKPOINTS.XL_MOBILE]: {
    marginRight: 0,
    marginTop: 8,
  },
});

const SubmitButton = styled(Button)({
  maxWidth: 140,
});

const Title = styled(Text)({
  marginBottom: 16,
});

const AddNewMemberModal: React.FC<TAddNewMemberModal> = ({
  isModalVisible,
  onSubmit,
  onCancel,
  onSelect,
  options,
  selectedOptions,
  isSubmitButtonDisabled,
}) => (
  <StyledModal isVisible={isModalVisible} onModalOutsideClick={onCancel}>
    <Container>
      <Title type="h1">Add new member</Title>
      <DropdownMultiSelect
        onChange={onSelect}
        options={options}
        selectedOptions={selectedOptions}
      />
      <ButtonsContainer>
        <CancelButton buttonType="light" text="Cancel" onClick={onCancel} />
        <SubmitButton
          isDisabled={isSubmitButtonDisabled}
          text="Add"
          onClick={onSubmit}
        />
      </ButtonsContainer>
    </Container>
  </StyledModal>
);

const mapStateToProps = (state: IReduxState) => {
  const selectedOptions = getSelectedNewMembers(state);

  return {
    isModalVisible: isNewMemberModalVisible(state),
    options: getLicenseesOptions(state),
    isSubmitButtonDisabled: !selectedOptions.length,
    selectedOptions,
  };
};

const mapDispatchToProps = (dispatch: TDispatch, { teamUid }: IOwnProps) => ({
  onSubmit: () => dispatch(addNewMember(teamUid)),
  onCancel: () => dispatch(setNewMemberModalVisible(false)),
  onSelect: (value: IOption[]) => dispatch(setSelectedNewMembers(value)),
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(AddNewMemberModal);
