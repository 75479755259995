import { createAction } from 'redux-actions';
import { AxiosResponse } from 'axios';

import { TThunk } from 'types/common';
import { API_URLS, callCoreApi, METHODS } from 'api';

import { SET_LICENSEES, SET_LOADING, REMOVE_LICENSEE } from './actionTypes';
import { TNormalizedLicensee, ILicensee } from './types';
import { getLicenseesUids } from './selectors';

export const setLoading = createAction<boolean>(SET_LOADING);
export const removeLicensee = createAction<string>(REMOVE_LICENSEE);
export const setLicensees = createAction<TNormalizedLicensee[]>(SET_LICENSEES);

export const fetchLicensees = (): TThunk => (dispatch, getState) => {
  const state = getState();

  const licenseesUids = getLicenseesUids(state);

  if (!!licenseesUids.length) {
    return;
  }

  dispatch(setLoading(true));

  callCoreApi({
    method: METHODS.GET,
    url: API_URLS.LICENSEES,
    authorized: true,
  })
    .then((response: AxiosResponse<ILicensee[]>) => {
      const formattedData = response?.data?.map((licensee) => ({
        uid: licensee.id,
        ...licensee,
      }));

      dispatch(setLicensees(formattedData));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
