import { connect } from 'react-redux';

import { IReduxState } from 'state/types';
import {
  PriceCalculatorError,
  TPriceCalculatorError,
} from 'components/PriceCalculator';

import { isFloorLimitError, isCeilingLimitError } from '../selectors';
import { EXTERNAL_LINKS } from 'utils/constants';

const mapStateToProps = (state: IReduxState) => {
  const isFloorLimit = !!isFloorLimitError(state);
  const isCeilingLimit = !!isCeilingLimitError(state);

  return {
    isVisible: isFloorLimit || isCeilingLimit,
    titleText: isFloorLimit
      ? 'Buying for less than 5 people?'
      : 'Buying for more than 100 people?',
    mainText: isFloorLimit
      ? 'If you would like to purchase less than 5 people, then we suggest you buy regular licenses from'
      : 'If you are buying more than 100 subscriptions',
    link: isFloorLimit
      ? EXTERNAL_LINKS.WEBSITE
      : 'mailto:support@yoursapp.com?subject=Premium Business Client',
    linkText: isFloorLimit ? 'yoursapp.com' : 'Contact us',
  };
};

export default connect<TPriceCalculatorError, unknown, unknown, IReduxState>(
  mapStateToProps
)(PriceCalculatorError);
