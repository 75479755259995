import { connect } from 'react-redux';
import styled from '@emotion/styled';

import Text from 'components/Text';
import Button from 'components/Button';
import { ReactComponent as ExclamationMark } from 'assets/exclamation_mark.svg';
import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { BREAKPOINTS } from 'theme/constants';

import { handlePopupClose, handleBuyExtraClick } from '../actions';
import { isLimitErrorVisible } from '../selectors';

interface IMapDispatchToProps {
  onCancelClick: () => void;
  onBuyExtraClick: () => void;
}

type TLimitError = IMapDispatchToProps;

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  [BREAKPOINTS.XL_MOBILE]: {
    flexDirection: 'column',
  },
});

const StyledButton = styled(Button)({
  margin: '0 4px',
  width: 190,
  [BREAKPOINTS.XL_MOBILE]: {
    margin: '4px 0',
  },
});

const StyledText = styled(Text)({
  marginTop: 12,
  marginBottom: 24,
});

const LimitError: React.FC<TLimitError> = ({
  onCancelClick,
  onBuyExtraClick,
}) => (
  <Container>
    <ExclamationMark />
    <StyledText type="h1" weight={600} align="center">
      You have reached a limit for adding new people
    </StyledText>
    <ButtonsContainer>
      <StyledButton onClick={onCancelClick} text="Cancel" buttonType="light" />
      <StyledButton onClick={onBuyExtraClick} text="Buy extra licenses" />
    </ButtonsContainer>
  </Container>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: isLimitErrorVisible(state),
});

const mapDispatchToProps = {
  onCancelClick: handlePopupClose,
  onBuyExtraClick: handleBuyExtraClick,
};

export default connect<
  TWithHideableProp<unknown>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(LimitError));
