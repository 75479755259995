import { createAction } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';

import { TThunk } from 'types/common';
import { getLicenseesByUidObject } from 'features/Licensees/selectors';
import { IOption } from 'components/DropdownMultiSelect';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { ILicensee, TNormalizedLicensee } from 'features/Licensees/types';

import { TAssignNewMemberApiProps } from './types';
import {
  getTeamMembersUids,
  getSelectedNewMembers,
  isLoading,
} from './selectors';
import {
  SET_TEAM_MEMBERS,
  SET_LOADING,
  REMOVE_TEAM_MEMBER,
  RESET_TEAM_MEMBERS_DATA,
  SET_NEW_MEMBER_MODAL_VISIBLE,
  SET_SELECTED_NEW_MEMBERS,
  SET_TEAM_NAME,
  UPDATE_TEAM_MEMBERS,
} from './actionTypes';

export const setLoading = createAction<boolean>(SET_LOADING);
export const removeTeamMember = createAction<string>(REMOVE_TEAM_MEMBER);
export const setTeamName = createAction<string>(SET_TEAM_NAME);
export const resetTeamMembersData = createAction(RESET_TEAM_MEMBERS_DATA);
export const setSelectedNewMembers = createAction<IOption[]>(
  SET_SELECTED_NEW_MEMBERS
);
export const setNewMemberModalVisible = createAction<boolean>(
  SET_NEW_MEMBER_MODAL_VISIBLE
);
export const setTeamMembers =
  createAction<TNormalizedLicensee[]>(SET_TEAM_MEMBERS);
export const updateTeamMember =
  createAction<TNormalizedLicensee>(UPDATE_TEAM_MEMBERS);

export const fetchTeamMembers =
  (teamUid: string): TThunk =>
  (dispatch, getState) => {
    const state = getState();

    const teamMembersUids = getTeamMembersUids(state);

    if (!!teamMembersUids.length || !teamUid) {
      return;
    }

    dispatch(setLoading(true));

    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.TEAMS,
      path: `${teamUid}/licenses`,
      authorized: true,
    })
      .then((response: AxiosResponse<ILicensee[]>) => {
        const normalizedData = response.data.map((teamMember) => ({
          uid: teamMember.id,
          ...teamMember,
        }));

        dispatch(setTeamMembers(normalizedData));
      })
      .catch((_error: AxiosError) => {
        // TODO: handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const addNewMember =
  (teamUid: string): TThunk =>
  (dispatch, getState) => {
    const state = getState();

    const preventFetch = isLoading(state);

    if (preventFetch) {
      return;
    }

    dispatch(setLoading(true));

    const selectedMembers = getSelectedNewMembers(state);
    const selectedMembersIds = selectedMembers.map((selectedMember) => ({
      id: selectedMember.value,
    }));

    callCoreApi<TAssignNewMemberApiProps>({
      method: METHODS.POST,
      url: API_URLS.TEAMS,
      path: `${teamUid}/assign-licenses/`,
      authorized: true,
      data: selectedMembersIds,
    })
      .then(() => {
        const licenseesObject = getLicenseesByUidObject(state);

        const formattedMembers = selectedMembers.map((member) => ({
          ...licenseesObject?.[member.value],
        }));

        dispatch(setTeamMembers(formattedMembers));
      })
      .catch((_error: AxiosError) => {
        // TODO: handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setNewMemberModalVisible(false));
        dispatch(setSelectedNewMembers([]));
      });
  };
