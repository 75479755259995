import { ROLE } from 'features/Licensees/types';

export const NAME = 'teamMembersTable';

export enum TABLE_KEYS {
  EMAIL = 'email',
  ROLE = 'role',
  STATUS = 'status',
  ACTION = 'action',
}

export const ROLE_TEXT = {
  [ROLE.MEMBER]: 'Member',
  [ROLE.OWNER]: 'Owner',
  [ROLE.TEAM_LEAD]: 'Team lead',
};
