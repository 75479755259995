import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { PERIODS } from 'utils/constants';
import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import DropdownSelect from 'components/DropdownSelect';
import { useEmployeesMoods, useMoodsIcons } from '../queries';
import { IMood } from '../types';

import { BREAKPOINTS } from '../../../theme/constants';
import ContainerPlaceholder from '../../../components/ContainerPlaceholder';

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const DropdownSelectContainer = styled.div({
  width: 158,
});

const MoodsContainer = styled(ContainerPlaceholder)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridAutoRows: 'min-content',
  gridColumnGap: 24,
  gridRowGap: 22,
  padding: '39px 0 27px',
  [BREAKPOINTS.TABLET]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(215px, 1fr))',
  },
});

const MoodContainer = styled.div({
  display: 'grid',
  gridTemplateAreas: `'icon label count'
                      'icon bar   bar  '`,
  gridTemplateColumns: 'min-content 1fr max-content',
  gridAutoRows: 'min-content',
  gridColumnGap: 11,
  gridRowGap: 5,
  alignItems: 'start',
  justifyContent: 'center',
});

const MoodIcon = styled.img({
  gridArea: 'icon',
  width: 24,
  height: 24,
  objectFit: 'cover',
});

const MoodLabel = styled.div(({ theme }) => ({
  gridArea: 'label',
  fontSize: 10,
  lineHeight: '12px',
  color: theme.colors.textGray,
}));

const MoodCount = styled.div(({ theme }) => ({
  gridArea: 'count',
  fontFamily: 'Helvetica',
  fontSize: 10,
  lineHeight: '15px',
  color: theme.colors.textGray,
}));

const MoodProgressBar = styled.div<{ $percentage: number }>(
  ({ theme, $percentage }) => ({
    gridArea: 'bar',
    display: 'flex',
    justifyContent: 'start',
    height: 8,
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: theme.colors.formBackgroundLight,
    ':after': {
      content: '""',
      display: 'flex',
      height: '100%',
      width: `${$percentage}%`,
      borderRadius: 5,
      backgroundColor: theme.colors.formBackgroundDark,
      transition: 'width 300ms',
    },
  })
);

const options = [
  {
    value: PERIODS.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: PERIODS.LAST_30_DAYS,
    label: 'Last 30 days',
  },
  {
    value: PERIODS.LAST_90_DAYS,
    label: 'Last 90 days',
  },
  {
    value: PERIODS.LAST_YEAR,
    label: 'Last year',
  },
];

const EmployeesMoods: React.FC = () => {
  const [currentPeriod, setPeriod] = useState<PERIODS>(PERIODS.LAST_30_DAYS);

  const { data: iconsData = {} } = useMoodsIcons();

  const { data: moodsData = [], isLoading } = useEmployeesMoods(currentPeriod);

  const moods: IMood[] = useMemo(() => {
    const total = moodsData.reduce((acm, { count }) => acm + count, 0);

    return moodsData.map(({ id, mood, count }) => ({
      icon: iconsData[mood]!,
      label: mood.replace(' / ', '/').replace(/^\w/, (c) => c.toUpperCase()),
      id,
      count,
      percentage: (count / total) * 100,
    }));
  }, [moodsData, iconsData]);

  const backdropRef = useRef<HTMLDivElement>(null);

  return (
    <ChartContainer ref={backdropRef}>
      <Header>
        <ChartTitle>{`Employees' Moods`}</ChartTitle>
        <DropdownSelectContainer>
          <DropdownSelect<PERIODS>
            data={options}
            onChange={setPeriod}
            value={currentPeriod}
          />
        </DropdownSelectContainer>
      </Header>
      <MoodsContainer
        isLoading={isLoading}
        hasNoData={!moods?.length}
        backdropNode={backdropRef?.current}
      >
        {moods.map(({ id, icon, label, count, percentage }) => (
          <MoodContainer key={id}>
            {icon && (
              <MoodIcon
                src={icon as unknown as string}
                alt={`${label} label`}
              />
            )}
            <MoodLabel>{label}</MoodLabel>
            <MoodCount>{count}</MoodCount>
            <MoodProgressBar $percentage={percentage} />
          </MoodContainer>
        ))}
      </MoodsContainer>
    </ChartContainer>
  );
};

export default EmployeesMoods;
