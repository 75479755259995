import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { Table, IDataItem, IHeader } from 'components/Table';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import { getLicenseesUids } from 'features/Licensees/selectors';
import { isOrganizationOwner } from 'features/Auth/selectors';
import hideable, { TWithHideableProp } from 'utils/hideable';

import LicenseeAction from './LicenseeAction';
import LicenseeStatus from './LicenseeStatus';
import LicenseeEmail from './LicenseeEmail';
import LicenseeCreatedAt from './LicenseeCreatedAt';
import { TABLE_KEYS } from '../constants';
import { getUidsBySearchValue } from '../selectors';

interface IMapStateToProps {
  header: IHeader[];
  data: IDataItem[];
}

const HeaderComponent = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
}));

const renderHeader: (shouldShowAction: boolean) => IHeader[] = (
  shouldShowAction
) => [
  {
    name: TABLE_KEYS.EMAIL,
    component: <HeaderComponent type="small">EMAIL</HeaderComponent>,
  },
  {
    name: TABLE_KEYS.STATUS,
    component: <HeaderComponent type="small">STATUS</HeaderComponent>,
  },
  {
    name: TABLE_KEYS.CREATED_AT,
    component: <HeaderComponent type="small">CREATED AT</HeaderComponent>,
  },
  ...(shouldShowAction
    ? [
        {
          name: TABLE_KEYS.ACTION,
          component: <HeaderComponent type="small">ACTION</HeaderComponent>,
        },
      ]
    : []),
];

const renderRows = (uids: string[]) =>
  uids.map((uid) => ({
    key: uid,
    [TABLE_KEYS.EMAIL]: <LicenseeEmail uid={uid} />,
    [TABLE_KEYS.STATUS]: <LicenseeStatus uid={uid} />,
    [TABLE_KEYS.CREATED_AT]: <LicenseeCreatedAt uid={uid} />,
    [TABLE_KEYS.ACTION]: <LicenseeAction uid={uid} />,
  }));

const mapStateToProps = (state: IReduxState) => {
  const uids = getUidsBySearchValue(state);

  return {
    header: renderHeader(!!isOrganizationOwner(state)),
    isVisible: !!getLicenseesUids(state)?.length,
    data: renderRows(uids),
  };
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable(Table));
