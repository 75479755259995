import { useTheme } from '@emotion/react';
import Select, { StylesConfig } from 'react-select';

interface IDropdownMultiSelect {
  options: IOption[];
  selectedOptions: IOption[];
  onChange: (value: any) => void;
}

export interface IOption {
  label: string;
  value: string;
}

const DropdownMultiSelect: React.FC<IDropdownMultiSelect> = ({
  options,
  selectedOptions,
  onChange,
}) => {
  const theme = useTheme();
  const styles: StylesConfig = {
    placeholder: (provided) => ({
      ...provided,
      fontSize: 14,
      fontWeight: 400,
      color: theme.colors.titleText,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    container: (provided, _state) => ({
      ...provided,
      width: '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      borderRadius: 10,
      border: `1px solid ${
        state.isFocused ? theme.colors.textGray : 'rgba(138, 147, 159, 0.24)'
      }`,
      ':hover': {
        borderColor: theme.colors.textGray,
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.formBackgroundGray,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: theme.colors.formBackgroundDark,
      ':hover': {
        backgroundColor: theme.colors.formBackgroundGray,
        cursor: 'pointer',
      },
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '-15px 19px 35px -2px rgba(0,0,0,0.07)',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: 14,
      color: '#444444',
      fontWeight: 400,
      lineHeight: '20px',
      padding: '11px 22px',
      backgroundColor: theme.colors.white,
      ':hover': {
        backgroundColor: theme.colors.formBackgroundGray,
      },
    }),
  };

  return (
    <Select
      isClearable={false}
      styles={styles}
      options={options}
      value={selectedOptions}
      onChange={onChange}
      isMulti
    />
  );
};

export default DropdownMultiSelect;
