import React from 'react';
import styled from '@emotion/styled';

import SettingsButton from './SettingsButton';

import StatsTeamSelect from 'features/StatsTeamSelect/components';
import { ReactComponent as Burger } from 'assets/burger.svg';
import useWindowSize from 'utils/useWindowSize';
import { BREAKPOINTS } from 'theme/constants';

interface IHeader {
  onMenuClick: () => void;
  title: string;
  subtitle: string;
}

const Container = styled.div({
  display: 'grid',
  gridTemplateAreas: `'title actions'`,
  gridTemplateColumns: '1fr min-content',
  gridColumnGap: 24,
  padding: '32px 80px',
  backgroundColor: `rgba(57, 108, 216, 0)`,
  [BREAKPOINTS.XL_TABLET]: {
    paddingLeft: '20px',
    paddingRight: '20px',
    gridTemplateAreas: `'menu title actions'`,
    gridTemplateColumns: 'min-content 1fr min-content',
  },
  [BREAKPOINTS.XL_MOBILE]: {
    gridTemplateAreas: `'menu actions'
                        'title title'`,
    gridRowGap: 36,
  },
});

const ActionContainer = styled.div({
  gridArea: 'actions',
  marginLeft: 'auto',
  display: 'flex',
});

const BurgerMenuButton = styled.button({
  gridArea: 'menu',
  justifySelf: 'start',
  outline: 'none',
  border: 'none',
  background: 'none',
  padding: 5,
  cursor: 'pointer',
});

const TitleContainer = styled.div({
  gridArea: 'title',
  justifySelf: 'start',
});

const Title = styled.h1({
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: 34,
  lineHeight: '42px',
  margin: 0,
  [BREAKPOINTS.XL_MOBILE]: {
    fontSize: 24,
  },
});

const Subtitle = styled.h1(({ theme }) => ({
  fontFamily: 'Poppins',
  fontWeight: 600,
  fontSize: 14,
  lineHeight: '24px',
  margin: 0,
  color: theme.colors.subtitle,
  [BREAKPOINTS.XL_MOBILE]: {
    fontWeight: 400,
  },
}));

const Header: React.FC<IHeader> = ({
  onMenuClick,
  title,
  subtitle,
  ...rest
}) => {
  const { isCompact } = useWindowSize();

  return (
    <Container {...rest}>
      {title && (
        <TitleContainer>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
      )}
      {isCompact && (
        <BurgerMenuButton onClick={onMenuClick}>
          <Burger />
        </BurgerMenuButton>
      )}
      <ActionContainer>
        <StatsTeamSelect />
        <SettingsButton />
      </ActionContainer>
    </Container>
  );
};

export default Header;
