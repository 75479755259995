import styled from '@emotion/styled';

interface IProgressBar {
  percentage: number;
}

const ProgressBarContainer = styled.div({
  position: 'relative',
  backgroundColor: 'rgba(138, 147, 159, 0.2)',
  width: '100%',
  height: 2,
});

const Filler = styled.div<IProgressBar>((props) => ({
  position: 'relative',
  backgroundColor: props.theme.colors.activeBlue,
  width: `${props.percentage}%`,
  height: 2,
}));

const ProgressBar: React.FC<IProgressBar> = ({ percentage, ...rest }) => (
  <ProgressBarContainer {...rest}>
    <Filler percentage={percentage} />
  </ProgressBarContainer>
);

export default ProgressBar;
