import { connect } from 'react-redux';

import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';
import TableSearchInput from 'components/TableSearchInput';
import { getTeamMembersUids } from 'features/TeamMembers/selectors';

import { getSearchValue } from '../selectors';
import { setSearchValue } from '../actions';

interface IMapStateToProps {
  value: string;
  placeholder: string;
}

interface IMapDispatchToProps {
  onChange: (value: string) => void;
}

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !!getTeamMembersUids(state).length,
  placeholder: 'Search by email',
  value: getSearchValue(state),
});

const mapDispatchToProps = {
  onChange: setSearchValue,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(TableSearchInput));
