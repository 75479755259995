import { createAction } from 'redux-actions';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { callCoreApi, API_URLS, METHODS } from 'api';
import { CUSTOM_ERROR_CODES } from 'api/constants';
import { TThunk } from 'types/common';
import { ERROR_TEXTS } from 'utils/constants';
import { isEmailValid, isPasswordValid } from 'utils/helpers';
import { signInRequest } from 'features/SignIn/actions';

import { IRegistrationApiProps, IRegistrationRequest } from './types';
import {
  getCompanyName,
  getEmail,
  getFirstName,
  getLastName,
  isLoading,
  getPassword,
} from './selectors';
import {
  SET_COMPANY_NAME,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_LOADING,
  SET_PASSWORD,
  SET_COMPANY_NAME_ERROR,
  SET_EMAIL_ERROR,
  SET_FIRST_NAME_ERROR,
  SET_LAST_NAME_ERROR,
  SET_PASSWORD_ERROR,
  RESET_STATE,
} from './actionTypes';

export const setCompanyName = createAction<string>(SET_COMPANY_NAME);
export const setEmail = createAction<string>(SET_EMAIL);
export const setFirstName = createAction<string>(SET_FIRST_NAME);
export const setLastName = createAction<string>(SET_LAST_NAME);
export const setPassword = createAction<string>(SET_PASSWORD);

export const setEmailError = createAction<string>(SET_EMAIL_ERROR);
export const setPasswordError = createAction<string>(SET_PASSWORD_ERROR);
export const setCompanyNameError = createAction<string>(SET_COMPANY_NAME_ERROR);
export const setFirstNameError = createAction<string>(SET_FIRST_NAME_ERROR);
export const setLastNameNameError = createAction<string>(SET_LAST_NAME_ERROR);

export const setLoading = createAction<boolean>(SET_LOADING);
export const resetState = createAction(RESET_STATE);

export const handleRegistration = (): TThunk => (dispatch, getState) => {
  const state = getState();
  let isValidationError;

  if (isLoading(state)) {
    return;
  }

  dispatch(setEmailError(''));
  dispatch(setFirstNameError(''));
  dispatch(setPasswordError(''));

  const email = getEmail(state);
  const firstName = getFirstName(state);
  const lastName = getLastName(state);
  const companyName = getCompanyName(state);
  const password = getPassword(state);

  if (!isEmailValid(email)) {
    dispatch(setEmailError(ERROR_TEXTS.VALID_EMAIL));
    isValidationError = true;
  }

  if (!firstName) {
    dispatch(setFirstNameError(ERROR_TEXTS.BLANK_FIELD));
    isValidationError = true;
  }

  if (!lastName) {
    dispatch(setLastNameNameError(ERROR_TEXTS.BLANK_FIELD));
    isValidationError = true;
  }

  if (!companyName) {
    dispatch(setCompanyNameError(ERROR_TEXTS.BLANK_FIELD));
    isValidationError = true;
  }

  if (!isPasswordValid(password)) {
    dispatch(setPasswordError(ERROR_TEXTS.PASSWORD_VALIDATION_ERROR));
    isValidationError = true;
  }

  if (isValidationError) {
    return;
  }

  dispatch(
    handleRegistrationRequest({
      firstName,
      lastName,
      companyName,
      password,
      email,
    })
  );
};

export const handleRegistrationRequest =
  ({
    firstName,
    lastName,
    companyName,
    email,
    password,
  }: IRegistrationRequest): TThunk =>
  (dispatch) => {
    dispatch(setLoading(true));

    const fbp = Cookies.get('_fbp');
    const fbc = Cookies.get('_fbc');
    const appsflyer_web_id = Cookies.get('afUserId');
    let google_cid;

    if (window.ga?.getAll && typeof window.ga?.getAll === 'function') {
      google_cid = window.ga?.getAll()?.[0]?.get('clientId');
    }

    callCoreApi<IRegistrationApiProps>({
      url: API_URLS.REGISTER,
      method: METHODS.POST,
      data: {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        organization: {
          name: companyName,
        },
        google_cid,
        fbp,
        fbc,
        appsflyer_web_id,
      },
    })
      .then(() => {
        dispatch(signInRequest(email, password));
      })
      .catch((error: AxiosError) => {
        dispatch(setLoading(false));

        if (
          error.response?.data?.custom_status_code ===
          CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
        ) {
          const passwordError =
            error.response?.data?.errors?.password?.[0] || '';
          const emailError = error.response?.data?.errors?.email?.[0] || '';
          const firstNameError =
            error.response?.data?.errors?.fist_name?.[0] || '';

          dispatch(setFirstNameError(firstNameError));
          dispatch(setPasswordError(passwordError));
          dispatch(setEmailError(emailError));
        } else {
          toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
        }
      });
  };
