import PurchaseFrom from 'features/Purchase/components';
import FullScreenWrapper from 'components/FullScreenWrapper';

const Purchase: React.FC = () => (
  <FullScreenWrapper>
    <PurchaseFrom />
  </FullScreenWrapper>
);

export default Purchase;
