import styled from '@emotion/styled';
import hideable from 'utils/hideable';
import Text from 'components/Text';
import { ReactComponent as DisabledArrowBack } from 'assets/disabled_back_arrow.svg';
import { ReactComponent as EnabledArrowBack } from 'assets/enabled_back_arrow.svg';
import { ReactComponent as DisabledArrowForward } from 'assets/disabled_forward_arrow.svg';
import { ReactComponent as EnabledArrowForward } from 'assets/enabled_forward_arrow.svg';
import React from 'react';

const PaginationContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '20px 0',
});

const PaginationButton = styled.button((props) => ({
  border: 'none',
  background: 'none',
  outline: 'none',
  margin: '0 12px',
  display: 'flex',
  alignItems: 'center',
  cursor: props.disabled ? 'default' : 'pointer',
}));

const PaginationCounter = styled(Text)({
  margin: '0 5px',
});

interface IPagination {
  onBackClick: () => void;
  onNextClick: () => void;
  activePage: number;
  pages: number;
  rowsPerPage: number;
  amountOfRecords: number;
}

const Pagination: React.FC<IPagination> = ({
  onBackClick,
  onNextClick,
  activePage,
  pages,
  rowsPerPage,
  amountOfRecords,
}) => {
  const isLastPage = activePage === pages;
  const isFirstPage = activePage === 1;

  return (
    <PaginationContainer>
      <PaginationCounter>
        {isFirstPage ? 1 : rowsPerPage * (activePage - 1) + 1} -{' '}
        {isLastPage ? amountOfRecords : rowsPerPage * activePage} out of{' '}
        {amountOfRecords}
      </PaginationCounter>
      <PaginationButton disabled={activePage === 1} onClick={onBackClick}>
        {isFirstPage ? <DisabledArrowBack /> : <EnabledArrowBack />}
      </PaginationButton>
      <PaginationButton disabled={isLastPage} onClick={onNextClick}>
        {isLastPage ? <DisabledArrowForward /> : <EnabledArrowForward />}
      </PaginationButton>
    </PaginationContainer>
  );
};

export default hideable<IPagination>(Pagination);
