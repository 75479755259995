import FormContainer from 'components/FormContainer';
import StepsIndicator from 'components/StepsIndicator';

import PriceCalculator from './PriceCalculator';
import Payment from './Payment';

const Purchase: React.FC = () => (
  <FormContainer>
    <StepsIndicator steps={2} currentStep={2} />
    <PriceCalculator />
    <Payment />
  </FormContainer>
);

export default Purchase;
