import { connect } from 'react-redux';
import styled from '@emotion/styled';

import Text from 'components/Text';
import ProgressBar from 'components/ProgressBar';
import { IReduxState } from 'state/types';
import {
  getLicenseCount,
  getLicenseLimit,
  isOrganizationOwner,
} from 'features/Auth/selectors';

interface IMapStateToProps {
  licenseesCount: number;
  licenseesLimit: number;
  shouldShowNumber: boolean;
}

type TLicenseesCount = IMapStateToProps;

const Container = styled.div({
  display: 'flex',
  marginRight: 12,
});

const StyledProgressBar = styled(ProgressBar)({
  marginTop: 5,
});

const NumbersContainer = styled.div((props) => ({
  paddingLeft: 16,
  marginLeft: 16,
  borderLeft: `1px solid ${props.theme.colors.lightGray}`,
}));

const LicenseesCount: React.FC<TLicenseesCount> = ({
  licenseesCount,
  licenseesLimit,
  shouldShowNumber,
}) => (
  <Container>
    <Text type="h1" weight={600}>
      Business account
    </Text>
    {shouldShowNumber && (
      <NumbersContainer>
        <Text weight={400}>
          Your licenses: {licenseesCount}/{licenseesLimit}
        </Text>
        <StyledProgressBar
          percentage={Math.round((licenseesCount * 100) / licenseesLimit)}
        />
      </NumbersContainer>
    )}
  </Container>
);

const mapStateToProps = (state: IReduxState) => ({
  licenseesCount: getLicenseCount(state),
  licenseesLimit: getLicenseLimit(state),
  shouldShowNumber: !!isOrganizationOwner(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(LicenseesCount);
