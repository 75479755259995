import SearchLicensee from './SearchLicensee';
import DeleteLicenseePopup from './DeleteLicenseePopup';
import LicenseesTableComponent from './LicenseesTableComponent';
import LicenseesTablePlaceholder from './LicenseesTablePlaceholder';

const LicenseesTable: React.FC = () => (
  <>
    <SearchLicensee />
    <LicenseesTableComponent />
    <LicenseesTablePlaceholder />
    <DeleteLicenseePopup />
  </>
);

export default LicenseesTable;
