import { combineReducers } from 'redux';

import { IReduxState } from './types';

import navigation from 'navigation';
import auth from 'features/Auth';
import registration from 'features/Registration';
import signIn from 'features/SignIn';
import purchase from 'features/Purchase';
import subscription from 'features/Subscription';
import inviteLicensee from 'features/InviteLicensee';
import licensees from 'features/Licensees';
import licenseesTable from 'features/LicenseesTable';
import manageTeams from 'features/ManageTeams';
import extraLicensesPurchase from 'features/ExtraLicensesPurchase';
import usersActivity from 'features/UsersActivity';
import contentUsage from 'features/ContentUsage';
import teamMembers from 'features/TeamMembers';
import teamMembersTable from 'features/TeamMembersTable';
import statsTeamSelect from 'features/StatsTeamSelect';

import resetReducer from 'utils/resetReducer';

import { LOGOUT } from 'features/Auth/actionTypes';

export default combineReducers<IReduxState>({
  [navigation.constants.NAME]: navigation.reducer,
  [auth.constants.NAME]: resetReducer([LOGOUT], auth.reducer),
  [registration.constants.NAME]: registration.reducer,
  [signIn.constants.NAME]: signIn.reducer,
  [purchase.constants.NAME]: purchase.reducer,
  [subscription.constants.NAME]: resetReducer([LOGOUT], subscription.reducer),
  [inviteLicensee.constants.NAME]: inviteLicensee.reducer,
  [licensees.constants.NAME]: resetReducer([LOGOUT], licensees.reducer),
  [licenseesTable.constants.NAME]: licenseesTable.reducer,
  [manageTeams.constants.NAME]: resetReducer([LOGOUT], manageTeams.reducer),
  [extraLicensesPurchase.constants.NAME]: extraLicensesPurchase.reducer,
  [usersActivity.constants.NAME]: usersActivity.reducer,
  [contentUsage.constants.NAME]: contentUsage.reducer,
  [teamMembers.constants.NAME]: teamMembers.reducer,
  [teamMembersTable.constants.NAME]: teamMembersTable.reducer,
  [statsTeamSelect.constants.NAME]: statsTeamSelect.reducer,
});
