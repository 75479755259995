import React from 'react';
import styled from '@emotion/styled';
import { ROUTES } from 'navigation/constants';

import { ReactComponent as ResetSuccessfulIcon } from 'assets/reset_successful.svg';
import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
import Input from 'components/Input';
import LoadingScreen from 'components/LoadingScreen';

import { usePasswordResetState } from './hooks';

const StyledForm = styled.form({
  minHeight: 'initial',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 0,
  padding: '48px 70px',
});

const SuccessTitle = styled(Text)({
  maxWidth: 290,
  margin: '29px 0 25px',
});

const Title = styled(Text)({
  marginBottom: 16,
});

const Error = styled(Text)(({ theme }) => ({
  color: theme.colors.error,
}));

const StyledInput = styled(Input)({
  width: 364,
  marginTop: 0,
});

const StyledButton = styled(Button)({
  maxWidth: 226,
  margin: '32px 0 16px',
});

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '46px',
  width: 122,
  borderRadius: 10,
  fontSize: '16px',
  fontFamily: 'Poppins',
  color: theme.buttons.primary.text,
  border: theme.buttons.primary.border,
  backgroundColor: theme.buttons.primary.background,
  textDecoration: 'none',
  transition: 'all 300ms',
  ':hover': {
    cursor: 'pointer',
    color: theme.buttons.primary.hoverText,
    backgroundColor: theme.buttons.primary.hoverBackground,
    border: theme.buttons.primary.hoverBorder,
  },
}));

const PasswordReset: React.FC = () => {
  const {
    isVerified,
    isSuccess,
    password,
    setPassword,
    passwordError,
    asyncError,
    isLoading,
    handleSubmit,
  } = usePasswordResetState();

  return (
    <>
      <LoadingScreen isVisible={!isVerified} />
      {isVerified && (
        <StyledForm>
          {isSuccess ? (
            <>
              <ResetSuccessfulIcon />
              <SuccessTitle type="h2" weight={600} align="center">
                Your password was successfully changed
              </SuccessTitle>
              <StyledLink to={ROUTES.LOGIN}>Sign in</StyledLink>
            </>
          ) : (
            <>
              <Title type="h1" weight={600}>
                Please write your new password.
              </Title>
              <Error>{asyncError}</Error>
              <StyledInput
                placeholder="Password"
                type="password"
                value={password}
                onChange={setPassword}
                error={passwordError}
              />
              <StyledButton
                text="Reset password"
                onClick={handleSubmit}
                isDisabled={isLoading}
              />
            </>
          )}
        </StyledForm>
      )}
    </>
  );
};

export default PasswordReset;
