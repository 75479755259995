import styled from '@emotion/styled';

import TotalNumbers from 'features/UsersActivity/components/TotalNumbers';
import DailyActiveUsers from 'features/UsersActivity/components/DailyActiveUsers';
import MonthlyActiveUsers from 'features/UsersActivity/components/MonthlyActiveUsers';
import { useTitles } from 'navigation/hooks';

const Wrapper = styled.div({
  marginBottom: 96,
});

const UsersActivity: React.FC = () => {
  useTitles('Users activity', 'Active users log');

  return (
    <Wrapper>
      <TotalNumbers />
      <DailyActiveUsers />
      <MonthlyActiveUsers />
    </Wrapper>
  );
};

export default UsersActivity;
