import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { API_URLS, callCoreApi, METHODS } from 'api';
import { PERIODS } from 'utils/constants';
import { getDatesByPeriod } from 'utils/helpers';
import formatPeriodData from 'utils/formatPeriodData';
import getDataAggregationByPeriod from 'utils/getDataAggregationByPeriod';

import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';
import {
  ICircumstancesApiResponse,
  ICircumstancesIconsApiResponse,
  IEmployeesMoodsApiResponse,
  IMoodsIconsApiResponse,
  IMoodsQuestionsApiResponse,
  ISummaryStatsApiResponse,
  ITeamsQuestionsApiResponse,
} from './types';
import { CIRCUMSTANCES, TEAM_QUESTIONS } from './constants';

export const useSummaryStats = () => {
  const selectedTeam = useSelector(getSelectedTeam);

  return useQuery<ISummaryStatsApiResponse>(
    ['summaryStats', selectedTeam],
    () => {
      return callCoreApi({
        method: METHODS.GET,
        url: API_URLS.SUMMARY_STATS,
        queryParams: [
          ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
        ],
        authorized: true,
      }).then(
        (response: AxiosResponse<ISummaryStatsApiResponse>) => response.data
      );
    }
  );
};

export const useMoodsQuestions = (question: TEAM_QUESTIONS) => {
  const { data = [] } = useQuery<IMoodsQuestionsApiResponse[]>(
    ['moodsQuestions'],
    () => {
      return callCoreApi({
        method: METHODS.GET,
        url: API_URLS.MOODS_QUESTIONS,
        authorized: true,
      }).then(
        (response: AxiosResponse<IMoodsQuestionsApiResponse[]>) => response.data
      );
    }
  );

  if (!data.length) return;

  const [questionData] = data.filter(({ label }) => label === question);
  return questionData;
};

export const useTeamsQuestions = (
  id: IMoodsQuestionsApiResponse['id'] | undefined,
  period: PERIODS
) => {
  const selectedTeam = useSelector(getSelectedTeam);
  return useQuery<ITeamsQuestionsApiResponse[]>(
    ['questions', id, period, selectedTeam],
    () => {
      const periodDates = getDatesByPeriod(period);
      return callCoreApi({
        method: METHODS.GET,
        url: API_URLS.MOODS_ANSWERS,
        queryParams: [
          { key: 'question', value: id! },
          { key: 'date_from', value: periodDates?.from || '' },
          { key: 'date_to', value: periodDates?.to || '' },
          { key: 'aggregation', value: getDataAggregationByPeriod(period) },
          ...(selectedTeam ? [{ key: 'team', value: selectedTeam! }] : []),
        ],
        authorized: true,
      }).then((response: AxiosResponse<ITeamsQuestionsApiResponse[]>) =>
        formatPeriodData(period, response.data, 'date')
      );
    },
    { enabled: !!id }
  );
};

export const useCircumstancesIcons = () =>
  useQuery<Record<CIRCUMSTANCES | string, string>>(['circumstancesIcons'], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.CIRCUMSTANCES_ICONS,
      authorized: true,
    }).then((response: AxiosResponse<ICircumstancesIconsApiResponse[]>) =>
      response.data.reduce(
        (acm, { circumstance, icon }) => ({ ...acm, [circumstance]: icon }),
        {}
      )
    )
  );

export const useCircumstances = (period: PERIODS) => {
  const selectedTeam = useSelector(getSelectedTeam);
  return useQuery<ICircumstancesApiResponse[]>(
    ['circumstances', period, selectedTeam],
    () => {
      const periodDates = getDatesByPeriod(period);

      return callCoreApi({
        method: METHODS.GET,
        url: API_URLS.CIRCUMSTANCES,
        queryParams: [
          { key: 'date_from', value: periodDates?.from || '' },
          { key: 'date_to', value: periodDates?.to || '' },
          ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
        ],
        authorized: true,
      }).then(
        (response: AxiosResponse<ICircumstancesApiResponse[]>) => response.data
      );
    }
  );
};

export const useMoodsIcons = () =>
  useQuery<Record<string, URL>>(['moodsIcons'], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.MOODS_ICONS,
      authorized: true,
    }).then((response: AxiosResponse<IMoodsIconsApiResponse[]>) =>
      response.data.reduce(
        (acm, { mood, icon }) => ({ ...acm, [mood]: icon }),
        {}
      )
    )
  );

export const useEmployeesMoods = (period: PERIODS) => {
  const selectedTeam = useSelector(getSelectedTeam);

  return useQuery<IEmployeesMoodsApiResponse[]>(
    ['employeesMoods', period, selectedTeam],
    () => {
      const periodDates = getDatesByPeriod(period);

      return callCoreApi({
        method: METHODS.GET,
        url: API_URLS.EMPLOYEES_MOODS,
        queryParams: [
          { key: 'date_from', value: periodDates?.from || '' },
          { key: 'date_to', value: periodDates?.to || '' },
          ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
        ],
        authorized: true,
      }).then(
        (response: AxiosResponse<IEmployeesMoodsApiResponse[]>) => response.data
      );
    }
  );
};
