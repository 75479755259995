import styled from '@emotion/styled';

interface IText {
  type?: 'small' | 'p' | 'h1' | 'h2' | 'h3';
  weight?:
    | 'bold'
    | 'normal'
    | 100
    | 200
    | 300
    | 400
    | 500
    | 600
    | 700
    | 800
    | 900;
  align?: 'center' | 'left' | 'right';
}

export const getStyleByType = (type: 'p' | 'h1' | 'h2' | 'h3' | 'small') =>
  ({
    h1: {
      fontSize: '24px',
    },
    h2: {
      fontSize: '18px',
    },
    h3: {
      fontSize: '16px',
    },
    p: {
      fontSize: '14px',
    },
    small: {
      fontSize: '12px',
    },
  }[type]);

const Text = styled.div<IText>(
  ({ theme, type = 'p', weight = 'normal', align = 'left' }) => ({
    color: theme.colors.darkText,
    fontWeight: weight,
    textAlign: align,
    ...getStyleByType(type),
  })
);

export default Text;
