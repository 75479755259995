import styled from '@emotion/styled';
import { connect } from 'react-redux';

import CreateTeamModalContent from './CreateTeamModalContent';
import { setCreateTeamModalVisible } from '../actions';
import { isCreateTeamModalVisible, isLoading } from '../selectors';

import Button from 'components/Button';
import Modal from 'components/Modal';
import { IReduxState } from 'state/types';
import { BREAKPOINTS } from 'theme/constants';
import { TDispatch } from 'types/common';

interface IMapStateToProps {
  isModalVisible: boolean;
  isButtonDisabled: boolean;
}

interface IMapDispatchToProps {
  onModalOutsideClick: () => void;
  onButtonPress: () => void;
}

type TCreateTeam = IMapStateToProps & IMapDispatchToProps;

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '32px 45px 22px 45px',
  [BREAKPOINTS.XL_TABLET]: {
    padding: '32px 24px 22px 24px',
  },
});

const ModalContentContainer = styled.div({
  minWidth: 350,
});

const ButtonContainer = styled.div({
  width: 200,
});

const CreateTeam: React.FC<TCreateTeam> = ({
  isModalVisible,
  isButtonDisabled,
  onButtonPress,
  onModalOutsideClick,
}) => (
  <>
    <Container>
      <ButtonContainer>
        <Button
          text="+ Create new team"
          onClick={onButtonPress}
          isLoading={isButtonDisabled}
        />
      </ButtonContainer>
    </Container>
    <Modal onModalOutsideClick={onModalOutsideClick} isVisible={isModalVisible}>
      <ModalContentContainer>
        <CreateTeamModalContent />
      </ModalContentContainer>
    </Modal>
  </>
);

const mapStateToProps = (state: IReduxState) => ({
  isModalVisible: isCreateTeamModalVisible(state),
  isButtonDisabled: isLoading(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onModalOutsideClick: () => dispatch(setCreateTeamModalVisible(false)),
  onButtonPress: () => dispatch(setCreateTeamModalVisible(true)),
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(CreateTeam);
