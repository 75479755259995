import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { connect } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';

import Button from 'components/Button';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';

import {
  isPurchaseLoading,
  getPaymentError,
  getQuantityOfLicenses,
  isCeilingLimitError,
  isFloorLimitError,
} from '../selectors';
import {
  setPaymentError,
  setPurchaseLoading,
  handlePaymentStart,
  handlePaymentComplete,
  handleQuantityValidationError,
} from '../actions';

interface IMapStateToProps {
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  paymentError: string;
  quantityOfLicenses: string;
}

interface IMapDispatchToProps {
  onError: (error: string) => void;
  onPurchaseStart: () => void;
  onPurchaseEnd: () => void;
  onQuantityValidationError: () => void;
  onPurchaseComplete: (paymentMethod: PaymentMethod) => void;
}

type TPaymentForm = IMapDispatchToProps & IMapStateToProps;

const CardInput = styled(CardElement)({
  borderBottom: '1px solid rgba(139, 146, 157, 0.25)',
  padding: '8px 0',
  marginBottom: '8px 0',
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 24,
});

const StyledButton = styled(Button)({
  maxWidth: 154,
});

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
  marginBottom: 12,
}));

const PaymentForm: React.FC<TPaymentForm> = ({
  onError,
  onPurchaseEnd,
  onPurchaseStart,
  onPurchaseComplete,
  onQuantityValidationError,
  isButtonLoading,
  isButtonDisabled,
  paymentError,
  quantityOfLicenses,
}) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

  const handlePayment = async () => {
    onPurchaseStart();

    if (!quantityOfLicenses) {
      onQuantityValidationError();

      return;
    }

    if (!stripe || !elements || isButtonLoading) {
      onPurchaseEnd();

      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      onPurchaseEnd();

      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error && error?.message) {
      onPurchaseEnd();
      onError(error.message);
    }

    if (paymentMethod) {
      onPurchaseComplete(paymentMethod);
    } else {
      onPurchaseEnd();
    }
  };

  return (
    <>
      <Error>{paymentError}</Error>
      <CardInput
        options={{
          hideIcon: true,
          style: {
            base: {
              fontSize: '15px',
              color: theme.colors.darkText,
              '::placeholder': {
                color: theme.colors.lightGray,
                fontWeight: '400',
              },
            },
          },
        }}
      />
      <ButtonContainer>
        <StyledButton
          onClick={handlePayment}
          text="Subscribe"
          isDisabled={!stripe || isButtonDisabled}
          isLoading={isButtonLoading}
        />
      </ButtonContainer>
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isButtonLoading: !!isPurchaseLoading(state),
  isButtonDisabled: !!isCeilingLimitError(state) || !!isFloorLimitError(state),
  paymentError: getPaymentError(state),
  quantityOfLicenses: getQuantityOfLicenses(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onError: (message: string) => dispatch(setPaymentError(message)),
  onPurchaseStart: () => dispatch(handlePaymentStart()),
  onQuantityValidationError: () => dispatch(handleQuantityValidationError()),
  onPurchaseEnd: () => dispatch(setPurchaseLoading(false)),
  onPurchaseComplete: (paymentMethod: PaymentMethod) =>
    dispatch(handlePaymentComplete(paymentMethod)),
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm);
