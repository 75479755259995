import { useRef } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { handleDeleteTeam } from '../actions';
import { isLoading } from '../selectors';

import Text from 'components/Text';
import { ReactComponent as RemoveTeamIcon } from 'assets/remove_team.svg';
import useOnClickOutside from 'utils/useOnClickOutside';
import hideable from 'utils/hideable';
import { TDispatch } from 'types/common';
import { IReduxState } from 'state/types';

interface IMapStateToProps {
  isDisabled: boolean;
}

interface IMapDispatchToProps {
  onDeleteTeamClick: () => void;
}

interface IOwnProps {
  teamUid: string;
  onOutsideClick: () => void;
}

type TTeamSettingsPopup = IMapDispatchToProps & IOwnProps & IMapStateToProps;

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 40,
  boxShadow:
    '0px 1px 3px rgba(28, 37, 56, 0.1), 0px 4px 16px rgba(139, 146, 157, 0.08)',
}));

const SettingsButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  outline: 'none',
  margin: 0,
  padding: '12px 20px',
  cursor: 'pointer',
  width: '100%',
});

const SettingsButtonText = styled(Text)((props) => ({
  color: props.theme.colors.darkText,
  fontSize: 13,
  lineHeight: '18px',
  fontWeight: 400,
  marginLeft: 8,
}));

const TeamSettingsPopup: React.FC<TTeamSettingsPopup> = ({
  onOutsideClick,
  onDeleteTeamClick,
  isDisabled,
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onOutsideClick);

  return (
    <Container ref={ref}>
      <SettingsButton onClick={onDeleteTeamClick} disabled={isDisabled}>
        <RemoveTeamIcon />
        <SettingsButtonText>Delete</SettingsButtonText>
      </SettingsButton>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isDisabled: isLoading(state),
});

const mapDispatchToProps = (dispatch: TDispatch, { teamUid }: IOwnProps) => ({
  onDeleteTeamClick: () => dispatch(handleDeleteTeam(teamUid)),
});

export default connect<unknown, IMapDispatchToProps, IOwnProps, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(TeamSettingsPopup));
