import styled from '@emotion/styled';

import Text from 'components/Text';

export default styled(Text)((props) => ({
  color: props.theme.colors.titleText,
  fontSize: 18,
  fontWeight: 600,
  lineHeight: '26px',
}));
