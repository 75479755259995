import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { ROUTES } from 'navigation/constants';
import { BREAKPOINTS } from 'theme/constants';
import { isOrganizationOwner } from 'features/Auth/selectors';
import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';

interface ITab {
  isActive: boolean;
}

const Container = styled.div({
  display: 'flex',
  padding: '0 45px',
  [BREAKPOINTS.XL_TABLET]: {
    padding: '0 24px',
  },
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  marginRight: 28,
});

const Tab = styled.div<ITab>((props) => ({
  color: props.isActive
    ? props.theme.navigationColors.activeLinkText
    : props.theme.colors.textGray,
  padding: '10px 0',
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: 500,
  borderBottomStyle: 'solid',
  borderBottomWidth: props.isActive ? 2 : 0,
  borderBottomColor: props.isActive
    ? props.theme.navigationColors.activeLinkText
    : props.theme.colors.textGray,
}));

const UsersManagementTabs: React.FC = () => {
  const location = useLocation();

  return (
    <Container>
      <StyledLink to={ROUTES.MAIN}>
        <Tab isActive={location.pathname === ROUTES.MAIN}>Members</Tab>
      </StyledLink>
      <StyledLink to={ROUTES.TEAMS}>
        <Tab isActive={location.pathname.includes(ROUTES.TEAMS)}>Teams</Tab>
      </StyledLink>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !!isOrganizationOwner(state),
});

export default connect<
  TWithHideableProp<unknown>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable(UsersManagementTabs));
