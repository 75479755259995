import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getSubscriptionName = (state: IReduxState) => state?.[NAME]?.name;

export const getSubscriptionId = (state: IReduxState) =>
  state?.[NAME]?.subscriptionId;

export const getSubscriptionValidFromDate = (state: IReduxState) =>
  state?.[NAME]?.validFrom;

export const getSubscriptionValidToDate = (state: IReduxState) =>
  state?.[NAME]?.validTo;

export const userHasSubscription = (state: IReduxState) =>
  state?.[NAME]?.userHasSubscription;

export const getPaymentProvider = (state: IReduxState) =>
  state?.[NAME]?.paymentProvider;

export const isAutoRenewEnabled = (state: IReduxState) =>
  state?.[NAME]?.isAutoRenewEnabled;
