import { useRef } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import ChartTitle from 'components/ChartTitle';
import ChartContainer from 'components/ChartContainer';
import BlueBarChart from 'components/BlueBarChart';
import DropdownSelect from 'components/DropdownSelect';
import ContainerPlaceholder from 'components/ContainerPlaceholder';

import { useSubcategoryUsage } from '../queries';
import { setCategoryForSubcategories } from '../actions';
import { getCategoryForSubcategories } from '../selectors';
import { CATEGORIES_EXTRA_OPTIONS, TCategorySelect } from '../types';
import { IReduxState } from 'state/types';
import { CATEGORIES } from 'utils/constants';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';

const Container = styled(ChartContainer)({
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
});

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const DropdownSelectContainer = styled.div({
  width: 158,
});

interface IMapStateToProps {
  selectedCategory: TCategorySelect;
  selectedTeam?: string;
  useSubcategoryUsageParam: CATEGORIES | null;
}

interface IMapDispatchToProps {
  onChange: (value: TCategorySelect) => void;
}

type TSubcategoriesUsage = IMapStateToProps & IMapDispatchToProps;

const options = [
  {
    value: CATEGORIES_EXTRA_OPTIONS.MOST_POPULAR,
    label: 'Most popular',
  },
  {
    value: CATEGORIES.SLEEP,
    label: 'Sleep',
  },
  {
    value: CATEGORIES.MUSIC,
    label: 'Music',
  },
  {
    value: CATEGORIES.MEDITATION,
    label: 'Meditation',
  },
  {
    value: CATEGORIES.PSYCHOLOGY,
    label: 'Psychology',
  },
  {
    value: CATEGORIES.BODY,
    label: 'Body',
  },
];

const SubcategoriesUsage: React.FC<TSubcategoriesUsage> = ({
  selectedCategory,
  useSubcategoryUsageParam,
  selectedTeam,
  onChange,
  ...rest
}) => {
  const { data, isLoading } = useSubcategoryUsage(
    useSubcategoryUsageParam,
    selectedTeam
  );

  const backdropRef = useRef<HTMLDivElement>(null);
  return (
    <Container {...rest} ref={backdropRef}>
      <Header>
        <ChartTitle>Subcategories</ChartTitle>
        <DropdownSelectContainer>
          <DropdownSelect<TCategorySelect>
            data={options}
            onChange={onChange}
            value={selectedCategory}
          />
        </DropdownSelectContainer>
      </Header>
      <ContainerPlaceholder
        isLoading={isLoading}
        hasNoData={!data?.length}
        backdropNode={backdropRef.current}
      >
        <BlueBarChart
          data={data}
          dataKeyX="sub_category"
          dataKeyY="play_time"
          tooltipFormatter={(value: string, _name?: string, props?: any) => [
            'min.',
            `${props?.payload?.sub_category}: ${value}`,
          ]}
        />
      </ContainerPlaceholder>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => {
  const selectedCategory = getCategoryForSubcategories(state);
  const useSubcategoryUsageParam =
    selectedCategory === CATEGORIES_EXTRA_OPTIONS.MOST_POPULAR
      ? null
      : selectedCategory;

  return {
    selectedCategory,
    useSubcategoryUsageParam,
    selectedTeam: getSelectedTeam(state),
  };
};

const mapDispatchToProps = {
  onChange: setCategoryForSubcategories,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(SubcategoriesUsage);
