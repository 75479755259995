import { createAction } from 'redux-actions';
import { TThunk } from 'types/common';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { callCoreApi, API_URLS, METHODS, STATUS_CODES } from 'api';
import { ERROR_TEXTS } from 'utils/constants';
import { setAuthData } from 'features/Auth/actions';
import { TSignInResponse } from 'features/Auth/types';
import { setLoading as setRegistrationLoading } from 'features/Registration/actions';

import { ISignInApiProps } from './types';
import { isLoading, getPassword, getEmail } from './selectors';
import {
  SET_EMAIL,
  SET_PASSWORD,
  SET_EMAIL_ERROR,
  SET_PASSWORD_ERROR,
  SET_ERROR,
  SET_LOADING,
  RESET_STATE,
} from './actionTypes';

export const setEmail = createAction<string>(SET_EMAIL);
export const setPassword = createAction<string>(SET_PASSWORD);
export const setEmailError = createAction<string>(SET_EMAIL_ERROR);
export const setPasswordError = createAction<string>(SET_PASSWORD_ERROR);
export const setError = createAction<string>(SET_ERROR);
export const setLoading = createAction<boolean>(SET_LOADING);
export const resetState = createAction(RESET_STATE);

export const handleSignIn = (): TThunk => (dispatch, getState) => {
  const state = getState();
  let isValidationError;

  if (isLoading(state)) {
    return;
  }

  dispatch(setEmailError(''));
  dispatch(setPasswordError(''));
  dispatch(setError(''));

  const email = getEmail(state);
  const password = getPassword(state);

  if (!email) {
    dispatch(setEmailError(ERROR_TEXTS.EMAIL_REQUIRED));

    isValidationError = true;
  }

  if (!password) {
    dispatch(setPasswordError(ERROR_TEXTS.PASSWORD_REQUIRED));

    isValidationError = true;
  }

  if (isValidationError) {
    return;
  }

  dispatch(signInRequest(email, password));
};

export const signInRequest =
  (email: string, password: string): TThunk =>
  (dispatch) => {
    dispatch(setLoading(true));

    callCoreApi<ISignInApiProps>({
      url: API_URLS.LOGIN,
      method: METHODS.POST,
      data: {
        username: email,
        password,
        type: 'business',
      },
    })
      .then((response: AxiosResponse<TSignInResponse>) => {
        dispatch(setAuthData(response.data));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === STATUS_CODES.UNAUTHORIZED) {
          dispatch(setError(ERROR_TEXTS.INCORRECT_EMAIL_OR_PASSWORD));
        } else {
          toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setRegistrationLoading(false));
      });
  };
