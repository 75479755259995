import { connect } from 'react-redux';
import styled from '@emotion/styled';

import MenuLink from './MenuLink';

import { ReactComponent as Logo } from 'assets/logo_dark.svg';
import { ReactComponent as ContentUsageActive } from 'assets/sideNavigation/contentUsageActive.svg';
import { ReactComponent as ContentUsageInactive } from 'assets/sideNavigation/contentUsageInactive.svg';
import { ReactComponent as UsersActivityActive } from 'assets/sideNavigation/usersActivityActive.svg';
import { ReactComponent as UsersActivityInactive } from 'assets/sideNavigation/usersActivityInactive.svg';
import { ReactComponent as UsersActive } from 'assets/sideNavigation/usersActive.svg';
import { ReactComponent as UsersInactive } from 'assets/sideNavigation/usersInactive.svg';
import { ReactComponent as MoodCheckInActive } from 'assets/sideNavigation/moodCheckInActive.svg';
import { ReactComponent as MoodCheckInInactive } from 'assets/sideNavigation/moodCheckInInactive.svg';

import Text from 'components/Text';
import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { isAuthenticated } from 'features/Auth/selectors';
import { ROUTES } from 'navigation/constants';

const Container = styled.aside((props) => ({
  height: '100vh',
  position: 'fixed',
  backgroundColor: props.theme.colors.appBackgroundGray,
  zIndex: 1000,
}));

const ContentContainer = styled.div({
  padding: '32px 24px 0 24px',
});

const LogoContainer = styled.div({
  display: 'flex',
  marginBottom: 32,
});

const LogoText = styled(Text)((props) => ({
  display: 'flex',
  alignItems: 'center',
  maxHeight: 19,
  borderLeft: `1px solid ${props.theme.colors.darkText}`,
  color: props.theme.colors.darkText,
  paddingLeft: 14,
  marginTop: 10,
}));

const StyledLogo = styled(Logo)({
  width: 96,
});

const SideNavigationBar: React.FC = ({ ...rest }) => (
  <Container {...rest}>
    <ContentContainer>
      <LogoContainer>
        <StyledLogo />
        <LogoText type="h2">BUSINESS</LogoText>
      </LogoContainer>
      <MenuLink
        activeImage={<UsersActive />}
        inactiveImage={<UsersInactive />}
        to={ROUTES.MAIN}
        text="User management"
      />
      <MenuLink
        activeImage={<UsersActivityActive />}
        inactiveImage={<UsersActivityInactive />}
        to={ROUTES.USERS_ACTIVITY}
        text="User activity"
      />
      <MenuLink
        activeImage={<ContentUsageActive />}
        inactiveImage={<ContentUsageInactive />}
        to={ROUTES.CONTENT_USAGE}
        text="Content usage"
      />
      <MenuLink
        activeImage={<MoodCheckInActive />}
        inactiveImage={<MoodCheckInInactive />}
        to={ROUTES.MOOD_TRACKER}
        text="Mood tracker"
      />
    </ContentContainer>
  </Container>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: isAuthenticated(state),
});

export default connect<
  TWithHideableProp<unknown>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable(SideNavigationBar));
