import { connect } from 'react-redux';
import styled from '@emotion/styled';

import Text from 'components/Text';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';

import { setDeleteLicenseeUid, deleteLicensee } from '../actions';
import { getLicenseeToDeleteUid, isLoading } from '../selectors';

interface IMapStateToProps {
  isModalVisible: boolean;
  isButtonLoading: boolean;
}

interface IMapDispatchToProps {
  onCancelClick: () => void;
  onConfirmClick: () => void;
}

type TDeleteLicenseePopup = IMapStateToProps & IMapDispatchToProps;

const ButtonsContainer = styled.div({
  display: 'flex',
  marginTop: 32,
});

const StyledButton = styled(Button)({
  margin: 5,
});

const DeleteLicenseePopup: React.FC<TDeleteLicenseePopup> = ({
  isModalVisible,
  isButtonLoading,
  onCancelClick,
  onConfirmClick,
}) => (
  <Modal onModalOutsideClick={onCancelClick} isVisible={isModalVisible}>
    <Text type="h1" weight={600} align="center">
      Are you sure you want to delete this licensee
    </Text>
    <ButtonsContainer>
      <StyledButton onClick={onCancelClick} text="Cancel" buttonType="light" />
      <StyledButton
        onClick={onConfirmClick}
        text="Confirm"
        isLoading={isButtonLoading}
      />
    </ButtonsContainer>
  </Modal>
);

const mapStateToProps = (state: IReduxState) => ({
  isModalVisible: !!getLicenseeToDeleteUid(state),
  isButtonLoading: isLoading(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onCancelClick: () => dispatch(setDeleteLicenseeUid('')),
  onConfirmClick: () => dispatch(deleteLicensee()),
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(DeleteLicenseePopup);
