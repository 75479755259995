import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Checkmark } from 'assets/checkmark.svg';

interface IStepsIndicator {
  steps: number;
  currentStep: number;
}

interface IStyledStep {
  isFutureStep: boolean;
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
});

const Dash = styled.div<IStyledStep>((props) => ({
  width: 9,
  height: 0,
  borderBottom: `2px solid ${
    props.isFutureStep
      ? props.theme.colors.white
      : props.theme.colors.activeBlue
  }`,
}));

const StepContainer = styled.div<IStyledStep>((props) => ({
  display: 'flex',
  height: 20,
  width: 20,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 100,
  fontSize: 12,
  margin: '0 1px',
  backgroundColor: props.isFutureStep
    ? props.theme.colors.white
    : props.theme.colors.activeBlue,
  color: props.isFutureStep
    ? props.theme.colors.darkText
    : props.theme.colors.white,
}));

const StepsIndicator: React.FC<IStepsIndicator> = ({ steps, currentStep }) => (
  <Container>
    {[...Array(steps)].map((_step, index: number) => (
      <React.Fragment key={index}>
        {index !== 0 && <Dash isFutureStep={currentStep < index + 1} />}
        <StepContainer isFutureStep={currentStep < index + 1}>
          {index + 1 < currentStep ? <Checkmark /> : index + 1}
        </StepContainer>
      </React.Fragment>
    ))}
  </Container>
);

export default StepsIndicator;
