import { useRef } from 'react';
import styled from '@emotion/styled';

import useOnClickOutside from 'utils/useOnClickOutside';
import hideable from 'utils/hideable';

interface IModal {
  onModalOutsideClick: () => void;
  children: React.ReactNode;
}

const Overlay = styled.div({
  position: 'fixed',
  zIndex: 10000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'rgba(6, 18, 24, 0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContent = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  padding: '48px 70px',
  maxWidth: 367,
}));

const Modal: React.FC<IModal> = ({
  children,
  onModalOutsideClick,
  ...rest
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, onModalOutsideClick);

  return (
    <Overlay>
      <ModalContent ref={ref} {...rest}>
        {children}
      </ModalContent>
    </Overlay>
  );
};

export default hideable<IModal>(Modal);
