import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { IReduxState } from 'state/types';

import TeamCard from './TeamCard';
import CreateTeam from './CreateTeam';
import { fetchTeams, resetTeams } from '../actions';
import { getTeamsUids } from '../selectors';
import { BREAKPOINTS } from 'theme/constants';

interface IMapStateToProps {
  teamsUids: string[];
}

interface IMapDispatchToProps {
  onMount: () => void;
  onUnmount: () => void;
}

type TManageTeams = IMapDispatchToProps & IMapStateToProps;

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: '0 35px',
  [BREAKPOINTS.XL_TABLET]: {
    padding: '0 14px',
  },
});

const ManageTeams: React.FC<TManageTeams> = ({ teamsUids }) => (
  <>
    <CreateTeam />
    <Container>
      {teamsUids.map((uid, index) => (
        <TeamCard index={index} key={uid} uid={uid} />
      ))}
    </Container>
  </>
);

const mapStateToProps = (state: IReduxState) => ({
  teamsUids: getTeamsUids(state) || [],
});

const mapDispatchToProps = {
  onMount: fetchTeams,
  onUnmount: resetTeams,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(ManageTeams);
