import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { IInviteLicensee } from './types';
import {
  SET_LOADING,
  SET_LIMIT_ERROR_VISIBLE,
  SET_POPUP_VISIBLE,
  SET_LICENSEE_EMAIL_ERROR,
  SET_LICENSEE_EMAIL,
} from './actionTypes';

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const isLimitErrorVisibleReducer = handleAction<boolean, boolean>(
  SET_LIMIT_ERROR_VISIBLE,
  updateSimpleValue,
  false
);

const isPopupVisibleReducer = handleAction<boolean, boolean>(
  SET_POPUP_VISIBLE,
  updateSimpleValue,
  false
);

const licenseeEmailReducer = handleAction<string, string>(
  SET_LICENSEE_EMAIL,
  updateSimpleValue,
  ''
);

const licenseeEmailErrorReducer = handleAction<string, string>(
  SET_LICENSEE_EMAIL_ERROR,
  updateSimpleValue,
  ''
);

export default combineReducers<IInviteLicensee>({
  isLoading: isLoadingReducer,
  isLimitErrorVisible: isLimitErrorVisibleReducer,
  isPopupVisible: isPopupVisibleReducer,
  licenseeEmail: licenseeEmailReducer,
  licenseeEmailError: licenseeEmailErrorReducer,
});
