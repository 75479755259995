import styled from '@emotion/styled';
import { connect } from 'react-redux';

import TopMetricCard from 'components/TopMetricCard';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';
import { ReactComponent as TopContentUnit } from 'assets/contentUsage/top_content_unit.svg';
import { ReactComponent as TopPlaylist } from 'assets/contentUsage/top_playlist.svg';
import { ReactComponent as TopCategory } from 'assets/contentUsage/top_category.svg';
import { BREAKPOINTS } from 'theme/constants';
import { IReduxState } from 'state/types';

import { useCategoryUsage, usePlaylistsUsage, useMediaUsage } from '../queries';

interface IMapStateToProps {
  selectedTeam?: string;
}

type TTopContentUnits = IMapStateToProps;

const Container = styled.div({
  display: 'flex',
  marginBottom: 40,
  [BREAKPOINTS.TABLET]: {
    flexDirection: 'column',
  },
});

const CardWithMargin = styled(TopMetricCard)({
  margin: '0 26px',
  [BREAKPOINTS.TABLET]: {
    margin: '12px 0',
  },
});

const TopContentUnits: React.FC<TTopContentUnits> = ({ selectedTeam }) => {
  const { data: categoryData } = useCategoryUsage(selectedTeam);
  const { data: mediaData } = useMediaUsage(selectedTeam);
  const { data: playlistData } = usePlaylistsUsage('playlist', selectedTeam);

  const topCategory = categoryData?.[0]?.category || 'No data';
  const topPlaylist = playlistData?.[0]?.title || 'No data';
  const topContentUnit = mediaData?.[0]?.title || 'No data';

  return (
    <Container>
      <TopMetricCard
        icon={<TopContentUnit />}
        title="Top content unit"
        result={topContentUnit}
      />
      <CardWithMargin
        icon={<TopCategory />}
        title="Top category"
        result={topCategory}
      />
      <TopMetricCard
        icon={<TopPlaylist />}
        title="Top playlist"
        result={topPlaylist}
      />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  selectedTeam: getSelectedTeam(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(TopContentUnits);
