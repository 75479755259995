import styled from '@emotion/styled';

import { BREAKPOINTS } from 'theme/constants';

import { MOODS, TEAM_QUESTIONS } from '../../constants';
import { useMoodsQuestions, useSummaryStats } from '../../queries';

import SummaryCard from './SummaryCard';
import { useMemo } from 'react';

const Container = styled.div({
  display: 'flex',
  columnGap: 26,
  rowGap: 12,
  [BREAKPOINTS.TABLET]: {
    flexDirection: 'column',
  },
});

const formatDate = (date: Date) =>
  date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

const SummaryStats: React.FC = () => {
  const moodQuestionsData = useMoodsQuestions(TEAM_QUESTIONS.MENTAL);

  const { data, isLoading } = useSummaryStats();

  const {
    overallMood,
    bestDay,
    worstDay,
    overallMoodIcon,
    bestDayIcon,
    worstDayIcon,
  } = useMemo(() => {
    const summaryInfo = {} as {
      overallMood?: string;
      bestDay?: string;
      worstDay?: string;
      overallMoodIcon?: string;
      bestDayIcon?: string;
      worstDayIcon?: string;
    };

    const dataExists = data?.overall_mood;
    const answersExist = moodQuestionsData?.answers.length;

    if (answersExist) {
      const { answers } = moodQuestionsData;
      summaryInfo.bestDayIcon = answers[MOODS.GOOD].icon;
      summaryInfo.worstDayIcon = answers[MOODS.BAD].icon;
    }

    if (dataExists) {
      const { best_day, worst_day } = data;
      summaryInfo.bestDay = formatDate(new Date(best_day));
      summaryInfo.worstDay = formatDate(new Date(worst_day));
    }

    if (answersExist && dataExists) {
      const { answers } = moodQuestionsData;
      const { overall_mood } = data;
      summaryInfo.overallMood = answers[overall_mood].answer;
      summaryInfo.overallMoodIcon = answers[overall_mood].icon;
    }

    return summaryInfo;
  }, [data, moodQuestionsData]);

  return (
    <Container>
      <SummaryCard
        title="Overall mood"
        result={overallMood}
        resultSuffix=" · Last 30 days"
        icon={overallMoodIcon}
        isLoading={isLoading}
      />
      <SummaryCard
        title="Best Day"
        result={bestDay}
        resultSuffix={' · Last year'}
        icon={bestDayIcon}
        isLoading={isLoading}
      />
      <SummaryCard
        title="Worst Day"
        result={worstDay}
        resultSuffix={' · Last year'}
        icon={worstDayIcon}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default SummaryStats;
