import styled from '@emotion/styled';

interface IStyleProps {
  backgroundColor?: string;
}

interface ITeamLogoProps {
  teamName: string;
}

type TTeamLogo = IStyleProps & ITeamLogoProps;

const StyledTeamLogo = styled.div<IStyleProps>((props) => ({
  backgroundColor: props.backgroundColor,
  color: props.theme.colors.formBackgroundGray,
  height: 56,
  width: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 12,
  marginRight: 14,
}));

const TeamLogo: React.FC<TTeamLogo> = ({
  teamName,
  backgroundColor = '#2D2D2D',
}) => {
  const teamInitials = `${teamName.charAt(0).toLocaleUpperCase()}${teamName
    .charAt(2)
    .toLocaleUpperCase()}
  `;

  return (
    <StyledTeamLogo backgroundColor={backgroundColor}>
      {teamInitials}
    </StyledTeamLogo>
  );
};

export default TeamLogo;
