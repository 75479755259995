import styled from '@emotion/styled';

import Text from 'components/Text';
import TopMetricCard from 'components/TopMetricCard';

interface ISummaryCard {
  title: string;
  isLoading: boolean;
  icon?: string;
  result?: string;
  resultSuffix: string;
}
const SummaryTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.titleText,
  lineHeight: '24px',
}));

const SummaryResult = styled(Text)(({ theme }) => ({
  color: theme.colors.lightGray,
  fontSize: 10,
  lineHeight: '12px',
  '> strong': {
    fontWeight: 600,
  },
}));

const SummaryMoodIcon = styled.img({
  width: 40,
  height: 40,
  transform: 'scale(1.25)',
  objectFit: 'cover',
});

const SummaryCard: React.FC<ISummaryCard> = ({
  icon,
  title,
  isLoading,
  result,
  resultSuffix,
}) => {
  return (
    <TopMetricCard
      icon={<></>}
      CustomTitleComponent={
        <SummaryTitle type="h3" weight={600}>
          {title}
        </SummaryTitle>
      }
      CustomResultComponent={
        <SummaryResult weight="normal">
          {result ? (
            <>
              <strong>{result}</strong>
              {resultSuffix}
            </>
          ) : isLoading ? (
            ''
          ) : (
            'No data'
          )}
        </SummaryResult>
      }
      graphImage={icon ? <SummaryMoodIcon src={icon} alt={result} /> : <></>}
    />
  );
};

export default SummaryCard;
