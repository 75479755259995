import { createAction } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';

import { TThunk } from 'types/common';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { isOrganizationOwner } from 'features/Auth/selectors';
import { setSelectedTeam } from 'features/StatsTeamSelect/actions';

import { TNormalizedTeam, ICreateTeamApiProps, ITeam } from './types';
import { getTeamsUids, getNewTeamName, isLoading } from './selectors';
import {
  SET_TEAMS,
  RESET_TEAMS,
  SET_LOADING,
  SET_SEARCH_VALUE,
  SET_CREATE_TEAM_MODAL_VISIBLE,
  DELETE_TEAM,
  SET_NEW_TEAM_NAME,
} from './actionTypes';

export const setTeams = createAction<TNormalizedTeam[]>(SET_TEAMS);
export const resetTeams = createAction(RESET_TEAMS);
export const setLoading = createAction<boolean>(SET_LOADING);
export const deleteTeam = createAction<string>(DELETE_TEAM);
export const setSearchValue = createAction<string>(SET_SEARCH_VALUE);
export const setNewTeamName = createAction<string>(SET_NEW_TEAM_NAME);
export const setCreateTeamModalVisible = createAction<boolean>(
  SET_CREATE_TEAM_MODAL_VISIBLE
);

export const handleCreateTeam = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const teamName = getNewTeamName(state);
  const isFetchLoading = isLoading(state);

  if (isFetchLoading) {
    return;
  }

  dispatch(setLoading(true));

  callCoreApi<ICreateTeamApiProps>({
    method: METHODS.POST,
    url: API_URLS.TEAMS,
    authorized: true,
    data: {
      name: teamName,
    },
  })
    .then((response: AxiosResponse<ITeam>) => {
      const normalizedData = {
        ...response.data,
        uid: response.data.id,
      };

      dispatch(setTeams([normalizedData]));
      dispatch(setCreateTeamModalVisible(false));
      dispatch(setNewTeamName(''));
    })
    .catch((_error: AxiosError) => {
      // TODO: handle error
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const handleDeleteTeam =
  (uid: string): TThunk =>
  (dispatch) => {
    dispatch(setLoading(true));

    callCoreApi({
      method: METHODS.DELETE,
      url: API_URLS.TEAMS,
      path: `${uid}/`,
      authorized: true,
    })
      .then(() => {
        dispatch(deleteTeam(uid));
        dispatch(setSelectedTeam(''));
      })
      .catch((_error: AxiosError) => {
        // TODO: handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const fetchTeams = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const isFetched = !!getTeamsUids(state)?.length;
  const hasAccessToTeams = isOrganizationOwner(state);

  if (isFetched) {
    return;
  }

  if (!hasAccessToTeams) {
    return;
  }

  dispatch(setLoading(true));

  callCoreApi({
    method: METHODS.GET,
    url: API_URLS.TEAMS,
    authorized: true,
  })
    .then((response: AxiosResponse<ITeam[]>) => {
      const normalizedData = response.data.map((team) => ({
        ...team,
        uid: team.id,
      }));

      dispatch(setTeams(normalizedData));
    })
    .catch((_error: AxiosError) => {
      // TODO: handle error
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
