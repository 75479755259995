import { TooltipProps } from 'recharts';
import styled from '@emotion/styled';

import Text from 'components/Text';
import { ReactComponent as NoDataIcon } from 'assets/no_data.svg';

import {
  IMoodsQuestionsApiResponse,
  ITeamsQuestionsApiResponse,
} from '../../types';

const TooltipContainer = styled.div<{
  $translateY: number;
}>(({ theme, $translateY }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.colors.white,
  borderRadius: 4,
  padding: '12px 36px 5px',
  rowGap: 4,
  transform: `translate(-50%, ${$translateY - 6}px)`,
  position: 'relative',
  ':after': {
    content: '""',
    display: 'block',
    width: 16,
    height: 6,
    background: theme.colors.white,
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 100%)',
    clipPath: 'polygon(0% 0%, 50% 100%, 100% 0%)',
  },
}));
const TooltipMoodIcon = styled.img({
  width: 26,
  height: 26,
  objectFit: 'cover',
});

const TooltipText = styled(Text)((props) => ({
  color: props.theme.colors.black,
  fontSize: 10,
  lineHeight: '16px',
  letterSpacing: 0.1,
  fontWeight: 700,
}));

const TooltipCheckinsText = styled(TooltipText)((props) => ({
  color: props.theme.colors.darkGray,
  fontWeight: 400,
}));

const getTopTooltipTranslateY = (
  tooltipProps: TooltipProps<any[], any>,
  dataMax: number
) => {
  const [{ payload, name }] = tooltipProps.payload!;

  const value = payload[name];

  const { y: tooltipHeight = 0, height: chartHeight = 0 } =
    tooltipProps.viewBox!;

  const cursorY = tooltipProps.coordinate?.y ?? 0;
  const chartCursorY = chartHeight - (chartHeight / dataMax) * value;

  return chartCursorY - cursorY - tooltipHeight;
};

interface ITeamQuestionsTooltip extends TooltipProps<any[], string> {
  dataMax: number;
  answers: IMoodsQuestionsApiResponse['answers'];
}

const TeamsQuestionsTooltip: React.FC<ITeamQuestionsTooltip> = ({
  dataMax,
  answers,
  ...props
}) => {
  if (!props.payload?.length) return null;

  const [
    {
      payload: { answer_score, answer_count },
    },
  ] = props.payload as { payload: ITeamsQuestionsApiResponse }[];

  const { icon, answer } = answers[Math.round(answer_score)];

  const translateY = getTopTooltipTranslateY(props, dataMax);

  return (
    <TooltipContainer $translateY={translateY}>
      {answer_count ? (
        <TooltipMoodIcon src={icon} alt={answer} />
      ) : (
        <NoDataIcon width={26} height={26} />
      )}
      <TooltipText>{answer_count ? answer : 'No data'}</TooltipText>
      <TooltipCheckinsText>{`${answer_count} check-ins`}</TooltipCheckinsText>
    </TooltipContainer>
  );
};

export default TeamsQuestionsTooltip;
