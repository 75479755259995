import styled from '@emotion/styled';

interface IExternalLink {
  newTab?: boolean;
  to: string;
}

const StyledLink = styled.a((props) => ({
  color: props.theme.colors.lightBlue,
}));

const ExternalLink: React.FC<IExternalLink> = ({ children, to, newTab }) => (
  <StyledLink href={to} target={newTab ? '_blank' : undefined}>
    {children}
  </StyledLink>
);

export default ExternalLink;
