import { IOrganization } from 'features/Auth/types';
import { IByUid } from 'types/common';
import { IUidsPayload } from 'utils/reducerHelpers';

export interface ILicensees {
  licenseesByUid: IByUid<TNormalizedLicensee>;
  licenseesUids: string[];
  isLoading: boolean;
}

export type TStatus = 'inactive' | 'active';

export interface ILicensee {
  id: string;
  email: string;
  status: TStatus;
  created_at: string;
  organization: IOrganization;
  role: ROLE;
}

export enum ROLE {
  TEAM_LEAD = 'team_lead',
  MEMBER = 'member',
  OWNER = 'owner',
}

export type TNormalizedLicensee = ILicensee & IUidsPayload;

export interface IAddLicenseeApiProps {
  email: string;
}
