import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { ISubscription, PAYMENT_PROVIDERS } from './types';
import {
  SET_SUBSCRIPTION_VALID_TO_DATE,
  SET_SUBSCRIPTION_NAME,
  SET_SUBSCRIPTION_VALID_FROM_DATE,
  SET_PAYMENT_PROVIDER,
  SET_AUTO_RENEW_STATUS,
  SET_USER_HAS_SUBSCRIPTION,
  SET_SUBSCRIPTION_ID,
} from './actionTypes';

const validToReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_VALID_TO_DATE,
  updateSimpleValue,
  ''
);

const validFromReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_VALID_FROM_DATE,
  updateSimpleValue,
  ''
);

const nameReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_NAME,
  updateSimpleValue,
  ''
);

const subscriptionIdReducer = handleAction<string, string>(
  SET_SUBSCRIPTION_ID,
  updateSimpleValue,
  ''
);

const isAutoRenewEnabledReducer = handleAction<boolean, boolean>(
  SET_AUTO_RENEW_STATUS,
  updateSimpleValue,
  false
);

const userHasSubscriptionReducer = handleAction<boolean | null, boolean | null>(
  SET_USER_HAS_SUBSCRIPTION,
  updateSimpleValue,
  null
);

const paymentProviderReducer = handleAction<
  PAYMENT_PROVIDERS | null,
  PAYMENT_PROVIDERS
>(SET_PAYMENT_PROVIDER, updateSimpleValue, null);

const subscriptionReducers = combineReducers<ISubscription>({
  name: nameReducer,
  validFrom: validFromReducer,
  validTo: validToReducer,
  paymentProvider: paymentProviderReducer,
  isAutoRenewEnabled: isAutoRenewEnabledReducer,
  subscriptionId: subscriptionIdReducer,
  userHasSubscription: userHasSubscriptionReducer,
});

export default subscriptionReducers;
