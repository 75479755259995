import PriceCalculatorErrorContainer from './PriceCalculatorErrorContainer';
import PriceCalculatorInputContainer from './PriceCalculatorInputContainer';
import PriceCalculatorResultContainer from './PriceCalculatorResultContainer';

const PriceCalculator: React.FC = () => (
  <>
    <PriceCalculatorInputContainer />
    <PriceCalculatorResultContainer />
    <PriceCalculatorErrorContainer />
  </>
);

export default PriceCalculator;
