import { connect } from 'react-redux';

import ActionForm from 'components/ActionForm';
import { IReduxState } from 'state/types';
import { TWithHideableProp } from 'utils/hideable';

import { setLicenseeEmail, handlePopupClose, addNewLicensee } from '../actions';
import {
  getLicenseeEmail,
  getLicenseeEmailError,
  isLimitErrorVisible,
  isLoading,
} from '../selectors';

interface IMapStateToProps {
  value: string;
  error: string;
  isButtonLoading: boolean;
  title: string;
  description: string;
  inputPlaceholder: string;
  cancelButtonText: string;
  submitButtonText: string;
}

interface IMapDispatchToProps {
  onChange: (value: string) => void;
  onCancelClick: () => void;
  onSubmitClick: () => void;
}

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !isLimitErrorVisible(state),
  value: getLicenseeEmail(state),
  error: getLicenseeEmailError(state),
  isButtonLoading: isLoading(state),
  title: 'Add new licensees to Yours',
  description: 'People you add will receive an invite automatically',
  inputPlaceholder: 'Email',
  cancelButtonText: 'Cancel',
  submitButtonText: 'Add user',
});

const mapDispatchToProps = {
  onChange: setLicenseeEmail,
  onCancelClick: handlePopupClose,
  onSubmitClick: addNewLicensee,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(ActionForm);
