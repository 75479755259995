import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { IExtraLicensesPurchase } from './types';
import {
  SET_PURCHASE_POPUP_VISIBLE,
  SET_QUANTITY_OF_LICENSES,
  SET_QUANTITY_OF_LICENSES_ERROR,
  SET_GET_PRICE_LOADING,
  SET_PRICE,
  SET_PURCHASE_LOADING,
} from './actionTypes';

const isPurchasePopupVisibleReducer = handleAction<boolean, boolean>(
  SET_PURCHASE_POPUP_VISIBLE,
  updateSimpleValue,
  false
);

const isGetPriceLoadingReducer = handleAction<boolean, boolean>(
  SET_GET_PRICE_LOADING,
  updateSimpleValue,
  false
);

const isPurchaseLoadingReducer = handleAction<boolean, boolean>(
  SET_PURCHASE_LOADING,
  updateSimpleValue,
  false
);

const quantityOfLicensesReducer = handleAction<string, string>(
  SET_QUANTITY_OF_LICENSES,
  updateSimpleValue,
  ''
);

const priceReducer = handleAction<string, string>(
  SET_PRICE,
  updateSimpleValue,
  ''
);

const quantityOfLicensesErrorReducer = handleAction<string, string>(
  SET_QUANTITY_OF_LICENSES_ERROR,
  updateSimpleValue,
  ''
);

export default combineReducers<IExtraLicensesPurchase>({
  isPurchasePopupVisible: isPurchasePopupVisibleReducer,
  isGetPriceLoading: isGetPriceLoadingReducer,
  quantityOfLicenses: quantityOfLicensesReducer,
  quantityOfLicensesError: quantityOfLicensesErrorReducer,
  isPurchaseLoading: isPurchaseLoadingReducer,
  price: priceReducer,
});
