import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TeamSettingsPopup from './TeamSettingsPopup';
import { getTeamNameByUid } from '../selectors';
import { TEAM_COLOR_PALETTE } from '../constants';

import { ReactComponent as TeamSettingsDots } from 'assets/team_settings.svg';
import TeamLogo from 'components/TeamLogo';
import Text from 'components/Text';
import { ROUTES } from 'navigation/constants';
import { IReduxState } from 'state/types';

interface IOwnProps {
  uid: string;
  index: number;
}

interface IMapStateToProps {
  teamName: string;
}

type TTeamCard = IMapStateToProps & IOwnProps;

const Card = styled.div((props) => ({
  borderRadius: 12,
  border: `1px solid ${props.theme.colors.textGray}`,
  padding: '30px 24px 30px 34px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 10,
  ':hover': {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
    borderColor: props.theme.colors.linkBlue,
  },
}));

const TeamInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const TeamSettingsButton = styled.span({
  margin: 0,
  padding: '10px 10px',
  position: 'relative',
  cursor: 'pointer',
});

const TeamCard: React.FC<TTeamCard> = ({ teamName, uid, index }) => {
  const [isSettingsPopupVisible, setSettingsPopupVisible] = useState(false);

  const handleSettingsButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setSettingsPopupVisible(true);
  };

  const logoColor =
    index < TEAM_COLOR_PALETTE.length
      ? TEAM_COLOR_PALETTE[index]
      : TEAM_COLOR_PALETTE[index % TEAM_COLOR_PALETTE.length];

  return (
    <StyledLink to={`${ROUTES.TEAMS}/${teamName}/${uid}`}>
      <Card>
        <TeamInfo>
          <TeamLogo backgroundColor={logoColor} teamName={teamName} />
          <Text>{teamName}</Text>
        </TeamInfo>
        <TeamSettingsButton onClick={handleSettingsButtonClick}>
          <TeamSettingsDots />
          <TeamSettingsPopup
            isVisible={isSettingsPopupVisible}
            teamUid={uid}
            onOutsideClick={() => setSettingsPopupVisible(false)}
          />
        </TeamSettingsButton>
      </Card>
    </StyledLink>
  );
};

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => ({
  teamName: getTeamNameByUid(state, uid) || '',
});

export default connect<IMapStateToProps, unknown, IOwnProps, IReduxState>(
  mapStateToProps
)(TeamCard);
