import { useState, useRef } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ArrowUp } from 'assets/arrow_up.svg';
import { ReactComponent as ArrowDown } from 'assets/arrow_down.svg';
import useOnClickOutside from 'utils/useOnClickOutside';

interface IDropdownSelect<T> {
  value: T;
  data: IData<T>[];
  isLight?: boolean;
  onChange: (value: T) => void;
}

export interface IData<T> {
  label: string;
  value: T;
}

interface IContainer {
  isOpen: boolean;
  isLight: boolean;
}

const Container = styled.div<IContainer>(({ isOpen, theme, isLight }) => ({
  position: 'relative',
  borderWidth: 2,
  borderRadius: 10,
  border: `1px solid ${
    isOpen ? theme.colors.textGray : 'rgba(138, 147, 159, 0.24)'
  }`,
  ...(isLight
    ? {
        backgroundColor: theme.colors.formBackgroundGray,
        border: 'none',
      }
    : {}),
}));

const LabelContainer = styled.div({
  padding: '12px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

const Label = styled.div((props) => ({
  fontSize: 14,
  fontWeight: 400,
  color: props.theme.colors.titleText,
  marginRight: 'auto',
}));

const DropdownMenu = styled.div({
  position: 'absolute',
  top: 60,
  left: 0,
  right: 0,
  zIndex: 100,
  boxShadow: '-15px 19px 35px -2px rgba(0,0,0,0.07)',
  maxHeight: 200,
  overflowY: 'scroll',
});

const Option = styled.div((props) => ({
  cursor: 'pointer',
  fontSize: 14,
  color: '#444444',
  fontWeight: 400,
  lineHeight: '20px',
  padding: '11px 22px',
  backgroundColor: props.theme.colors.white,
  ':hover': {
    backgroundColor: props.theme.colors.formBackgroundGray,
  },
}));

const DropdownSelect = <T,>({
  data,
  value,
  onChange,
  isLight = false,
  ...rest
}: IDropdownSelect<T>) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setDropdownOpen(false));

  const onClick = () => {
    setDropdownOpen((value) => !value);
  };

  const currentLabel = data?.find((option) => option.value === value)?.label;

  return (
    <Container
      isOpen={isDropdownOpen}
      onClick={onClick}
      isLight={isLight}
      {...rest}
    >
      <LabelContainer>
        <Label>{currentLabel}</Label>
        {isDropdownOpen && <ArrowUp />}
        {!isDropdownOpen && <ArrowDown />}
      </LabelContainer>
      {isDropdownOpen && (
        <DropdownMenu ref={ref}>
          {data.map((option) => (
            <Option key={option.label} onClick={() => onChange(option.value)}>
              {option.label}
            </Option>
          ))}
        </DropdownMenu>
      )}
    </Container>
  );
};

export default DropdownSelect;
