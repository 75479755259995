import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import store from 'state/store';
import { ERROR_TEXTS } from 'utils/constants';
import { getAccessToken, getRefreshToken } from 'features/Auth/selectors';
import { TSignInResponse } from 'features/Auth/types';
import { CORE_API, API_URLS, METHODS, CUSTOM_ERROR_CODES } from './constants';
import {
  logout,
  refreshTokenRequest,
  setAuthData,
} from 'features/Auth/actions';

interface IQueryParameter {
  key: string;
  value: string;
}

interface ICallApiProps<T> {
  method: METHODS;
  url: API_URLS;
  data?: T;
  queryParams?: IQueryParameter[];
  path?: string;
  authorized?: boolean;
}

axios.interceptors.response.use(
  (value: AxiosResponse<any>) => value,
  async (error: AxiosError) => {
    if (
      error?.config?.url === `${CORE_API}${API_URLS.REFRESH_TOKEN}` ||
      error?.response?.data?.custom_status_code !==
        CUSTOM_ERROR_CODES.STATUS_INVALID_TOKEN
    ) {
      return Promise.reject(error);
    }

    try {
      const refreshToken = getRefreshToken(store.getState());

      if (!refreshToken) {
        throw 'Something went wrong';
      }

      const response: AxiosResponse<TSignInResponse> =
        await refreshTokenRequest(refreshToken);
      const accessToken = response?.data?.access;
      error.config.headers.Authorization = `Bearer ${accessToken}`;

      store.dispatch<any>(setAuthData(response.data));

      return axios.request(error.config);
    } catch {
      toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      store.dispatch<any>(logout());
    }
  }
);

const callCoreApi = <T>({
  method,
  url,
  data,
  queryParams = [],
  path = '',
  authorized,
}: ICallApiProps<T>) => {
  const state = store.getState();
  const accessToken = getAccessToken(state);

  const params = queryParams
    .map(
      (parameter, index) =>
        `${
          parameter.value
            ? `${index === 0 ? '?' : '&'}${parameter.key}=${encodeURIComponent(
                parameter.value
              )}`
            : ''
        }`
    )
    .join('');

  return axios({
    method,
    url: `${CORE_API}${url}${path}${!!params?.length ? params : ''}`,
    data,
    headers: authorized
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });
};

export default callCoreApi;
