import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

import lightLoaderJson from 'assets/light_loader.json';
import darkLoaderJson from 'assets/dark_loader.json';
import { ReactComponent as NoDataIcon } from 'assets/no_data.svg';

import Text from './Text';
import AnimateJson from './AnimateJson';

type IContainerPlaceholder = {
  children: React.ReactNode;
  isLoading: boolean;
  dark?: boolean;
  backdropNode?: HTMLElement | null;
  className?: string;
} & (
  | {
      hideNoDataPlaceholder?: false;
      hasNoData: boolean;
    }
  | {
      hideNoDataPlaceholder: true;
      hasNoData?: never;
    }
);

const Container = styled.div<{ $shouldRenderPlaceholder: boolean }>(
  ({ $shouldRenderPlaceholder }) => ({
    position: 'relative',
    minHeight: $shouldRenderPlaceholder ? 180 : 0,
    transition: 'min-height 400ms',
  })
);

const AbsoluteContainer = styled.div<{ $isVisible?: boolean }>(
  ({ $isVisible = true }) => ({
    position: 'absolute',
    top: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: $isVisible ? 1 : 0,
    pointerEvents: $isVisible ? 'unset' : 'none',
    transition: 'opacity 400ms',
  })
);

const Backdrop = styled.div<{
  $backdropNode: HTMLElement;
  $dark: boolean;
  $isVisible: boolean;
}>(({ theme: { colors }, $backdropNode, $dark, $isVisible }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  width: '100%',
  height: '100%',
  background: $dark ? colors.black : colors.white,
  opacity: $isVisible ? 0.3 : 0,
  pointerEvents: 'none',
  borderRadius: getComputedStyle($backdropNode).borderRadius ?? 0,
  transition: 'opacity 400ms',
}));

const Loader = styled(AnimateJson)({
  width: 'min(150px, 25%)',
});

const NoDataText = styled(Text)((props) => ({
  color: props.theme.colors.textGray,
}));

const NoData: React.FC = () => (
  <AbsoluteContainer>
    <NoDataIcon />
    <NoDataText weight={600} type="h2">
      No data
    </NoDataText>
  </AbsoluteContainer>
);

const ContainerPlaceholder: React.FC<IContainerPlaceholder> = ({
  children,
  isLoading,
  hasNoData,
  dark = false,
  hideNoDataPlaceholder = false,
  backdropNode,
  ...rest
}) => {
  const shouldRenderNoData = !hideNoDataPlaceholder && !isLoading && hasNoData;
  const normalizedChildren = shouldRenderNoData ? <NoData /> : children;

  const lastChildrenRef = useRef<React.ReactNode>(children);

  const [shouldRenderLoader, setShouldRenderLoader] = useState(false);

  useEffect(() => {
    if (backdropNode) backdropNode.style.position = 'relative';
  }, [backdropNode]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoading) {
      timeout = setTimeout(() => setShouldRenderLoader(true), 400);
    } else {
      lastChildrenRef.current = normalizedChildren;
      setShouldRenderLoader(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <>
      <Container
        {...rest}
        $shouldRenderPlaceholder={isLoading || !!shouldRenderNoData}
      >
        {isLoading ? lastChildrenRef.current : normalizedChildren}
        <AbsoluteContainer $isVisible={shouldRenderLoader}>
          <Loader data={dark ? darkLoaderJson : lightLoaderJson} />
        </AbsoluteContainer>
      </Container>
      {!!backdropNode &&
        ReactDOM.createPortal(
          <Backdrop
            $backdropNode={backdropNode}
            $dark={dark}
            $isVisible={shouldRenderLoader}
          />,
          backdropNode
        )}
    </>
  );
};

export default ContainerPlaceholder;
