import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  TooltipProps,
  XAxisProps,
  YAxisProps,
  AreaProps,
} from 'recharts';

interface IBlueAreaChart {
  data: any;
  dataKeyY: string;
  dataKeyX: string;
  tooltipFormatter?: (value: string) => string[];
  renderTooltip?: (props: TooltipProps<any[], string>) => React.ReactNode;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
  tooltipProps?: TooltipProps<any[], string>;
  areaProps?: Omit<AreaProps, 'ref' | 'dataKey'>;
}

const BlueAreaChart: React.FC<IBlueAreaChart> = ({
  data,
  dataKeyY,
  dataKeyX,
  tooltipFormatter = (value: string) => [value, ''],
  renderTooltip,
  xAxisProps = {},
  yAxisProps = {},
  tooltipProps = {},
  areaProps = {},
}) => (
  <ResponsiveContainer height={400}>
    <AreaChart data={data} margin={{ bottom: 38, top: 48 }}>
      <defs>
        <linearGradient id="linearGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="rgba(42, 185, 219)" stopOpacity={0.2} />
          <stop offset="85%" stopColor="#rgba(42, 185, 219)" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis
        dy={24}
        stroke="transparent"
        dataKey={dataKeyX}
        tick={{
          fill: '#8A939F',
          fontSize: 10,
          fontWeight: 600,
        }}
        domain={data?.length ? undefined : ['', '']}
        {...xAxisProps}
      />
      <YAxis
        dx={-10}
        width={42}
        stroke="transparent"
        tick={{
          fill: '#8A939F',
          fontSize: 10,
          fontWeight: 600,
        }}
        {...yAxisProps}
      />
      <CartesianGrid
        strokeDasharray="5 5"
        stroke="rgba(138, 147, 159, 0.32)"
        vertical={false}
      />
      <Tooltip
        content={renderTooltip}
        cursor={{
          stroke: '#000000',
          strokeDasharray: '5 5',
          strokeWidth: 2,
        }}
        separator=""
        formatter={tooltipFormatter}
        labelStyle={{
          display: 'none',
        }}
        itemStyle={{
          color: '#FFFFFF',
          fontSize: 12,
          fontWeight: 700,
        }}
        contentStyle={{
          backgroundColor: '#000000',
          borderRadius: 10,
          border: 'none',
          padding: '4px 24px',
        }}
        {...tooltipProps}
      />
      <Area
        type="monotone"
        stroke="#2AB9DB"
        strokeWidth={2}
        dataKey={dataKeyY}
        fill="url(#linearGradient)"
        activeDot={{
          stroke: '#000000',
          strokeWidth: 4,
          r: 6,
          fill: '#2AB9DB',
        }}
        {...areaProps}
      />
    </AreaChart>
  </ResponsiveContainer>
);

export default BlueAreaChart;
