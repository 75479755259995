export const NAME = 'manageTeams';

export enum TABLE_KEYS {
  NAME = 'name',
}

export const TEAM_COLOR_PALETTE = [
  '#2AB9DB',
  '#E252B1',
  '#8A939F',
  '#B784EB',
  '#EEAA25',
  '#3F73A1',
  '#8CB9DE',
  '#0A171D',
  '#789FFE',
];
