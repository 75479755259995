import { connect } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import InviteLicensee from 'features/InviteLicensee/components';
import ExtraLicensesPurchase from 'features/ExtraLicensesPurchase/components';
import LicenseesCount from 'features/LicenseesTable/components/LicenseesCount';
import UsersManagementTabs from 'features/UsersManagementTabs/components';
import { isOrganizationOwner } from 'features/Auth/selectors';
import { BREAKPOINTS } from 'theme/constants';
import { ROUTES } from 'navigation/constants';
import { IReduxState } from 'state/types';
import { useTitles } from 'navigation/hooks';

interface IMapStateToProps {
  isOwner: boolean;
}

type TUsersManagement = IMapStateToProps;

const Container = styled.div((props) => ({
  minWidth: 550,
  borderRadius: 20,
  backgroundColor: props.theme.colors.formBackgroundGray,
  marginBottom: 96,
  paddingBottom: 36,
  boxShadow: props.theme.shadows.light,
}));

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '52px 45px',
  [BREAKPOINTS.XL_TABLET]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '52px 24px',
  },
});

const ShadowContainer = styled.div({
  boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.06)',
});

const LicensesControlContainer = styled.div({
  display: 'flex',
  [BREAKPOINTS.XL_TABLET]: {
    margin: '24px 0',
  },
});

const UsersManagement: React.FC<TUsersManagement> = ({ isOwner }) => {
  useTitles('User management', 'Manage users and licenses');

  const location = useLocation();

  const shouldShowLicenseesControls =
    location.pathname === ROUTES.MAIN && isOwner;

  return (
    <Container>
      <ShadowContainer>
        <Header>
          <LicenseesCount />
          {shouldShowLicenseesControls && (
            <LicensesControlContainer>
              <ExtraLicensesPurchase />
              <InviteLicensee />
            </LicensesControlContainer>
          )}
        </Header>
        <UsersManagementTabs />
      </ShadowContainer>
      <Outlet />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isOwner: !!isOrganizationOwner(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(UsersManagement);
