import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import resetReducer from 'utils/resetReducer';
import { updateSimpleValue } from 'utils/reducerHelpers';
import { ISignIn } from './types';
import {
  SET_EMAIL,
  SET_PASSWORD,
  SET_LOADING,
  SET_PASSWORD_ERROR,
  SET_EMAIL_ERROR,
  SET_ERROR,
  RESET_STATE,
} from './actionTypes';

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const passwordReducer = handleAction<string, string>(
  SET_PASSWORD,
  updateSimpleValue,
  ''
);

const emailErrorReducer = handleAction<string, string>(
  SET_EMAIL_ERROR,
  updateSimpleValue,
  ''
);

const passwordErrorReducer = handleAction<string, string>(
  SET_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const errorReducer = handleAction<string, string>(
  SET_ERROR,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const signInReducers = combineReducers<ISignIn>({
  email: emailReducer,
  password: passwordReducer,
  emailError: emailErrorReducer,
  passwordError: passwordErrorReducer,
  error: errorReducer,
  isLoading: isLoadingReducer,
});

export default resetReducer([RESET_STATE], signInReducers);
