import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import useWindowSize from 'utils/useWindowSize';
import { IReduxState } from 'state/types';
import { isAuthenticated, isAuthLoading } from 'features/Auth/selectors';
import { userHasSubscription } from 'features/Subscription/selectors';
import { ROUTES } from 'navigation/constants';
import { BREAKPOINTS } from 'theme/constants';
import Header from 'features/Header/components/StaticHeader';
import SideNavigationBar from 'features/SideNavigationBar/components';
import { SIDE_BAR_WIDTH } from 'features/SideNavigationBar/constants';
import { TDispatch } from 'types/common';
import { fetchLicensees } from 'features/Licensees/actions';
import { fetchTeams } from 'features/ManageTeams/actions';

import { getSubtitle, getTitle } from 'navigation/selectors';

interface IStyleProps {
  isCompact: boolean;
}

interface IMenuProps {
  isMobileMenuVisible: boolean;
}

interface IMapStateToProps {
  isAuthenticated: boolean;
  userHasSubscription: boolean;
  userDoesNotHaveSubscription: boolean;
  isAuthFinished: boolean;
  title: string;
  subtitle: string;
}

interface IMapDispatchToProps {
  onMount: () => void;
}

type TAppLayout = IMapStateToProps & IMapDispatchToProps;

const Container = styled.div({
  display: 'flex',
});

const MobileMenuOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  zIndex: 100,
});

const WithHeaderContainer = styled.div<IStyleProps>((props) => ({
  marginLeft: props.isCompact ? 0 : SIDE_BAR_WIDTH,
  flexGrow: 1,
}));

const ScreenContentContainer = styled.div({
  padding: '0 80px',
  [BREAKPOINTS.XL_TABLET]: {
    padding: '0 20px',
  },
});

const StyledNavigation = styled(SideNavigationBar)<IStyleProps & IMenuProps>(
  ({ isCompact, isMobileMenuVisible }) => ({
    width: isCompact && !isMobileMenuVisible ? 0 : SIDE_BAR_WIDTH,
    overflow: 'hidden',
    transition: 'width 0.1s',
  })
);

const AppLayout: React.FC<TAppLayout> = ({
  isAuthenticated,
  userDoesNotHaveSubscription,
  isAuthFinished,
  onMount,
  title,
  subtitle,
}) => {
  const location = useLocation();
  const { isCompact } = useWindowSize();
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const closeMobileMenu = () => setMobileMenuVisible(false);
  const openMobileMenu = () => setMobileMenuVisible(true);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    closeMobileMenu();
  }, [location?.pathname]);

  if (isAuthFinished && !isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
  }

  if (isAuthFinished && isAuthenticated && userDoesNotHaveSubscription) {
    return <Navigate to={ROUTES.PURCHASE} state={{ from: location }} replace />;
  }

  return (
    <Container>
      <StyledNavigation
        isMobileMenuVisible={isMobileMenuVisible}
        isCompact={isCompact}
      />
      {isMobileMenuVisible && <MobileMenuOverlay onClick={closeMobileMenu} />}
      <WithHeaderContainer isCompact={isCompact}>
        <Header
          onMenuClick={openMobileMenu}
          title={title}
          subtitle={subtitle}
        />
        <ScreenContentContainer>
          <Outlet />
        </ScreenContentContainer>
      </WithHeaderContainer>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isAuthenticated: isAuthenticated(state),
  isAuthFinished: !isAuthLoading(state),
  userHasSubscription: userHasSubscription(state) === true,
  userDoesNotHaveSubscription: userHasSubscription(state) === false,
  title: getTitle(state),
  subtitle: getSubtitle(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onMount: () => {
    dispatch(fetchLicensees());
    dispatch(fetchTeams());
  },
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(AppLayout);
