import { NAME } from './constants';

export const SET_REFRESH_TOKEN = `${NAME}.SET_REFRESH_TOKEN`;
export const SET_ACCESS_TOKEN = `${NAME}.SET_ACCESS_TOKEN`;
export const SET_AUTH_LOADING = `${NAME}.SET_AUTH_LOADING`;
export const LOGOUT = `${NAME}.LOGOUT`;

export const SET_EMAIL = `${NAME}.SET_EMAIL`;
export const SET_FIRST_NAME = `${NAME}.SET_FIRST_NAME`;
export const SET_LAST_NAME = `${NAME}.SET_LAST_NAME`;
export const SET_ORGANIZATION_NAME = `${NAME}.SET_ORGANIZATION_NAME`;
export const SET_ORGANIZATION_ID = `${NAME}.SET_ORGANIZATION_ID`;
export const SET_IS_ORGANIZATION_OWNER = `${NAME}.SET_IS_ORGANIZATION_OWNER`;
export const SET_LICENSE_LIMIT = `${NAME}.SET_LICENSE_LIMIT`;
export const SET_LICENSE_COUNT = `${NAME}.SET_LICENSE_COUNT`;
