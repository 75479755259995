import { NAME } from './constants';

export const SET_TEAM_NAME = `${NAME}.SET_TEAM_NAME`;
export const SET_TEAM_MEMBERS = `${NAME}.SET_TEAM_MEMBERS`;
export const REMOVE_TEAM_MEMBER = `${NAME}.REMOVE_TEAM_MEMBER`;
export const UPDATE_TEAM_MEMBERS = `${NAME}.UPDATE_TEAM_MEMBERS`;
export const SET_NEW_MEMBER_MODAL_VISIBLE = `${NAME}.SET_NEW_MEMBER_MODAL_VISIBLE`;
export const SET_LOADING = `${NAME}.SET_LOADING`;
export const SET_SELECTED_NEW_MEMBERS = `${NAME}.SET_SELECTED_NEW_MEMBERS`;
export const RESET_TEAM_MEMBERS_DATA = `${NAME}.RESET_TEAM_MEMBERS_DATA`;
