import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import resetReducer from 'utils/resetReducer';
import { updateSimpleValue } from 'utils/reducerHelpers';
import { IRegistration } from './types';
import {
  SET_COMPANY_NAME,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_PASSWORD,
  SET_PASSWORD_ERROR,
  SET_LAST_NAME_ERROR,
  SET_FIRST_NAME_ERROR,
  SET_EMAIL_ERROR,
  SET_COMPANY_NAME_ERROR,
  SET_LOADING,
  RESET_STATE,
} from './actionTypes';

const companyNameReducer = handleAction<string, string>(
  SET_COMPANY_NAME,
  updateSimpleValue,
  ''
);

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const firstNameReducer = handleAction<string, string>(
  SET_FIRST_NAME,
  updateSimpleValue,
  ''
);

const lastNameReducer = handleAction<string, string>(
  SET_LAST_NAME,
  updateSimpleValue,
  ''
);

const passwordReducer = handleAction<string, string>(
  SET_PASSWORD,
  updateSimpleValue,
  ''
);

const passwordErrorReducer = handleAction<string, string>(
  SET_PASSWORD_ERROR,
  updateSimpleValue,
  ''
);

const lastNameErrorReducer = handleAction<string, string>(
  SET_LAST_NAME_ERROR,
  updateSimpleValue,
  ''
);

const firstNameErrorReducer = handleAction<string, string>(
  SET_FIRST_NAME_ERROR,
  updateSimpleValue,
  ''
);

const emailErrorReducer = handleAction<string, string>(
  SET_EMAIL_ERROR,
  updateSimpleValue,
  ''
);

const companyNameErrorReducer = handleAction<string, string>(
  SET_COMPANY_NAME_ERROR,
  updateSimpleValue,
  ''
);

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const registrationReducers = combineReducers<IRegistration>({
  companyName: companyNameReducer,
  firstName: firstNameReducer,
  lastName: lastNameReducer,
  email: emailReducer,
  password: passwordReducer,
  passwordError: passwordErrorReducer,
  lastNameError: lastNameErrorReducer,
  firstNameError: firstNameErrorReducer,
  emailError: emailErrorReducer,
  companyNameError: companyNameErrorReducer,
  isLoading: isLoadingReducer,
});

export default resetReducer([RESET_STATE], registrationReducers);
