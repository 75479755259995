import { connect } from 'react-redux';

import Modal from 'components/Modal';
import { IReduxState } from 'state/types';

import InviteLicenseeInput from './InviteLicenseeInput';
import LimitError from './LimitError';
import { isPopupVisible } from '../selectors';
import { handlePopupClose } from '../actions';

interface IMapStateToProps {
  isModalVisible: boolean;
}

interface IMapDispatchToProps {
  onModalOutsideClick: () => void;
}

type TInviteLicenseePopup = IMapDispatchToProps & IMapStateToProps;

const InviteLicenseePopup: React.FC<TInviteLicenseePopup> = ({
  onModalOutsideClick,
  isModalVisible,
}) => (
  <Modal isVisible={isModalVisible} onModalOutsideClick={onModalOutsideClick}>
    <InviteLicenseeInput />
    <LimitError />
  </Modal>
);

const mapStateToProps = (state: IReduxState) => ({
  isModalVisible: isPopupVisible(state),
});

const mapDispatchToProps = {
  onModalOutsideClick: handlePopupClose,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(InviteLicenseePopup);
