import React from 'react';
import styled from '@emotion/styled';

import { ROUTES } from 'navigation/constants';
import Text from 'components/Text';
import Button from 'components/Button';
import Link from 'components/Link';
import Input from 'components/Input';

import { useSendPasswordResetEmailState } from './hooks';

const StyledForm = styled.form({
  minHeight: 'initial',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 0,
  padding: '48px 70px',
});

const Title = styled(Text)({
  marginBottom: 16,
});

const Subtitle = styled(Text)({
  marginBottom: 27,
});

const Error = styled(Text)(({ theme }) => ({
  color: theme.colors.error,
}));

const StyledInput = styled(Input)({
  width: 364,
  marginTop: 11,
});

const StyledButton = styled(Button)({
  maxWidth: 226,
  margin: '32px 0 16px',
});

const StyledLink = styled(Link)(({ theme }) => ({
  whiteSpace: 'nowrap',
  color: theme.colors.linkBlue,
  fontSize: 15,
  lineHeight: '22.5px',
  textDecoration: 'none',
  transition: 'opacity 300ms',
  '&:hover': {
    opacity: 0.7,
  },
}));

const SendPasswordResetEmail: React.FC = () => {
  const {
    isSuccess,
    resend,
    email,
    setEmail,
    emailError,
    asyncError,
    handleSubmit,
    isLoading,
  } = useSendPasswordResetEmailState();

  return (
    <StyledForm>
      {isSuccess ? (
        <>
          <Title type="h1" weight={600}>
            Please verify your email
          </Title>
          <Subtitle>We have sent an email to {email}</Subtitle>
        </>
      ) : (
        <>
          <Title type="h1" weight={600}>
            {resend ? 'Your link has expired' : 'Reset password'}
          </Title>
          <Subtitle>
            {resend
              ? "Please enter your email address and we'll send you another verification link."
              : 'We will send you a password reset link.'}
          </Subtitle>
          <Error>{asyncError}</Error>
          <StyledInput
            placeholder="Email"
            type="email"
            value={email}
            onChange={setEmail}
            error={emailError}
          />
          <StyledButton
            text={resend ? 'Resend' : 'Send email'}
            onClick={handleSubmit}
            isDisabled={isLoading}
          />
          <StyledLink to={ROUTES.LOGIN}>Back to sign in</StyledLink>
        </>
      )}
    </StyledForm>
  );
};

export default SendPasswordResetEmail;
