import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Button from 'components/Button';
import { TDispatch } from 'types/common';

import TeamIndicator from './TeamIndicator';
import AddNewMemberModal from './AddNewMemberModal';
import { setNewMemberModalVisible } from '../actions';
import { IReduxState } from 'state/types';
import { BREAKPOINTS } from 'theme/constants';

interface IMapDispatchToProps {
  onClick: () => void;
}

type TAddNewMember = IMapDispatchToProps;

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '32px 45px',
  [BREAKPOINTS.XL_TABLET]: {
    padding: '32px 24px',
  },
});

const StyledButton = styled(Button)({
  maxWidth: 200,
});

const AddNewMember: React.FC<TAddNewMember> = ({ onClick }) => {
  const { teamUid } = useParams();

  if (!teamUid) {
    return null;
  }

  return (
    <>
      <Container>
        <TeamIndicator />
        <StyledButton text="+ Add new member" onClick={onClick} />
      </Container>
      <AddNewMemberModal teamUid={teamUid} />
    </>
  );
};

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onClick: () => dispatch(setNewMemberModalVisible(true)),
});

export default connect<unknown, IMapDispatchToProps, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(AddNewMember);
