import { useRef } from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import { ReactComponent as TeamAdmin } from 'assets/team_admin.svg';
import { ReactComponent as RemoveMember } from 'assets/remove_team.svg';
import useOnClickOutside from 'utils/useOnClickOutside';
import hideable from 'utils/hideable';

interface IMemberActionPopup {
  onOutsideClick: () => void;
  onRemoveMember: () => void;
  onTeamAdminClick: () => void;
  isRoleActionVisible: boolean;
  changeRoleText: string;
}

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 40,
  zIndex: 10,
  boxShadow:
    '0px 1px 3px rgba(28, 37, 56, 0.1), 0px 4px 16px rgba(139, 146, 157, 0.08)',
}));

const SettingsButton = styled.button((props) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'none',
  border: 'none',
  outline: 'none',
  margin: 0,
  padding: '12px 20px 12px 20px',
  cursor: 'pointer',
  width: '100%',
  ':hover': {
    backgroundColor: props.theme.colors.formBackgroundGray,
  },
}));

const SettingsButtonText = styled(Text)((props) => ({
  color: props.theme.colors.darkText,
  fontSize: 13,
  lineHeight: '18px',
  fontWeight: 400,
  marginLeft: 8,
}));

const MemberActionPopup: React.FC<IMemberActionPopup> = ({
  onOutsideClick,
  onRemoveMember,
  onTeamAdminClick,
  isRoleActionVisible,
  changeRoleText,
}) => {
  const ref = useRef(null);
  useOnClickOutside(ref, onOutsideClick);

  return (
    <Container ref={ref}>
      {isRoleActionVisible && (
        <SettingsButton onClick={onTeamAdminClick}>
          <TeamAdmin />
          <SettingsButtonText>{changeRoleText}</SettingsButtonText>
        </SettingsButton>
      )}
      <SettingsButton onClick={onRemoveMember}>
        <RemoveMember />
        <SettingsButtonText>Remove</SettingsButtonText>
      </SettingsButton>
    </Container>
  );
};

export default hideable<IMemberActionPopup>(MemberActionPopup);
