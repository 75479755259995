import styled from '@emotion/styled';

import Text from 'components/Text';

interface ITableSimpleTextComponent {
  text: string;
}

const StyledText = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
  fontSize: 13,
}));

const TableSimpleTextComponent: React.FC<ITableSimpleTextComponent> = ({
  text,
}) => <StyledText>{text}</StyledText>;

export default TableSimpleTextComponent;
