import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Text from 'components/Text';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';
import { IReduxState } from 'state/types';
import { CATEGORIES } from 'utils/constants';

import { colors } from '../constants';
import { useCategoryUsage } from '../queries';

interface ICategoryProps {
  category: CATEGORIES;
}

interface IMapStateToProps {
  selectedTeam?: string;
}

type TTopCategoriesList = IMapStateToProps;

const ItemContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const MinuteText = styled(Text)((props) => ({
  color: props.theme.colors.textGray,
}));

const CategoryTextContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const CategoryIndicator = styled.div<ICategoryProps>((props) => ({
  width: 5,
  height: 5,
  borderRadius: 6,
  borderWidth: 3,
  borderColor: colors[props.category],
  borderStyle: 'solid',
  marginRight: 14,
}));

const TopCategoriesList: React.FC<TTopCategoriesList> = ({ selectedTeam }) => {
  const { data } = useCategoryUsage(selectedTeam);

  return (
    <>
      {data?.map((categoryItem) => (
        <ItemContainer key={categoryItem.category}>
          <CategoryTextContainer>
            <CategoryIndicator category={categoryItem.category} />
            <Text>{categoryItem.category}</Text>
          </CategoryTextContainer>
          <MinuteText weight={700}>{categoryItem.play_time} min.</MinuteText>
        </ItemContainer>
      ))}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  selectedTeam: getSelectedTeam(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(TopCategoriesList);
