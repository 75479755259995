import styled from '@emotion/styled';

import { ReactComponent as DarkLogo } from 'assets/logo_dark.svg';
import Text from 'components/Text';
import hideable from 'utils/hideable';

const LogoContainer = styled.div({
  display: 'flex',
});

const LogoText = styled(Text)((props) => ({
  display: 'flex',
  alignItems: 'center',
  maxHeight: 19,
  borderLeft: `1px solid ${props.theme.colors.darkText}`,
  color: props.theme.colors.darkText,
  paddingLeft: 14,
  marginTop: 10,
}));

const StyledDarkLogo = styled(DarkLogo)({
  width: 96,
});

const Logo: React.FC = () => (
  <LogoContainer>
    <StyledDarkLogo />
    <LogoText type="h2">BUSINESS</LogoText>
  </LogoContainer>
);

export default hideable(Logo);
