import { useRef } from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import ContainerPlaceholder from 'components/ContainerPlaceholder';

interface IItem {
  title: string;
  value: number;
}

interface ITopListComponent {
  data?: IItem[];
  title: string;
  itemIcon: JSX.Element;
  isLoading?: boolean;
}

const Container = styled(ChartContainer)({
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
  padding: '32px 26px',
});

const ItemContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 18,
});

const TitleContainer = styled.div({
  display: 'flex',
});

const ValueText = styled(Text)((props) => ({
  color: props.theme.colors.textGray,
  whiteSpace: 'nowrap',
  marginLeft: 8,
}));

const Index = styled(Text)({
  marginRight: 6,
});

const Title = styled(Text)({});

const IconContainer = styled.div({
  width: 18,
  height: 18,
  marginRight: 4,
});

const TopListComponent: React.FC<ITopListComponent> = ({
  data,
  title,
  itemIcon,
  isLoading = false,
  ...rest
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);

  return (
    <Container {...rest} ref={backdropRef}>
      <ChartTitle>{title}</ChartTitle>
      <ContainerPlaceholder
        isLoading={isLoading}
        hasNoData={!data?.length}
        backdropNode={backdropRef.current}
      >
        {data?.map((item, index) => (
          <ItemContainer key={item.title}>
            <TitleContainer>
              <IconContainer>{itemIcon}</IconContainer>
              <Index>{`${index + 1}.`}</Index>
              <Title>{item.title}</Title>
            </TitleContainer>
            <ValueText weight={600}>{`${item.value} min.`}</ValueText>
          </ItemContainer>
        ))}
      </ContainerPlaceholder>
    </Container>
  );
};

export default TopListComponent;
