import styled from '@emotion/styled';

import Text from 'components/Text';
import { ReactComponent as AddUser } from 'assets/add_user.svg';

export interface ITablePlaceholder {
  text: string;
  onClick: () => void;
}

const Container = styled.div({
  padding: '150px 0',
  textAlign: 'center',
});

const ClickableContainer = styled.div({
  display: 'inline-block',
  cursor: 'pointer',
});

const StyledText = styled(Text)({
  marginTop: 20,
});

export const TablePlaceholder: React.FC<ITablePlaceholder> = ({
  onClick,
  text,
}) => (
  <Container>
    <ClickableContainer onClick={onClick}>
      <AddUser />
      <StyledText weight={400} type="h1" align="center">
        {text}
      </StyledText>
    </ClickableContainer>
  </Container>
);

export default TablePlaceholder;
