import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { TooltipProps } from 'recharts';

import { useUserActivity } from '../queries';
import { getDailyActiveUsersPeriod } from '../selectors';
import { setDailyActiveUsersPeriod } from '../actions';

import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import BlueAreaChart from 'components/BlueAreaChart';
import DropdownSelect from 'components/DropdownSelect';
import DateChartTooltip from 'components/DateChartTooltip';
import ContainerPlaceholder from 'components/ContainerPlaceholder';
import { PERIODS } from 'utils/constants';
import { IReduxState } from 'state/types';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';
import { useRef } from 'react';

interface IMapStateToProps {
  period: PERIODS;
  selectedTeam?: string;
}

interface IMapDispatchToProps {
  onChange: (value: PERIODS) => void;
}

type TDailyActiveUsers = IMapStateToProps & IMapDispatchToProps;

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const DropdownSelectContainer = styled.div({
  width: 158,
});

const options = [
  {
    value: PERIODS.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: PERIODS.LAST_30_DAYS,
    label: 'Last 30 days',
  },
  {
    value: PERIODS.LAST_90_DAYS,
    label: 'Last 90 days',
  },
  {
    value: PERIODS.LAST_YEAR,
    label: 'Last year',
  },
];

const renderTooltip = ({ payload }: TooltipProps<any[], string>) => (
  <DateChartTooltip
    date={payload?.[0]?.payload?.date}
    value={payload?.[0]?.payload?.unique_active_users}
  />
);

const DailyActiveUsers: React.FC<TDailyActiveUsers> = ({
  period,
  selectedTeam,
  onChange,
}) => {
  const { data, isLoading } = useUserActivity(period, selectedTeam);

  const backdropRef = useRef<HTMLDivElement>(null);

  return (
    <ChartContainer ref={backdropRef}>
      <Header>
        <ChartTitle>Daily active users</ChartTitle>
        <DropdownSelectContainer>
          <DropdownSelect<PERIODS>
            data={options}
            value={period}
            onChange={onChange}
          />
        </DropdownSelectContainer>
      </Header>
      <ContainerPlaceholder
        isLoading={isLoading}
        hideNoDataPlaceholder
        backdropNode={backdropRef.current}
      >
        <BlueAreaChart
          data={data}
          dataKeyX="date"
          dataKeyY="unique_active_users"
          renderTooltip={renderTooltip}
        />
      </ContainerPlaceholder>
    </ChartContainer>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  period: getDailyActiveUsersPeriod(state),
  selectedTeam: getSelectedTeam(state),
});

const mapDispatchToProps = {
  onChange: setDailyActiveUsersPeriod,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(DailyActiveUsers);
