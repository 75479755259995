import { createSelector } from 'reselect';

import { IReduxState } from 'state/types';
import { TNormalizedLicensee } from 'features/Licensees/types';
import {
  getLicenseesByUidObjectValues,
  getLicenseesUids,
} from 'features/Licensees/selectors';

import { NAME } from './constants';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const getSearchValue = (state: IReduxState) =>
  state?.[NAME]?.searchValue;

export const getLicenseeToDeleteUid = (state: IReduxState) =>
  state?.[NAME]?.deleteLicenseeUid;

export const getUidsBySearchValue = createSelector(
  getLicenseesUids,
  getSearchValue,
  getLicenseesByUidObjectValues,
  (
    allUids: string[],
    searchValue: string,
    licenseesObject: TNormalizedLicensee[]
  ) => {
    const foundValues: string[] = [];

    if (!searchValue) {
      return allUids;
    }

    licenseesObject.forEach((licensee) => {
      if (
        licensee.email
          .toLocaleLowerCase()
          .search(searchValue.toLocaleLowerCase()) !== -1
      ) {
        foundValues.push(licensee.uid);
      }
    });

    return foundValues;
  }
);
