import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { isOrganizationOwner } from 'features/Auth/selectors';
import DropdownSelect, { IData } from 'components/DropdownSelect';
import { IReduxState } from 'state/types';
import hideable, { TWithHideableProp } from 'utils/hideable';
import { ROUTES } from 'navigation/constants';

import { getTeamOptions, getSelectedTeam } from '../selectors';
import { setSelectedTeam } from '../actions';

interface IMapStateToProps {
  options: IData<string>[];
  selectedTeam: string;
}

interface IMapDispatchToProps {
  onChange: (value: string) => void;
}

type TStatsTeamSelect = IMapStateToProps & IMapDispatchToProps;

const Container = styled.div({
  width: 200,
  marginRight: 16,
});

const visibleRoutes = [
  ROUTES.USERS_ACTIVITY,
  ROUTES.CONTENT_USAGE,
  ROUTES.MOOD_TRACKER,
];

const StatsTeamSelect: React.FC<TStatsTeamSelect> = ({
  onChange,
  options,
  selectedTeam,
}) => {
  const location = useLocation();

  if (!visibleRoutes.includes(location.pathname as ROUTES)) {
    return null;
  }

  return (
    <Container>
      <DropdownSelect<string>
        data={options}
        value={selectedTeam}
        onChange={onChange}
        isLight
      />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !!isOrganizationOwner(state),
  options: getTeamOptions(state),
  selectedTeam: getSelectedTeam(state) || 'all',
});

const mapDispatchToProps = {
  onChange: setSelectedTeam,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(StatsTeamSelect));
