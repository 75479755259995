import { useQuery } from 'react-query';

import { AxiosResponse } from 'axios';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { PERIODS } from 'utils/constants';
import { getDatesByPeriod } from 'utils/helpers';
import getDataAggregationByPeriod from 'utils/getDataAggregationByPeriod';
import formatPeriodData from 'utils/formatPeriodData';

import {
  IContentUsageApiResponse,
  ICategoryUsageApiResponse,
  ISubcategoryUsageApiResponse,
  IPlaylistsUsageApiResponse,
  IMediaUsageApiResponse,
  TPlaylistType,
} from './types';

export const useContentUsage = (period: PERIODS, selectedTeam?: string) =>
  useQuery(['contentUsage', period, selectedTeam], () => {
    const periodDates = getDatesByPeriod(period);
    return callCoreApi({
      method: METHODS.GET,
      url: API_URLS.CONTENT_USAGE_BY_PERIOD,
      queryParams: [
        { key: 'date_from', value: periodDates?.from || '' },
        { key: 'date_to', value: periodDates?.to || '' },
        { key: 'aggregation', value: getDataAggregationByPeriod(period) },
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<IContentUsageApiResponse[]>) =>
      formatPeriodData(
        period,
        response.data.map((dailyUsage) => ({
          ...dailyUsage,
          play_time: Math.ceil(dailyUsage.play_time_seconds / 60),
        })),
        'date'
      )
    );
  });

export const useCategoryUsage = (selectedTeam?: string) =>
  useQuery(['categoryUsage', selectedTeam], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.CATEGORY_USAGE,
      queryParams: [
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<ICategoryUsageApiResponse[]>) => {
      return response.data.map((categoryUsage) => ({
        ...categoryUsage,
        play_time: Math.ceil(categoryUsage.play_time_seconds / 60),
      }));
    })
  );

export const useSubcategoryUsage = (
  category: string | null,
  selectedTeam?: string
) =>
  useQuery(['subcategoryUsage', category, selectedTeam], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.SUBCATEGORY_USAGE,
      queryParams: [
        ...(category ? [{ key: 'category', value: category }] : []),
        ...(!category ? [{ key: 'limit', value: '10' }] : []),
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<ISubcategoryUsageApiResponse[]>) => {
      return response.data.map((categoryUsage) => ({
        ...categoryUsage,
        play_time: Math.ceil(categoryUsage.play_time_seconds / 60),
      }));
    })
  );

export const usePlaylistsUsage = (type: TPlaylistType, selectedTeam?: string) =>
  useQuery(['playlistsUsage', type, selectedTeam], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.PLAYLISTS_USAGE,
      queryParams: [
        {
          key: 'type',
          value: type,
        },
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<IPlaylistsUsageApiResponse[]>) => {
      return response.data.map((playlistsUsage) => ({
        value: Math.ceil(playlistsUsage.play_time_seconds / 60),
        title: playlistsUsage.playlist,
      }));
    })
  );

export const useMediaUsage = (selectedTeam?: string) =>
  useQuery(['mediaUsage', selectedTeam], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.MEDIA_USAGE,
      queryParams: [
        {
          key: 'limit',
          value: '5',
        },
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<IMediaUsageApiResponse[]>) => {
      return response.data.map((mediaUsage) => ({
        value: Math.ceil(mediaUsage.play_time_seconds / 60),
        title: mediaUsage.media,
      }));
    })
  );
