import { useEffect } from 'react';
import { connect } from 'react-redux';

import { refreshToken } from '../actions';

interface IMapDispatchToProps {
  onMount: () => void;
}

type TAuthProvider = IMapDispatchToProps;

const AuthProvider: React.FC<TAuthProvider> = ({ onMount, children }) => {
  useEffect(() => {
    onMount();
  }, [onMount]);

  return <>{children}</>;
};

const mapDispatchToProps = {
  onMount: refreshToken,
};

export default connect(null, mapDispatchToProps)(AuthProvider);
