import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { ILicenseesTable } from './types';
import {
  SET_DELETE_LICENSEE_UID,
  SET_LOADING,
  SET_SEARCH_VALUE,
} from './actionTypes';

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const deleteLicenseeUidReducer = handleAction<string, string>(
  SET_DELETE_LICENSEE_UID,
  updateSimpleValue,
  ''
);

const searchValueReducer = handleAction<string, string>(
  SET_SEARCH_VALUE,
  updateSimpleValue,
  ''
);

export default combineReducers<ILicenseesTable>({
  isLoading: isLoadingReducer,
  deleteLicenseeUid: deleteLicenseeUidReducer,
  searchValue: searchValueReducer,
});
