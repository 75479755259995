export enum ROUTES {
  MAIN = '/',
  REGISTRATION = '/register',
  LOGIN = '/login',
  PURCHASE = '/purchase',
  CONTENT_USAGE = '/content-usage',
  USERS_ACTIVITY = '/users-activity',
  MOOD_TRACKER = '/mood-tracker',
  NOT_FOUND = '/404',
  RESET_PASSWORD = '/reset-password',
  SEND_PASSWORD_RESET_EMAIL = '/password-reset-email',
  USERS = '/users',
  TEAMS = '/teams',
}

export const NAME = 'navigation';
