import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { CUSTOM_ERROR_CODES } from 'api';
import { ERROR_TEXTS, QUERY_PARAMS } from 'utils/constants';
import { isPasswordValid } from 'utils/helpers';
import { ROUTES } from 'navigation/constants';

import { useResetPassword, useVerifyResetPasswordToken } from './queries';
import { IResetPasswordResponse } from './types';

export const usePasswordResetState = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState('');
  const [asyncError, setAsyncError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { mutate: verifyResetPasswordToken, isSuccess: isVerified } =
    useVerifyResetPasswordToken();

  const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword();

  const onError = useCallback(
    (error: AxiosError<IResetPasswordResponse>) => {
      if (
        error?.response?.data?.custom_status_code ===
        CUSTOM_ERROR_CODES.STATUS_RESET_PASSWORD_TOKEN_IS_EXPIRED
      ) {
        navigate(ROUTES.SEND_PASSWORD_RESET_EMAIL, {
          state: { resend: true },
          replace: true,
        });
      } else {
        navigate(ROUTES.LOGIN, { replace: true });
      }
    },
    [navigate]
  );

  useEffect(() => {
    const token = searchParams.get(QUERY_PARAMS.TOKEN);

    if (!token) return navigate(ROUTES.LOGIN);

    verifyResetPasswordToken(token, {
      onError,
    });
  }, [searchParams, navigate, verifyResetPasswordToken, onError]);

  const handleSubmit = useCallback(() => {
    setAsyncError('');
    setPasswordError('');

    if (!password) return setPasswordError(ERROR_TEXTS.PASSWORD_REQUIRED);

    if (!isPasswordValid(password))
      return setPasswordError(ERROR_TEXTS.PASSWORD_VALIDATION_ERROR);

    resetPassword(
      { password, token: searchParams.get(QUERY_PARAMS.TOKEN) as string },
      {
        onError,
      }
    );
  }, [
    password,
    resetPassword,
    setPasswordError,
    setAsyncError,
    onError,
    searchParams,
  ]);

  return {
    isVerified,
    isSuccess,
    password,
    setPassword,
    passwordError,
    asyncError,
    isLoading,
    handleSubmit,
  };
};
