import React, { useRef } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import useOnClickOutside from 'utils/useOnClickOutside';
import hideable from 'utils/hideable';
import { logout } from 'features/Auth/actions';
import { IReduxState } from 'state/types';

interface IOwnProps {
  closeHandler: () => void;
}

interface IMapDispatchToProps {
  onLogout: () => void;
}

type TMenu = IMapDispatchToProps & IOwnProps;

const Container = styled.div({
  position: 'absolute',
  top: 50,
  right: -26,
  boxShadow: '-15px 19px 35px -2px rgba(0,0,0,0.07)',
});

const ArrowBox = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  borderRadius: 2,
  padding: '8px 16px',
  position: 'relative',
  minWidth: 150,
  cursor: 'default',
  '&:after': {
    content: '" "',
    position: 'absolute',
    right: '15px',
    top: '-15px',
    borderTop: 'none',
    borderRight: '15px solid transparent',
    borderLeft: '15px solid transparent',
    borderBottom: `15px solid ${props.theme.colors.white}`,
  },
}));

const MenuText = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  margin: '4px 0',
  cursor: 'pointer',
});

const ActionButton = styled.button({
  background: 'none',
  border: 'none',
  outline: 'none',
  margin: 0,
  padding: 0,
});

const Menu: React.FC<TMenu> = ({ closeHandler, onLogout }) => {
  const ref = useRef(null);

  useOnClickOutside(ref, closeHandler);

  return (
    <Container ref={ref}>
      <ArrowBox>
        <ActionButton onClick={onLogout}>
          <MenuText>Logout</MenuText>
        </ActionButton>
      </ArrowBox>
    </Container>
  );
};

const mapDispatchToProps = {
  onLogout: logout,
};

export default connect<unknown, IMapDispatchToProps, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(hideable<TMenu>(Menu));
