import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { SET_SUBTITLE, SET_TITLE } from './actionTypes';
import { INavigation } from './types';

const titleReducer = handleAction<string, string>(
  SET_TITLE,
  updateSimpleValue,
  ''
);

const subtitleReducer = handleAction<string, string>(
  SET_SUBTITLE,
  updateSimpleValue,
  ''
);

export default combineReducers<INavigation>({
  title: titleReducer,
  subtitle: subtitleReducer,
});
