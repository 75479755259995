import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { IAuth } from './types';
import {
  SET_REFRESH_TOKEN,
  SET_ACCESS_TOKEN,
  SET_AUTH_LOADING,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_ORGANIZATION_NAME,
  SET_LICENSE_LIMIT,
  SET_LICENSE_COUNT,
  SET_ORGANIZATION_ID,
  SET_IS_ORGANIZATION_OWNER,
} from './actionTypes';

const firstNameReducer = handleAction<string, string>(
  SET_FIRST_NAME,
  updateSimpleValue,
  ''
);

const lastNameReducer = handleAction<string, string>(
  SET_LAST_NAME,
  updateSimpleValue,
  ''
);

const organizationNameReducer = handleAction<string, string>(
  SET_ORGANIZATION_NAME,
  updateSimpleValue,
  ''
);

const organizationIdReducer = handleAction<string, string>(
  SET_ORGANIZATION_ID,
  updateSimpleValue,
  ''
);

const licenseLimitReducer = handleAction<number, number>(
  SET_LICENSE_LIMIT,
  updateSimpleValue,
  0
);

const licenseCountReducer = handleAction<number, number>(
  SET_LICENSE_COUNT,
  updateSimpleValue,
  0
);

const emailReducer = handleAction<string, string>(
  SET_EMAIL,
  updateSimpleValue,
  ''
);

const accessTokenReducer = handleAction<string, string>(
  SET_ACCESS_TOKEN,
  updateSimpleValue,
  ''
);

const refreshTokenReducer = handleAction<string, string>(
  SET_REFRESH_TOKEN,
  updateSimpleValue,
  ''
);

const isAuthLoadingReducer = handleAction<boolean, boolean>(
  SET_AUTH_LOADING,
  updateSimpleValue,
  true
);

const isOrganizationOwnerReducer = handleAction<boolean, boolean>(
  SET_IS_ORGANIZATION_OWNER,
  updateSimpleValue,
  false
);

export default combineReducers<IAuth>({
  accessToken: accessTokenReducer,
  refreshToken: refreshTokenReducer,
  isAuthLoading: isAuthLoadingReducer,
  firstName: firstNameReducer,
  lastName: lastNameReducer,
  email: emailReducer,
  organizationName: organizationNameReducer,
  licenseLimit: licenseLimitReducer,
  licenseCount: licenseCountReducer,
  organizationId: organizationIdReducer,
  isOrganizationOwner: isOrganizationOwnerReducer,
});
