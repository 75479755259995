import { createAction } from 'redux-actions';
import { TThunk } from 'types/common';

import { ISubscriptionData, PAYMENT_PROVIDERS } from './types';
import {
  SET_SUBSCRIPTION_NAME,
  SET_SUBSCRIPTION_VALID_FROM_DATE,
  SET_SUBSCRIPTION_VALID_TO_DATE,
  SET_PAYMENT_PROVIDER,
  SET_AUTO_RENEW_STATUS,
  SET_SUBSCRIPTION_ID,
  SET_USER_HAS_SUBSCRIPTION,
} from './actionTypes';

export const setSubscriptionName = createAction<string>(SET_SUBSCRIPTION_NAME);
export const setSubscriptionId = createAction<string>(SET_SUBSCRIPTION_ID);
export const setAutoRenewStatus = createAction<boolean>(SET_AUTO_RENEW_STATUS);
export const setUserHasSubscription = createAction<boolean>(
  SET_USER_HAS_SUBSCRIPTION
);
export const setPaymentProvider =
  createAction<PAYMENT_PROVIDERS>(SET_PAYMENT_PROVIDER);
export const setSubscriptionValidFromDate = createAction<string>(
  SET_SUBSCRIPTION_VALID_FROM_DATE
);
export const setSubscriptionValidToDate = createAction<string>(
  SET_SUBSCRIPTION_VALID_TO_DATE
);

export const setSubscriptionData =
  (subscription: ISubscriptionData | null): TThunk =>
  (dispatch) => {
    if (!subscription) {
      dispatch(setUserHasSubscription(false));

      return;
    }

    dispatch(setUserHasSubscription(true));
    dispatch(setSubscriptionId(subscription.id));
    dispatch(setAutoRenewStatus(subscription.auto_renew));
    dispatch(setPaymentProvider(subscription.provider_product.provider.name));
    dispatch(setSubscriptionName(subscription.provider_product.product.name));
    dispatch(setSubscriptionValidToDate(subscription.period_end));
    dispatch(setSubscriptionValidFromDate(subscription.period_start));
  };
