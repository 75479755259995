import { connect } from 'react-redux';

import { isLoading, getNewTeamName } from '../selectors';
import {
  setNewTeamName,
  setCreateTeamModalVisible,
  handleCreateTeam,
} from '../actions';

import ActionForm from 'components/ActionForm';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { TWithHideableProp } from 'utils/hideable';

interface IMapStateToProps {
  value: string;
  error: string;
  isButtonLoading: boolean;
  title: string;
  inputPlaceholder: string;
  cancelButtonText: string;
  submitButtonText: string;
}

interface IMapDispatchToProps {
  onChange: (value: string) => void;
  onCancelClick: () => void;
  onSubmitClick: () => void;
}

const mapStateToProps = (state: IReduxState) => ({
  isVisible: true,
  value: getNewTeamName(state),
  error: '',
  isButtonLoading: isLoading(state),
  title: 'Create new team',
  inputPlaceholder: 'Team name',
  cancelButtonText: 'Cancel',
  submitButtonText: 'Create',
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onChange: (value: string) => dispatch(setNewTeamName(value)),
  onCancelClick: () => dispatch(setCreateTeamModalVisible(false)),
  onSubmitClick: () => dispatch(handleCreateTeam()),
});

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(ActionForm);
