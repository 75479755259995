import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';

import SettingsButton from './SettingsButton';
import Logo from './Logo';

import { BREAKPOINTS } from 'theme/constants';
import { ROUTES } from 'navigation/constants';

interface IStyledProps {
  isScrollMenu: boolean;
}

const Container = styled.div<IStyledProps>((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '32px 80px',
  backgroundColor: `rgba(231, 232, 236, 0)`,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 10,
  backdropFilter: 'blur(0)',
  transition: 'all 0.3s ease-out',
  [BREAKPOINTS.XL_TABLET]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  ...(props.isScrollMenu && {
    backgroundColor: `rgba(231, 232, 236, 1)`,
    backdropFilter: 'blur(20px)',
    paddingTop: 4,
    paddingBottom: 4,
    transition: 'all 0.5s ease-out',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
  }),
}));

const Header: React.FC = ({ ...rest }) => {
  const [isScrollMenu, setScrollMenu] = useState<boolean>(false);
  const location = useLocation();

  const shouldShowLogo = [
    ROUTES.PURCHASE,
    ROUTES.LOGIN,
    ROUTES.REGISTRATION,
    ROUTES.SEND_PASSWORD_RESET_EMAIL,
    ROUTES.RESET_PASSWORD,
  ].some((path) => path === location.pathname);

  const scrollListener = () => {
    const scrollNumber = window.scrollY;

    if (scrollNumber > 21) {
      setScrollMenu(true);
    } else {
      setScrollMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <Container isScrollMenu={isScrollMenu} {...rest}>
      <Logo isVisible={shouldShowLogo} />
      <SettingsButton isScrollMenu={isScrollMenu} />
    </Container>
  );
};

export default Header;
