import { createAction } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';

import { getPriceApiRequest } from 'features/Purchase/actions';
import { getSubscriptionId } from 'features/Subscription/selectors';
import { getLicenseLimit, getOrganizationId } from 'features/Auth/selectors';
import { setLicenseLimit } from 'features/Auth/actions';
import { TThunk } from 'types/common';
import { ERROR_TEXTS } from 'utils/constants';
import { API_URLS, callCoreApi, METHODS } from 'api';
import {
  ICompletePurchaseApiError,
  IGetPriceApiResponse,
} from 'features/Purchase/types';

import { IPurchaseExtraLicensesApiProps } from './types';
import { getQuantityOfLicenses, isPurchaseLoading } from './selectors';
import {
  SET_PURCHASE_POPUP_VISIBLE,
  SET_QUANTITY_OF_LICENSES,
  SET_QUANTITY_OF_LICENSES_ERROR,
  SET_GET_PRICE_LOADING,
  SET_PURCHASE_LOADING,
  SET_PRICE,
} from './actionTypes';

export const setPrice = createAction<string>(SET_PRICE);
export const setGetPriceLoading = createAction<boolean>(SET_GET_PRICE_LOADING);
export const setPurchaseLoading = createAction<boolean>(SET_PURCHASE_LOADING);
export const setQuantityOfLicenses = createAction<string>(
  SET_QUANTITY_OF_LICENSES
);
export const setQuantityOfLicensesError = createAction<string>(
  SET_QUANTITY_OF_LICENSES_ERROR
);
export const setPurchasePopupVisible = createAction<boolean>(
  SET_PURCHASE_POPUP_VISIBLE
);

export const handleQuantityChange =
  (value: string): TThunk =>
  (dispatch) => {
    dispatch(setQuantityOfLicenses(value));
    dispatch(setGetPriceLoading(true));
    dispatch(setQuantityOfLicensesError(''));
  };

export const getPrice = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const quantity = getQuantityOfLicenses(state);
  const limit = getLicenseLimit(state);
  const organizationId = getOrganizationId(state);

  if (!quantity) {
    dispatch(setPrice(''));
    dispatch(setGetPriceLoading(false));

    return;
  }

  const quantityNum = parseInt(quantity, 10);

  getPriceApiRequest(quantityNum, organizationId, limit)
    .then((response: AxiosResponse<IGetPriceApiResponse>) => {
      dispatch(setPrice(response?.data?.amount_due_now?.toString()));
    })
    .finally(() => {
      dispatch(setGetPriceLoading(false));
    });
};

export const handlePurchase = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const quantity = getQuantityOfLicenses(state);
  const purchaseLoading = isPurchaseLoading(state);
  const subscriptionId = getSubscriptionId(state);
  const organizationId = getOrganizationId(state);
  const currentLimit = getLicenseLimit(state);

  dispatch(setQuantityOfLicensesError(''));

  if (purchaseLoading) {
    return;
  }

  dispatch(setPurchaseLoading(true));

  if (!quantity) {
    dispatch(setQuantityOfLicensesError(ERROR_TEXTS.BLANK_FIELD));

    return;
  }

  const quantityNum = parseInt(quantity, 10);
  const productId = process.env.REACT_APP_STRIPE_BUSINESS_PLAN_ID as string;

  callCoreApi<IPurchaseExtraLicensesApiProps>({
    method: METHODS.PATCH,
    url: API_URLS.COMPLETE_PURCHASE,
    path: `${subscriptionId}/`,
    authorized: true,
    data: {
      quantity: currentLimit + quantityNum,
      provider_product_id: productId,
      cancel_at_period_end: false,
      organization_id: organizationId,
    },
  })
    .then(() => {
      dispatch(setPurchasePopupVisible(false));
      dispatch(setQuantityOfLicenses(''));
      dispatch(setLicenseLimit(currentLimit + quantityNum));
    })
    .catch((error: AxiosError<ICompletePurchaseApiError>) => {
      const errorMessage =
        error.response?.data?.detail || ERROR_TEXTS.SOMETHING_WENT_WRONG;

      dispatch(setQuantityOfLicensesError(errorMessage));
    })
    .finally(() => {
      dispatch(setPurchaseLoading(false));
    });
};
