import { createAction } from 'redux-actions';

import { PERIODS } from 'utils/constants';

import { TCategorySelect } from './types';
import {
  SET_CONTENT_USAGE_PERIOD,
  SET_CATEGORY_FOR_SUBCATEGORIES,
} from './actionTypes';

export const setContentUsagePeriod = createAction<PERIODS>(
  SET_CONTENT_USAGE_PERIOD
);

export const setCategoryForSubcategories = createAction<TCategorySelect>(
  SET_CATEGORY_FOR_SUBCATEGORIES
);
