import styled from '@emotion/styled';

import Input from 'components/Input';
import { BREAKPOINTS } from 'theme/constants';

export interface ISearchInput {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}

export interface IMapDispatchToProps {
  onChange: (value: string) => void;
}

const Container = styled.div({
  maxWidth: 364,
  padding: '20px 45px 0 45px',
  [BREAKPOINTS.XL_TABLET]: {
    padding: '20px 24px 0 24px',
  },
});

const SearchInput: React.FC<ISearchInput> = ({
  placeholder,
  value,
  onChange,
}) => (
  <Container>
    <Input
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      isSearch
    />
  </Container>
);

export default SearchInput;
