import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

import Text from 'components/Text';
import { TStatus } from 'features/Licensees/types';

interface ITableStatusComponent {
  status: TStatus;
}

interface IIGetStyleByStatus {
  status: TStatus;
  theme: Theme;
}

const getStyleByStatus = ({ status = 'inactive', theme }: IIGetStyleByStatus) =>
  ({
    inactive: {
      backgroundColor: theme.colors.inactiveGray,
      color: theme.colors.white,
    },
    active: {
      backgroundColor: theme.colors.activeBlue,
      color: theme.colors.white,
    },
  }[status]);

const StyledText = styled(Text)<{ status: TStatus }>((props) => ({
  fontSize: 12,
  padding: '5px 8px',
  display: 'inline-block',
  borderRadius: 4,
  ...getStyleByStatus({ status: props.status, theme: props.theme }),
}));

const TableStatusComponent: React.FC<ITableStatusComponent> = ({ status }) => (
  <StyledText weight={500} status={status}>
    {`${status?.charAt(0)?.toUpperCase()}${status?.slice(1)}`}
  </StyledText>
);

export default TableStatusComponent;
