import moment from 'moment';
import { connect } from 'react-redux';

import TableSimpleTextComponent from 'components/TableSimpleTextComponent';
import { getLicenseeCreatedAtByUid } from 'features/Licensees/selectors';
import { IReduxState } from 'state/types';

interface IOwnProps {
  uid: string;
}

interface IMapStateToProps {
  text: string;
}

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => {
  const createdAt = getLicenseeCreatedAtByUid(state, uid);

  return {
    text: moment(createdAt).format('ll'),
  };
};

export default connect<IMapStateToProps, unknown, IOwnProps, IReduxState>(
  mapStateToProps
)(TableSimpleTextComponent);
