import { combineReducers } from 'redux';
import { handleActions, handleAction } from 'redux-actions';

import {
  addByUid,
  addUids,
  deleteByUid,
  deleteUid,
  updateSimpleValue,
} from 'utils/reducerHelpers';

import { ILicensees, TNormalizedLicensee } from './types';
import { SET_LICENSEES, SET_LOADING, REMOVE_LICENSEE } from './actionTypes';
import { IByUid } from 'types/common';

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const licenseesUidsReducer = handleActions<string[], any>(
  {
    [SET_LICENSEES]: addUids,
    [REMOVE_LICENSEE]: deleteUid,
  },
  []
);

const licenseesByUidReducer = handleActions<IByUid<TNormalizedLicensee>, any>(
  {
    [SET_LICENSEES]: addByUid,
    [REMOVE_LICENSEE]: deleteByUid,
  },
  {}
);

export default combineReducers<ILicensees>({
  isLoading: isLoadingReducer,
  licenseesByUid: licenseesByUidReducer,
  licenseesUids: licenseesUidsReducer,
});
