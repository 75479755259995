import { createAction } from 'redux-actions';

import { removeLicensee } from 'features/Licensees/actions';
import { setLicenseCount } from 'features/Auth/actions';
import { getLicenseCount } from 'features/Auth/selectors';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { TThunk } from 'types/common';

import { getLicenseeToDeleteUid, isLoading } from './selectors';
import {
  SET_DELETE_LICENSEE_UID,
  SET_LOADING,
  SET_SEARCH_VALUE,
} from './actionTypes';

export const setLoading = createAction<boolean>(SET_LOADING);
export const setSearchValue = createAction<string>(SET_SEARCH_VALUE);
export const setDeleteLicenseeUid = createAction<string>(
  SET_DELETE_LICENSEE_UID
);

export const deleteLicensee = (): TThunk => (dispatch, getState) => {
  const state = getState();

  if (isLoading(state)) {
    return;
  }

  dispatch(setLoading(true));

  const uid = getLicenseeToDeleteUid(state);

  callCoreApi({
    method: METHODS.DELETE,
    url: API_URLS.LICENSEES,
    path: `${uid}/`,
    authorized: true,
  })
    .then(() => {
      dispatch(removeLicensee(uid));
      dispatch(setDeleteLicenseeUid(''));
      dispatch(setLicenseCount(getLicenseCount(state) - 1));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
