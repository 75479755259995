import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TDispatch } from 'types/common';
import { setSubtitle, setTitle } from './actions';

export const useTitles = (title: string, subtitle: string) => {
  const dispatch = useDispatch<TDispatch>();

  useEffect(() => {
    dispatch(setTitle(title));
    dispatch(setSubtitle(subtitle));
    return () => {
      dispatch(setTitle(''));
      dispatch(setSubtitle(''));
    };
  }, []);
};
