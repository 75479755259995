import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getEmail = (state: IReduxState) => state?.[NAME]?.email || '';

export const getPassword = (state: IReduxState) =>
  state?.[NAME]?.password || '';

export const getEmailError = (state: IReduxState) =>
  state?.[NAME]?.emailError || '';

export const getPasswordError = (state: IReduxState) =>
  state?.[NAME]?.passwordError || '';

export const getError = (state: IReduxState) => state?.[NAME]?.error || '';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;
