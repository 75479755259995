import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts';
import { connect } from 'react-redux';
import { useTheme } from '@emotion/react';

import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import ContainerPlaceholder from 'components/ContainerPlaceholder';
import { IReduxState } from 'state/types';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';

import TopCategoriesList from './TopCategoriesList';
import { colors } from '../constants';
import { useCategoryUsage } from '../queries';
import { useRef } from 'react';
import styled from '@emotion/styled';

interface IMapStateToProps {
  selectedTeam?: string;
}

const Container = styled(ChartContainer)({
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
});

const Title = styled(ChartTitle)({ minHeight: 47 });

type TCategoriesUsage = IMapStateToProps;

const CategoriesUsage: React.FC<TCategoriesUsage> = ({
  selectedTeam,
  ...rest
}) => {
  const { data, isLoading } = useCategoryUsage(selectedTeam);
  const theme = useTheme();

  const backdropRef = useRef<HTMLDivElement>(null);
  return (
    <Container {...rest} ref={backdropRef}>
      <Title>Categories</Title>
      <ContainerPlaceholder
        isLoading={isLoading}
        hasNoData={!data?.length}
        backdropNode={backdropRef.current}
      >
        <ResponsiveContainer height={300}>
          <PieChart>
            <Pie
              data={data}
              dataKey="play_time"
              nameKey="category"
              fill="#8884d8"
              innerRadius={4}
            >
              {data?.map((value, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[value.category]}
                  stroke={colors[value.category]}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: string, key: string) => [
                ` (${value}min.)`,
                key,
              ]}
              separator=""
              labelStyle={{
                display: 'none',
              }}
              itemStyle={{
                color: theme.colors.titleText,
                fontSize: 12,
                fontWeight: 700,
              }}
              contentStyle={{
                backgroundColor: '#FFFFFF',
                borderRadius: 10,
                border: 'none',
                padding: '4px 12px',
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </ContainerPlaceholder>
      <TopCategoriesList />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  selectedTeam: getSelectedTeam(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(CategoriesUsage);
