export enum MOODS {
  VERY_BAD,
  BAD,
  NORMAL,
  GOOD,
  VERY_GOOD,
}

export enum TEAM_QUESTIONS {
  MENTAL = 'mental',
  HEALTH = 'physical',
}

export enum CIRCUMSTANCES {
  COVID_19 = 'covid-19',
  DAILY_STRESSORS = 'daily stressors',
  WORK_OR_EDUCATION = 'work / education',
  HEALTH = 'health',
  MAJOR_LIFE_CHANGE = 'major life change',
  LIFESTYLE = 'lifestyle',
  NOTHING_IN_PARTICULAR = 'nothing in particular',
  PERSONAL_TIME = 'personal time',
  PHYSICAL_OR_BODY = 'physical / body',
  RELATIONSHIP = 'relationship',
  SELF_ESTEEM = 'self-esteem',
  SENSE_OF_PURPOSE = 'sense of purpose',
  SLEEP_PROBLEMS = 'sleep problems',
  THINKING_PATTERNS = 'thinking patterns',
  RESPONSIBILITIES = 'responsibilities',
}

export const circumstancesConstants: Record<
  CIRCUMSTANCES | string,
  { label: string; color: string }
> = {
  [CIRCUMSTANCES.COVID_19]: {
    label: 'Covid 19',
    color: '#789FFE',
  },
  [CIRCUMSTANCES.DAILY_STRESSORS]: {
    label: 'Daily stressors',
    color: '#BBB5FF',
  },
  [CIRCUMSTANCES.WORK_OR_EDUCATION]: {
    label: 'Work or school',
    color: '#FFB55C',
  },
  [CIRCUMSTANCES.HEALTH]: {
    label: 'Health',
    color: '#5283AD',
  },
  [CIRCUMSTANCES.MAJOR_LIFE_CHANGE]: {
    label: 'Major life changes',
    color: '#1FA6C6',
  },
  [CIRCUMSTANCES.LIFESTYLE]: {
    label: 'Lifestyle',
    color: '#1079D0',
  },
  [CIRCUMSTANCES.NOTHING_IN_PARTICULAR]: {
    label: 'Nothing in particular',
    color: '#BF97FF',
  },
  [CIRCUMSTANCES.PERSONAL_TIME]: {
    label: 'Personal time',
    color: '#87C7FF',
  },
  [CIRCUMSTANCES.PHYSICAL_OR_BODY]: {
    label: 'Physical/body',
    color: '#177BD8',
  },
  [CIRCUMSTANCES.RELATIONSHIP]: {
    label: 'Relationship',
    color: '#FED850',
  },
  [CIRCUMSTANCES.SELF_ESTEEM]: {
    label: 'Self-esteem',
    color: '#71A2CB',
  },
  [CIRCUMSTANCES.SENSE_OF_PURPOSE]: {
    label: 'Sense of purpose',
    color: '#8BE2F7',
  },
  [CIRCUMSTANCES.SLEEP_PROBLEMS]: {
    label: 'Sleep',
    color: '#3177B2',
  },
  [CIRCUMSTANCES.THINKING_PATTERNS]: {
    label: 'Thinking patterns',
    color: '#2864F0',
  },
  [CIRCUMSTANCES.RESPONSIBILITIES]: {
    label: 'Responsibilities',
    color: '#7599EE',
  },
  OTHER: {
    label: '',
    color: '#FF9E2A',
  },
};
