import { connect } from 'react-redux';

import {
  PriceCalculatorInput,
  IInputStateProps,
  IInputDispatchProps,
} from 'components/PriceCalculator';
import { IReduxState } from 'state/types';

import { getPrice, handleQuantityChange } from '../actions';
import {
  getQuantityOfLicenses,
  getQuantityOfLicensesError,
} from '../selectors';

const mapStateToProps = (state: IReduxState) => {
  const value = getQuantityOfLicenses(state);

  return {
    value,
    title: 'Buy extra licenses',
    inputError: getQuantityOfLicensesError(state),
    placeholder: 'Number of people covered',
  };
};

const mapDispatchToProps = {
  onChange: handleQuantityChange,
  onDebounce: getPrice,
};

export default connect<
  IInputStateProps,
  IInputDispatchProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(PriceCalculatorInput);
