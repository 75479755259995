import styled from '@emotion/styled';

import Text from 'components/Text';
import ExternalLink from 'components/ExternalLink';
import hideable, { TWithHideableProp } from 'utils/hideable';

interface IPriceCalculatorError {
  titleText: string;
  mainText: string;
  link: string;
  linkText: string;
}

export type TPriceCalculatorError = TWithHideableProp<IPriceCalculatorError>;

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.actionBackgroundGray,
  padding: '16px 20px',
  marginTop: 24,
  marginBottom: 12,
}));

const MainText = styled(Text)({
  marginTop: 12,
});

const PriceCalculatorError: React.FC<TPriceCalculatorError> = ({
  titleText,
  mainText,
  link,
  linkText,
}) => (
  <Container>
    <Text weight={600}>{titleText}</Text>
    <MainText weight={400}>
      {mainText}{' '}
      <ExternalLink to={link} newTab>
        {linkText}
      </ExternalLink>
    </MainText>
  </Container>
);

export default hideable(PriceCalculatorError);
