import moment from 'moment';

import { STORAGE_KEYS, PERIODS } from './constants';

export const storageSetItem = (key: STORAGE_KEYS, value: string) => {
  localStorage.setItem(key, value);
};

export const storageRemoveItem = (key: STORAGE_KEYS) => {
  localStorage.removeItem(key);
};

export const storageGetItem = (key: STORAGE_KEYS) => {
  return localStorage.getItem(key);
};

export const numberBetween = (number: number, from: number, to: number) =>
  number >= from && number <= to;

export const isEmailValid = (email: string) => /\S+@\S+\.\S+/.test(email);

export const isPasswordValid = (password: string) =>
  password.length > 8 &&
  password.toLocaleLowerCase() !== password &&
  /\d/.test(password);

export const getDatesByPeriod = (period: PERIODS) => {
  const daysByPeriod = {
    [PERIODS.LAST_7_DAYS]: 7,
    [PERIODS.LAST_30_DAYS]: 30,
    [PERIODS.LAST_90_DAYS]: 90,
    [PERIODS.LAST_YEAR]: 365,
  };

  return {
    from: moment().subtract(daysByPeriod[period], 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  };
};
