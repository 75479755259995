import { NAME } from './constants';

export const SET_QUANTITY_OF_LICENSES = `${NAME}.SET_QUANTITY_OF_LICENSES`;
export const SET_TOTAL_PRICE = `${NAME}.SET_TOTAL_PRICE`;
export const SET_GET_PRICE_LOADING = `${NAME}.SET_GET_PRICE_LOADING`;
export const SET_FLOOR_LIMIT_ERROR = `${NAME}.SET_FLOOR_LIMIT_ERROR`;
export const SET_CEILING_LIMIT_ERROR = `${NAME}.SET_CEILING_LIMIT_ERROR`;
export const SET_LICENSE_QUANTITY_ERROR = `${NAME}.SET_LICENSE_QUANTITY_ERROR`;
export const SET_PAYMENT_ERROR = `${NAME}.SET_PAYMENT_ERROR`;
export const SET_PURCHASE_LOADING = `${NAME}.SET_PURCHASE_LOADING`;

export const RESET_STATE = `${NAME}.RESET_STATE`;
