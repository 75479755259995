import { Global, css, useTheme } from '@emotion/react';
import { Slide, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import 'assets/fonts/index.css';
import { Navigation } from 'navigation';
import AuthProvider from 'features/Auth/components';
import LoadingScreen from 'components/LoadingScreen';
import { refreshToken } from 'features/Auth/actions';
import { isAuthLoading } from 'features/Auth/selectors';
import { IReduxState } from 'state/types';

interface IMapStateToProps {
  shouldShowLoadingScreen: boolean;
}

type TApp = IMapStateToProps;

const App: React.FC<TApp> = ({ shouldShowLoadingScreen }) => {
  const theme = useTheme();

  return (
    <AuthProvider>
      <Global
        styles={css`
          #root,
          html,
          body {
            margin: 0px;
            padding: 0px;
            font-family: Poppins;
            min-height: 100vh;
            background-color: ${theme.colors.appBackgroundGray};
            .Toastify__toast {
              font-family: Poppins;
              text-align: center;
            }

            .Toastify__toast--success {
              background-color: ${theme.colors.green};
            }

            .Toastify__toast--error {
              background-color: ${theme.colors.error};
            }
          }
        `}
      />
      <Navigation isVisible={!shouldShowLoadingScreen} />
      <LoadingScreen isVisible={shouldShowLoadingScreen} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        transition={Slide}
      />
    </AuthProvider>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  shouldShowLoadingScreen: !!isAuthLoading(state),
});

const mapDispatchToProps = {
  onMount: refreshToken,
};

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps,
  mapDispatchToProps
)(App);
