import styled from '@emotion/styled';

import Text from 'components/Text';
import Loader from 'components/Loader';
import hideable, { TWithHideableProp } from 'utils/hideable';

interface IPriceCalculatorResult {
  quantityOfLicenses: string;
  quantityTitle: string;
  priceTitle: string;
  totalPrice: string;
  isCalculatorLoading: boolean;
}

export type TPriceCalculatorResult = TWithHideableProp<IPriceCalculatorResult>;

const Container = styled.div((props) => ({
  backgroundColor: props.theme.colors.actionBackgroundGray,
  padding: '16px 20px',
  marginTop: 24,
  marginBottom: 12,
}));

const InfoContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const TotalContainer = styled(InfoContainer)({
  borderTop: '1px solid rgba(139, 146, 157, 0.2)',
  paddingTop: 16,
  marginTop: 12,
});

const PriceCalculatorResult: React.FC<TPriceCalculatorResult> = ({
  quantityOfLicenses,
  totalPrice,
  quantityTitle,
  priceTitle,
  isCalculatorLoading,
}) => (
  <Container>
    <InfoContainer>
      <Text weight={400}>{quantityTitle}</Text>
      <Text weight={400}>{quantityOfLicenses}</Text>
    </InfoContainer>
    <TotalContainer>
      <Text weight={400}>{priceTitle}</Text>
      {!isCalculatorLoading && <Text weight={600}>{totalPrice}</Text>}
      {!!isCalculatorLoading && <Loader />}
    </TotalContainer>
  </Container>
);

export default hideable(PriceCalculatorResult);
