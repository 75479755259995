import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ReactComponent as Bin } from 'assets/bin.svg';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { isOrganizationOwner } from 'features/Auth/selectors';

import { setDeleteLicenseeUid } from '../actions';
import { TWithHideableProp } from 'utils/hideable';

interface IOwnProps {
  uid: string;
}

interface IMapDispatchToProps {
  onClick: () => void;
}

type TLicenseeAction = IMapDispatchToProps;

const DeleteButton = styled.button((props) => ({
  border: 'none',
  outline: 'none',
  backgroundColor: props.theme.colors.formBackgroundGray,
  borderRadius: 50,
  height: 40,
  width: 40,
  ':hover': {
    cursor: 'pointer',
  },
}));

const LicenseeAction: React.FC<TLicenseeAction> = ({ onClick }) => (
  <DeleteButton onClick={onClick}>
    <Bin />
  </DeleteButton>
);

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !!isOrganizationOwner(state),
});

const mapDispatchToProps = (dispatch: TDispatch, { uid }: IOwnProps) => ({
  onClick: () => dispatch(setDeleteLicenseeUid(uid)),
});

export default connect<
  TWithHideableProp<unknown>,
  IMapDispatchToProps,
  IOwnProps,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(LicenseeAction);
