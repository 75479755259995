import { connect } from 'react-redux';

import TablePlaceholder from 'components/EmptyTablePlaceholder';
import { handleInviteLicenseeButtonClick } from 'features/InviteLicensee/actions';
import { IReduxState } from 'state/types';
import { getLicenseesUids } from 'features/Licensees/selectors';
import hideable, { TWithHideableProp } from 'utils/hideable';

export interface IMapStateToProps {
  text: string;
}

export interface IMapDispatchToProps {
  onClick: () => void;
}

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !getLicenseesUids(state).length,
  text: 'Invite your first licensee',
});

const mapDispatchToProps = {
  onClick: handleInviteLicenseeButtonClick,
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  unknown,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(TablePlaceholder));
