import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import { BREAKPOINTS } from 'theme/constants';

import PriceCalculator from './PriceCalculator';
import { setPurchasePopupVisible, handlePurchase } from '../actions';
import {
  isPurchasePopupVisible,
  isPurchaseLoading,
  getQuantityOfLicenses,
} from '../selectors';

interface IMapStateToProps {
  isModalVisible: boolean;
  isSubmitButtonDisabled: boolean;
  isSubmitButtonLoading: boolean;
}

interface IMapDispatchToProps {
  onCancel: () => void;
  onSubmit: () => void;
}

type TPurchasePopup = IMapStateToProps & IMapDispatchToProps;

const StyledModal = styled(Modal)({
  width: '100%',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  marginTop: 24,
  justifyContent: 'flex-end',
  [BREAKPOINTS.XL_MOBILE]: {
    flexDirection: 'column-reverse',
  },
});

const CancelButton = styled(Button)({
  marginRight: 8,
  maxWidth: 140,
  [BREAKPOINTS.XL_MOBILE]: {
    marginRight: 0,
    marginTop: 8,
  },
});

const SubmitButton = styled(Button)({
  maxWidth: 140,
});

const PurchasePopup: React.FC<TPurchasePopup> = ({
  isModalVisible,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  onCancel,
  onSubmit,
}) => (
  <StyledModal isVisible={isModalVisible} onModalOutsideClick={onCancel}>
    <PriceCalculator />
    <ButtonsContainer>
      <CancelButton onClick={onCancel} text="Cancel" buttonType="light" />
      <SubmitButton
        onClick={onSubmit}
        text="Submit"
        isDisabled={isSubmitButtonDisabled}
        isLoading={isSubmitButtonLoading}
      />
    </ButtonsContainer>
  </StyledModal>
);

const mapStateToProps = (state: IReduxState) => ({
  isModalVisible: isPurchasePopupVisible(state),
  isSubmitButtonDisabled: !getQuantityOfLicenses(state),
  isSubmitButtonLoading: isPurchaseLoading(state),
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onCancel: () => dispatch(setPurchasePopupVisible(false)),
  onSubmit: () => dispatch(handlePurchase()),
});

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(PurchasePopup);
