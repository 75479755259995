import { connect } from 'react-redux';

import TableSimpleTextComponent from 'components/TableSimpleTextComponent';
import { getTeamMemberRoleByUid } from 'features/TeamMembers/selectors';
import { IReduxState } from 'state/types';
import { ROLE } from 'features/Licensees/types';

import { ROLE_TEXT } from '../constants';

interface IOwnProps {
  uid: string;
}

interface IMapStateToProps {
  text: string;
}

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => {
  const role = getTeamMemberRoleByUid(state, uid) || ROLE.MEMBER;

  return {
    text: ROLE_TEXT[role],
  };
};

export default connect<IMapStateToProps, unknown, IOwnProps, IReduxState>(
  mapStateToProps
)(TableSimpleTextComponent);
