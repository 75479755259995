import PriceCalculatorInputContainer from './PriceCalculatorInputContainer';
import PriceCalculatorResultContainer from './PriceCalculatorResultContainer';

const PriceCalculator: React.FC = () => (
  <>
    <PriceCalculatorInputContainer />
    <PriceCalculatorResultContainer />
  </>
);

export default PriceCalculator;
