import { connect } from 'react-redux';

import { IReduxState } from 'state/types';
import {
  PriceCalculatorResult,
  TPriceCalculatorResult,
} from 'components/PriceCalculator';

import {
  getQuantityOfLicenses,
  getTotalPrice,
  isGetPriceLoading,
  isCeilingLimitError,
  isFloorLimitError,
} from '../selectors';

const mapStateToProps = (state: IReduxState) => {
  const totalPrice = getTotalPrice(state);
  const quantityOfLicenses = getQuantityOfLicenses(state);

  return {
    totalPrice: `$ ${totalPrice}`,
    quantityOfLicenses,
    isVisible:
      !!quantityOfLicenses &&
      !isCeilingLimitError(state) &&
      !isFloorLimitError(state),
    isCalculatorLoading: !!isGetPriceLoading(state),
    quantityTitle: 'Number of people covered',
    priceTitle: 'Total',
  };
};

export default connect<TPriceCalculatorResult, unknown, unknown, IReduxState>(
  mapStateToProps
)(PriceCalculatorResult);
