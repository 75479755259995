import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { isDev } from 'utils/constants';
import reducers from './reducers';

export const history = createBrowserHistory();

const middleware = applyMiddleware(thunk);

const middlewareWrapper = isDev
  ? composeWithDevTools(middleware)
  : compose(middleware);

export default createStore(reducers, middlewareWrapper);
