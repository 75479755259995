import { connect } from 'react-redux';
import styled from '@emotion/styled';

import FormContainer from 'components/FormContainer';
import Text from 'components/Text';
import Input from 'components/Input';
import Link from 'components/Link';
import Button from 'components/Button';
import { IReduxState } from 'state/types';
import { ROUTES } from 'navigation/constants';

import { setEmail, setPassword, handleSignIn } from '../actions';
import {
  getEmail,
  getPassword,
  isLoading,
  getEmailError,
  getPasswordError,
  getError,
} from '../selectors';

interface IMapStateToProps {
  email: string;
  password: string;
  emailError: string;
  passwordError: string;
  error: string;
  isButtonDisabled: boolean;
}

interface IMapDispatchToProps {
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onClick: () => void;
}

type TSignIn = IMapStateToProps & IMapDispatchToProps;

const StyledForm = styled(FormContainer)({
  minHeight: 'initial',
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledText = styled(Text)({
  alignSelf: 'flex-start',
  marginBottom: 16,
});

const Error = styled(Text)((props) => ({
  alignSelf: 'flex-start',
  color: props.theme.colors.error,
}));

const StyledButton = styled(Button)({
  maxWidth: 124,
  margin: '24px 0 18px',
});

const SignInText = styled(Text)(({ theme }) => ({
  color: theme.colors.subtitleDark,
  fontSize: 15,
  lineHeight: '22.5px',
  marginTop: 18,
}));

const StyledLink = styled(Link)((props) => ({
  whiteSpace: 'nowrap',
  color: props.theme.colors.linkBlue,
  fontSize: 15,
  lineHeight: '22.5px',
  transition: 'opacity 300ms',
  '&:hover': {
    opacity: 0.7,
  },
}));

const SignIn: React.FC<TSignIn> = ({
  email,
  password,
  isButtonDisabled,
  emailError,
  passwordError,
  error,
  onEmailChange,
  onPasswordChange,
  onClick,
}) => (
  <StyledForm>
    <StyledText type="h1" weight={600}>
      Log into your account
    </StyledText>
    <Error>{error}</Error>
    <Input
      placeholder="Email"
      type="email"
      value={email}
      onChange={onEmailChange}
      error={emailError}
    />
    <Input
      placeholder="Password"
      type="password"
      value={password}
      onChange={onPasswordChange}
      error={passwordError}
    />
    <StyledButton
      text="Sign in"
      onClick={onClick}
      isDisabled={isButtonDisabled}
    />
    <StyledLink to={ROUTES.SEND_PASSWORD_RESET_EMAIL}>
      Forgot password?
    </StyledLink>
    <SignInText>
      No account? <StyledLink to={ROUTES.REGISTRATION}>Sign up</StyledLink>
    </SignInText>
  </StyledForm>
);

const mapStateToProps = (state: IReduxState) => ({
  email: getEmail(state),
  password: getPassword(state),
  emailError: getEmailError(state),
  passwordError: getPasswordError(state),
  isButtonDisabled: !!isLoading(state),
  error: getError(state),
});

const mapDispatchToProps = {
  onPasswordChange: setPassword,
  onEmailChange: setEmail,
  onClick: handleSignIn,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
