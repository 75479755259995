import RegistrationForm from 'features/Registration/components';
import FullScreenWrapper from 'components/FullScreenWrapper';

const Registration: React.FC = () => (
  <FullScreenWrapper>
    <RegistrationForm />
  </FullScreenWrapper>
);

export default Registration;
