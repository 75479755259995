import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import SearchMembers from 'features/TeamMembersTable/components/SearchMembers';
import TableComponent from 'features/TeamMembersTable/components';
import { IReduxState } from 'state/types';

import AddNewMember from './AddNewMember';
import TablePlaceholder from './TablePlaceholder';
import { fetchTeamMembers, resetTeamMembersData } from '../actions';

interface IMapDispatchToProps {
  onMount: (value: string) => void;
  onUnmount: () => void;
}

type TTeamMembers = IMapDispatchToProps;

const TeamMembers: React.FC<TTeamMembers> = ({ onMount, onUnmount }) => {
  const { teamUid } = useParams();

  useEffect(() => {
    if (!teamUid) {
      return;
    }

    onMount(teamUid);

    return onUnmount;
  }, [teamUid, onMount, onUnmount]);

  return (
    <>
      <AddNewMember />
      <SearchMembers />
      <TablePlaceholder />
      <TableComponent />
    </>
  );
};

const mapDispatchToProps = {
  onMount: fetchTeamMembers,
  onUnmount: resetTeamMembersData,
};

export default connect<unknown, IMapDispatchToProps, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(TeamMembers);
