import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import Input from 'components/Input';
import Text from 'components/Text';

export interface IInputStateProps {
  value: string;
  inputError: string;
  title: string;
  placeholder?: string;
}

export interface IInputDispatchProps {
  onChange: (value: string) => void;
  onDebounce: () => void;
}

type TPriceCalculatorInput = IInputStateProps & IInputDispatchProps;

const PriceCalculatorInput: React.FC<TPriceCalculatorInput> = ({
  value,
  inputError,
  title,
  placeholder = 'Number of people covered (from 5 to 100)',
  onChange,
  onDebounce,
}) => {
  const debouncedValueChangeHandler = useDebouncedCallback(() => {
    onDebounce();
  }, 600);

  useEffect(() => {
    debouncedValueChangeHandler();
  }, [value]);

  const handleInputChange = (value: string) => {
    const numValue = parseInt(value, 10);

    if (numValue < 1) {
      onChange('');

      return;
    }

    if (numValue > 1000) {
      return;
    }

    onChange(value);
  };

  return (
    <>
      <Text type="h1">{title}</Text>
      <Input
        placeholder={placeholder}
        value={value}
        type="number"
        onChange={handleInputChange}
        error={inputError}
        min={0}
      />
    </>
  );
};

export default PriceCalculatorInput;
