import { values } from 'ramda';

import { IReduxState } from 'state/types';

import { NAME } from './constants';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const getSearchValue = (state: IReduxState) =>
  state?.[NAME]?.searchValue;

export const isCreateTeamModalVisible = (state: IReduxState) =>
  state?.[NAME]?.isCreateTeamModalVisible;

export const getNewTeamName = (state: IReduxState) =>
  state?.[NAME]?.newTeamName;

export const getTeamsUids = (state: IReduxState) => state?.[NAME]?.teams?.uids;

export const getTeamsObjectValues = (state: IReduxState) =>
  values(state?.[NAME]?.teams?.byUid || {});

export const getTeamNameByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.teams?.byUid?.[uid]?.name;
