import { useEffect, useRef } from 'react';
import lottie, {
  AnimationConfigWithData,
} from 'lottie-web/build/player/lottie_light';

interface IAnimateJson {
  data: AnimationConfigWithData['animationData'];
  className?: string;
}

const AnimateJson: React.FC<IAnimateJson> = ({ data, ...rest }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current) {
      lottie.loadAnimation({
        container: ref.current,
        animationData: data,
      });
    }
  }, []);

  return <div ref={ref} {...rest} />;
};

export default AnimateJson;
