import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { ITeamMembersTable } from './types';
import {
  SET_LOADING,
  SET_SEARCH_VALUE,
  SET_ACTION_POPUP_VISIBLE_UID,
} from './actionTypes';

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const searchValueReducer = handleAction<string, string>(
  SET_SEARCH_VALUE,
  updateSimpleValue,
  ''
);

const actionPopupVisibleUidReducer = handleAction<string, string>(
  SET_ACTION_POPUP_VISIBLE_UID,
  updateSimpleValue,
  ''
);

export default combineReducers<ITeamMembersTable>({
  isLoading: isLoadingReducer,
  searchValue: searchValueReducer,
  actionPopupVisibleUid: actionPopupVisibleUidReducer,
});
