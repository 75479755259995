import styled from '@emotion/styled';

import { ICircumstance } from '../../types';

interface ICircumstancesLabels {
  circumstances: ICircumstance[];
  onLabelHover: (index: number) => void;
}

const Container = styled.div({
  display: 'grid',
  gridTemplateRows: 'repeat(8, min-content)',
  gridTemplateColumns: 'repeat(auto-fill, minmax(max-content, 188px))',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifySelf: 'start',
  gridColumnGap: 52,
});

const Label = styled.span(({ color }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 13,
  lineHeight: '22px',
  columnGap: 8,
  padding: '8px 0',
  cursor: 'default',
  ':before': {
    content: '""',
    display: 'block',
    boxSizing: 'border-box',
    width: 10,
    height: 10,
    borderRadius: '50%',
    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: color,
  },
}));

const CircumstancesLabels: React.FC<ICircumstancesLabels> = ({
  circumstances,
  onLabelHover,
}) => {
  return (
    <Container>
      {circumstances.map(({ id, color, label, percentage }, index) => (
        <Label
          key={id}
          color={color}
          onMouseEnter={() => onLabelHover(index)}
        >{`${label} (${Math.round(percentage)}%)`}</Label>
      ))}
    </Container>
  );
};

export default CircumstancesLabels;
