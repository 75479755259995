import moment from 'moment';

import { PERIODS } from './constants';

export default <Data, Key extends keyof Data>(
  period: PERIODS,
  data: Data[],
  dateKey: Key
) => {
  const format =
    period === PERIODS.LAST_7_DAYS
      ? 'ddd DD'
      : period === PERIODS.LAST_YEAR
      ? 'MMM'
      : 'MMM DD';

  return data.map((monthlyRecord) => ({
    ...monthlyRecord,
    [dateKey]: moment(monthlyRecord[dateKey], 'YYYY-MM-DD').format(format),
  }));
};
