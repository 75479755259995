import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    darkGray: '#3F5561',
    lightGray: '#8B929D',
    extraLightGray: '#E2E4E7',
    appBackgroundGray: '#EBECF4',
    actionBackgroundGray: '#F6F6F6',
    formBackgroundGray: '#F3F4FC',
    formBackgroundDark: '#2D2D2D',
    formBackgroundLight: 'rgba(138, 147, 159, 0.29)',
    tableBackgroundGray: '#F0F1F3',
    textGray: '#8A939F',
    electric: '#0196AA',
    electricDark: '#017E8E',
    green: '#0BAE88',
    activeBlue: '#2AD0DB',
    inactiveGray: 'rgba(138, 147, 159, 0.79)',
    darkGreen: '#016674',
    white: '#FFFFFF',
    error: '#E83D32',
    errorBackground: 'rgba(232, 61, 50, 0.3)',
    blue: '#182F40',
    lightBlue: '#396CD8',
    darkBlue: '#2A519E',
    facebookBlue: '#1877F2',
    linkBlue: '#2AB9DB',
    facebookBlueHover: '#004CBE',
    mainBlack: '#0F1E27',
    black: '#000000',
    darkBlueBackground: '#0F2652',
    darkText: '#061218',
    lightText: '#5F6770',
    titleText: '#2D2D2D',
    orange: '#E5830E',
    shinyBlue: '#3058BE',
    blurryBlue: '#173164',
    menuBlue: '#2D54B4',
    subtitle: 'rgba(0, 0, 0, 0.6)',
    subtitleDark: 'rgba(45, 45, 45, 0.75)',
  },
  navigationColors: {
    inactiveLinkText: '#7F7F83',
    activeLinkText: '#2D2D2D',
    activeLinkBackground: '#F3F4FC',
  },
  buttons: {
    primary: {
      text: '#FFFFFF',
      background: '#53575E',
      hoverText: '#FFFFFF',
      hoverBackground: '#2D2D2D',
      border: 'none',
    },
    secondary: {
      text: '#0F1E27',
      background: 'transparent',
      hoverText: '#0F1E27',
      hoverBackground: '#FFFFFF',
      border: '1px solid #FFFFFF',
    },
    light: {
      text: '#8B929D',
      background: 'transparent',
      border: '1px solid #8A939F',
      hoverText: '#2D2D2D',
      hoverBackground: 'transparent',
      hoverBorder: '1px solid #2D2D2D',
    },
  },
  shadows: {
    light: '0px 1px 40px rgba(24, 24, 24, 0.1)',
  },
};

export default theme;
