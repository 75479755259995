import { connect } from 'react-redux';

import TableStatusComponent from 'components/TableStatusComponent';
import { getLicenseeStatusByUid } from 'features/Licensees/selectors';
import { TStatus } from 'features/Licensees/types';
import { IReduxState } from 'state/types';

interface IOwnProps {
  uid: string;
}

interface IMapStateToProps {
  status: TStatus;
}

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => ({
  status: getLicenseeStatusByUid(state, uid),
});

export default connect<IMapStateToProps, unknown, IOwnProps, IReduxState>(
  mapStateToProps
)(TableStatusComponent);
