import { createSelector } from 'reselect';

import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getFirstName = (state: IReduxState) =>
  state?.[NAME]?.firstName || '';

export const getLastName = (state: IReduxState) => state?.[NAME]?.lastName;

export const getEmail = (state: IReduxState) => state?.[NAME]?.email;

export const getAccessToken = (state: IReduxState) =>
  state?.[NAME]?.accessToken;

export const getRefreshToken = (state: IReduxState) =>
  state?.[NAME]?.refreshToken;

export const isAuthLoading = (state: IReduxState) =>
  state?.[NAME]?.isAuthLoading;

export const isOrganizationOwner = (state: IReduxState) =>
  state?.[NAME]?.isOrganizationOwner;

export const getLicenseCount = (state: IReduxState) =>
  state?.[NAME]?.licenseCount || 0;

export const getLicenseLimit = (state: IReduxState) =>
  state?.[NAME]?.licenseLimit || 0;

export const getOrganizationId = (state: IReduxState) =>
  state?.[NAME]?.organizationId || '';

export const isAuthenticated = createSelector(
  getAccessToken,
  getRefreshToken,
  (accessToken?: string, refreshToken?: string) =>
    !!accessToken && !!refreshToken
);
