import { Theme } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import ChartContainer from './ChartContainer';

type TTopMetricCard = {
  icon: JSX.Element;
  graphImage?: JSX.Element;
  cardType?: TCardType;
} & (
  | { title?: never; CustomTitleComponent: JSX.Element }
  | { title: string; CustomTitleComponent?: never }
) &
  (
    | {
        result?: never;
        CustomResultComponent: JSX.Element;
      }
    | { result: number | string; CustomResultComponent?: never }
  );

interface ITypeProps {
  cardType: TCardType;
}

type TCardType = 'primary' | 'secondary';

const getCardStyleByType = (cardType: TCardType, theme: Theme) =>
  ({
    primary: {
      backgroundColor: theme.colors.formBackgroundGray,
    },
    secondary: {
      backgroundColor: theme.colors.formBackgroundDark,
    },
  }[cardType]);

const getTitleStyleByType = (cardType: TCardType, theme: Theme) =>
  ({
    primary: {
      color: theme.colors.black,
      opacity: 0.7,
    },
    secondary: {
      color: theme.colors.white,
      opacity: 0.6,
    },
  }[cardType]);

const getResultStyleByType = (cardType: TCardType, theme: Theme) =>
  ({
    primary: {
      color: theme.colors.black,
    },
    secondary: {
      color: theme.colors.white,
    },
  }[cardType]);

const Container = styled(ChartContainer)<ITypeProps>((props) => ({
  padding: '20px 32px',
  alignItems: 'center',
  flexDirection: 'row',
  ...getCardStyleByType(props.cardType, props.theme),
}));

const TextContainer = styled.div({
  flexGrow: 1,
  marginLeft: 12,
});

const TitleText = styled(Text)<ITypeProps>((props) => ({
  ...getTitleStyleByType(props.cardType, props.theme),
}));

const Result = styled(Text)<ITypeProps>((props) => ({
  ...getResultStyleByType(props.cardType, props.theme),
}));

const TopMetricCard: React.FC<TTopMetricCard> = ({
  cardType = 'primary',
  icon,
  title,
  result,
  graphImage,
  CustomTitleComponent,
  CustomResultComponent,
  ...rest
}) => (
  <Container cardType={cardType} {...rest}>
    {icon}
    <TextContainer>
      {CustomTitleComponent ?? (
        <TitleText cardType={cardType}>{title}</TitleText>
      )}
      {CustomResultComponent ?? (
        <Result cardType={cardType} weight={700}>
          {result}
        </Result>
      )}
    </TextContainer>
    {graphImage}
  </Container>
);

export default TopMetricCard;
