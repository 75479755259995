import { AxiosResponse } from 'axios';
import { createAction } from 'redux-actions';
import JwtDecode from 'jwt-decode';
import { QueryCache } from 'react-query';

import { TThunk } from 'types/common';
import { STORAGE_KEYS } from 'utils/constants';
import { storageGetItem, storageSetItem } from 'utils/helpers';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { setSubscriptionData } from 'features/Subscription/actions';
import { ROLE } from 'features/Licensees/types';

import { IRefresh, IUserInfo, TSignInResponse } from './types';
import {
  LOGOUT,
  SET_ACCESS_TOKEN,
  SET_AUTH_LOADING,
  SET_EMAIL,
  SET_FIRST_NAME,
  SET_IS_ORGANIZATION_OWNER,
  SET_LAST_NAME,
  SET_LICENSE_COUNT,
  SET_LICENSE_LIMIT,
  SET_ORGANIZATION_ID,
  SET_ORGANIZATION_NAME,
  SET_REFRESH_TOKEN,
} from './actionTypes';

export const setFirstName = createAction<string>(SET_FIRST_NAME);
export const setLastName = createAction<string>(SET_LAST_NAME);
export const setLicenseCount = createAction<number>(SET_LICENSE_COUNT);
export const setLicenseLimit = createAction<number>(SET_LICENSE_LIMIT);
export const setOrganizationName = createAction<string>(SET_ORGANIZATION_NAME);
export const setOrganizationId = createAction<string>(SET_ORGANIZATION_ID);
export const setEmail = createAction<string>(SET_EMAIL);
export const setAccessToken = createAction<string>(SET_ACCESS_TOKEN);
export const setRefreshToken = createAction<string>(SET_REFRESH_TOKEN);
export const setAuthLoading = createAction<boolean>(SET_AUTH_LOADING);
export const setIsOrganizationOwner = createAction<boolean>(
  SET_IS_ORGANIZATION_OWNER
);
export const resetStateOnLogout = createAction(LOGOUT);

export const logout = (): TThunk => (dispatch) => {
  const queryCache = new QueryCache();

  queryCache.clear();

  dispatch(resetStateOnLogout());

  storageSetItem(STORAGE_KEYS.REFRESH_TOKEN, '');

  dispatch(setAuthLoading(false));
};

export const setAuthData =
  (data: TSignInResponse): TThunk =>
  (dispatch) => {
    const userInfo = JwtDecode<IUserInfo>(data.access);

    if (!userInfo) {
      dispatch(setAuthLoading(false));

      return;
    }

    dispatch(setEmail(userInfo.user.email));
    dispatch(setFirstName(userInfo.user.first_name));
    dispatch(setLastName(userInfo.user.last_name));
    dispatch(setSubscriptionData(userInfo.user.subscription));
    dispatch(setOrganizationId(userInfo.user.license?.organization.id || ''));
    dispatch(
      setOrganizationName(userInfo.user.license?.organization.name || '')
    );
    dispatch(
      setLicenseCount(userInfo.user.license?.organization.license_count || 0)
    );
    dispatch(
      setLicenseLimit(userInfo.user.license?.organization.license_limit || 0)
    );
    dispatch(
      setIsOrganizationOwner(userInfo.user.license?.role === ROLE.OWNER)
    );

    dispatch(setAccessToken(data.access));
    dispatch(setRefreshToken(data.refresh));
    dispatch(setAuthLoading(false));

    storageSetItem(STORAGE_KEYS.REFRESH_TOKEN, data.refresh);
  };

export const refreshToken = (): TThunk => (dispatch) => {
  const refreshToken = storageGetItem(STORAGE_KEYS.REFRESH_TOKEN);

  if (!refreshToken) {
    dispatch(setAuthLoading(false));

    return;
  }

  refreshTokenRequest(refreshToken)
    .then((response: AxiosResponse<TSignInResponse>) => {
      const userInfo = JwtDecode<IUserInfo>(response.data.access);
      const role = userInfo.user.license?.role;

      if (role === ROLE.OWNER || role === ROLE.TEAM_LEAD) {
        dispatch(setAuthData(response.data));
      } else {
        dispatch(logout());
      }
    })
    .catch(() => {
      dispatch(logout());
    });
};

export const refreshTokenRequest = (refresh_token: string) =>
  callCoreApi<IRefresh>({
    url: API_URLS.REFRESH_TOKEN,
    data: { refresh: refresh_token },
    method: METHODS.POST,
  });
