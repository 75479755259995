import { useRef } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { useUserActivity } from '../queries';

import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import BlackLineChart from 'components/BlackLineChart';
import ContainerPlaceholder from 'components/ContainerPlaceholder';
import { PERIODS } from 'utils/constants';
import { IReduxState } from 'state/types';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';

interface IMapStateToProps {
  selectedTeam?: string;
}

type TMonthlyActiveUsers = IMapStateToProps;

const StyledContainer = styled(ChartContainer)({
  marginTop: 24,
});

const MonthlyActiveUsers: React.FC<TMonthlyActiveUsers> = ({
  selectedTeam,
}) => {
  const { data, isLoading } = useUserActivity(PERIODS.LAST_YEAR, selectedTeam);

  const backdropRef = useRef<HTMLDivElement>(null);

  return (
    <StyledContainer ref={backdropRef}>
      <ChartTitle>Monthly active users</ChartTitle>
      <ContainerPlaceholder
        isLoading={isLoading}
        hideNoDataPlaceholder
        backdropNode={backdropRef.current}
      >
        <BlackLineChart
          data={data}
          dataKeyX="date"
          dataKeyY="unique_active_users"
        />
      </ContainerPlaceholder>
    </StyledContainer>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  selectedTeam: getSelectedTeam(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(MonthlyActiveUsers);
