import styled from '@emotion/styled';
import { ROUTES } from 'navigation/constants';
import { Link, useLocation } from 'react-router-dom';

interface IStyleProps {
  isActive: boolean;
}

interface IMenuLink {
  to: ROUTES;
  text: string;
  activeImage: JSX.Element;
  inactiveImage: JSX.Element;
}

const StyledLink = styled(Link)({
  textDecoration: 'none',
});

const LinkContent = styled.div<IStyleProps>((props) => ({
  borderRadius: 12,
  marginTop: 16,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 20px',
  fontSize: 14,
  minWidth: 172,
  backgroundColor: props.isActive
    ? props.theme.navigationColors.activeLinkBackground
    : 'transparent',
  color: props.isActive
    ? props.theme.navigationColors.activeLinkText
    : props.theme.navigationColors.inactiveLinkText,
  textDecoration: 'none',
}));

const ImageContainer = styled.div({
  width: 32,
  display: 'flex',
  alignItems: 'center',
});

const MenuLink: React.FC<IMenuLink> = ({
  to,
  text,
  activeImage,
  inactiveImage,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const image = isActive ? activeImage : inactiveImage;

  return (
    <StyledLink to={to}>
      <LinkContent isActive={isActive}>
        <ImageContainer>{image}</ImageContainer>
        {text}
      </LinkContent>
    </StyledLink>
  );
};

export default MenuLink;
