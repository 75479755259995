import { connect } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

import { IReduxState } from 'state/types';
import { isAuthenticated, isAuthLoading } from 'features/Auth/selectors';
import { userHasSubscription } from 'features/Subscription/selectors';
import { ROUTES } from 'navigation/constants';
import Header from 'features/Header/components/FixedHeader';

interface ILocationState {
  from?: {
    pathname?: string;
  };
}

interface IMapStateToProps {
  isAuthenticated: boolean;
  userHasSubscription: boolean;
  userDoesNotHaveSubscription: boolean;
  isAuthFinished: boolean;
}
type TAuthLayout = IMapStateToProps;

const AuthLayout: React.FC<TAuthLayout> = ({
  isAuthenticated,
  userHasSubscription,
  userDoesNotHaveSubscription,
  isAuthFinished,
}) => {
  const location = useLocation();
  const locationState = location.state as ILocationState;
  const mainRedirectDestination = locationState?.from?.pathname || ROUTES.MAIN;

  if (isAuthFinished && isAuthenticated && userDoesNotHaveSubscription) {
    return <Navigate to={ROUTES.PURCHASE} state={{ from: location }} replace />;
  }

  if (isAuthFinished && isAuthenticated && userHasSubscription) {
    return (
      <Navigate
        to={mainRedirectDestination}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isAuthenticated: isAuthenticated(state),
  isAuthFinished: !isAuthLoading(state),
  userHasSubscription: userHasSubscription(state) === true,
  userDoesNotHaveSubscription: userHasSubscription(state) === false,
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(AuthLayout);
