import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import { callCoreApi, METHODS, API_URLS } from 'api';

import {
  ISendPasswordResetEmailApiRequest,
  ISendPasswordResetEmailApiResponse,
} from './types';

export const useSendPasswordResetEmail = () =>
  useMutation<unknown, AxiosError<ISendPasswordResetEmailApiResponse>, string>({
    mutationFn: (email) =>
      callCoreApi<ISendPasswordResetEmailApiRequest>({
        method: METHODS.POST,
        url: API_URLS.SEND_PASSWORD_RESET_EMAIL,
        data: { email, redirect_to: 'business_app' },
      }),
  });
