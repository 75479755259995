import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
} from 'recharts';

interface IBlueAreaChart {
  data: any;
  dataKeyY: string;
  dataKeyX: string;
  tooltipFormatter?: (value: string, name?: string, props?: any) => string[];
}

const BlueAreaChart: React.FC<IBlueAreaChart> = ({
  data,
  dataKeyY,
  dataKeyX,
  tooltipFormatter = (value: string) => [value, ''],
}) => (
  <ResponsiveContainer height={400}>
    <BarChart data={data} margin={{ bottom: 38, top: 48 }}>
      <XAxis
        dy={24}
        stroke="transparent"
        dataKey={dataKeyX}
        tick={{
          fill: '#8A939F',
          fontSize: 10,
          fontWeight: 600,
        }}
      />
      <YAxis
        dx={-10}
        width={42}
        stroke="transparent"
        tick={{
          fill: '#8A939F',
          fontSize: 10,
          fontWeight: 600,
        }}
      />
      <CartesianGrid
        strokeDasharray="5 5"
        stroke="rgba(138, 147, 159, 0.32)"
        vertical={false}
      />
      <Tooltip
        cursor={{
          fill: 'rgba(255, 255, 255, 0.15)',
        }}
        separator=""
        formatter={tooltipFormatter}
        labelStyle={{
          display: 'none',
        }}
        itemStyle={{
          color: '#FFFFFF',
          fontSize: 12,
          fontWeight: 700,
        }}
        contentStyle={{
          backgroundColor: '#000000',
          borderRadius: 10,
          border: 'none',
          padding: '4px 24px',
        }}
      />
      <Bar fill="#2AB9DB" dataKey={dataKeyY} radius={6} barSize={20} />
    </BarChart>
  </ResponsiveContainer>
);

export default BlueAreaChart;
