import FullScreenWrapper from 'components/FullScreenWrapper';
import SendPasswordResetEmailForm from 'features/SendPasswordResetEmail';

const SendPasswordReset: React.FC = () => (
  <FullScreenWrapper>
    <SendPasswordResetEmailForm />
  </FullScreenWrapper>
);

export default SendPasswordReset;
