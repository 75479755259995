const userAgent = navigator.userAgent.toLocaleLowerCase();
export const isAndroid = userAgent.indexOf('android') > -1;
export const isIphone = userAgent.indexOf('ios') > -1;
export const isMobile = isAndroid || isIphone;

export const isDev = process.env.NODE_ENV === 'development';

export const MOBILE_APP_DEEP_LINK = 'yoursapp://yours';

export enum QUERY_PARAMS {
  TOKEN = 'token',
  CODE = 'code',
  WEB_PLAN_ID = 'web_plan_id',
  COUPON = 'coupon',
}

export enum STORAGE_KEYS {
  REFRESH_TOKEN = 'refresh_token',
  WEB_PLAN_ID = 'web_plan_id',
  COUPON = 'coupon',
}

export const ERROR_TEXTS = {
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  EMAIL_PASSWORD_REQUIRED: 'Email and password are required',
  BLANK_FIELD: 'This field may not be blank',
  VALID_EMAIL: 'Please provide valid email',
  ASYNC_VALID_EMAIL: 'Email address is not valid',
  INCORRECT_EMAIL_OR_PASSWORD: 'The email address or password is incorrect',
  PASSWORD_VALIDATION_ERROR:
    'Password has to be 9 characters long, must contain upper case and at least 1 digit',
  SOMETHING_WENT_WRONG: 'Something went wrong. Please try again later.',
};

export const EXTERNAL_LINKS = {
  WEBSITE: 'https://yoursapp.com/',
  APP_STORE: 'https://apps.apple.com/us/app/yours-app/id1543920949',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.yoursapp',
  TERMS_AND_CONDITIONS: 'https://yoursapp.com/legal/terms-and-conditions',
  PRIVACY_POLICY: 'https://yoursapp.com/legal/privacy-policy',
};

export enum PERIODS {
  LAST_7_DAYS = 'last_7_days',
  LAST_30_DAYS = 'last_30_days',
  LAST_90_DAYS = 'last_90_days',
  LAST_YEAR = 'last_year',
}

export enum DATA_AGGREGATION {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum CATEGORIES {
  SLEEP = 'Sleep',
  MUSIC = 'Music',
  MEDITATION = 'Meditation',
  BODY = 'Body',
  PSYCHOLOGY = 'Psychology',
}
