import { connect } from 'react-redux';
import styled from '@emotion/styled';

import TopMetricCard from 'components/TopMetricCard';
import { BREAKPOINTS } from 'theme/constants';
import { IReduxState } from 'state/types';
import { getSelectedTeam } from 'features/StatsTeamSelect/selectors';

import UserIcon from './UserIcon';
import { useTotalNumbers } from '../queries';

interface IMapStateToProps {
  selectedTeam?: string;
}

type TTotalNumbers = IMapStateToProps;

const Container = styled.div({
  display: 'flex',
  marginBottom: 40,
  [BREAKPOINTS.TABLET]: {
    flexDirection: 'column',
  },
});

const CardWithMargin = styled(TopMetricCard)({
  margin: '0 26px',
  [BREAKPOINTS.TABLET]: {
    margin: '12px 0',
  },
});

const TotalNumbers: React.FC<TTotalNumbers> = ({ selectedTeam }) => {
  const { data } = useTotalNumbers(selectedTeam);

  return (
    <Container>
      <TopMetricCard
        icon={<UserIcon color="#B784EB" backgroundColor="#E7DBF3" />}
        title="Total users"
        result={data?.totalActiveUsers || 0}
      />
      <CardWithMargin
        icon={<UserIcon color="#2AB9DB" backgroundColor="#C3E1E8" />}
        title="Daily active users"
        result={data?.dailyActiveUsers || 0}
      />
      <TopMetricCard
        icon={
          <UserIcon
            color="#EEAA25"
            backgroundColor="rgba(238, 207, 147, 0.33)"
          />
        }
        title="Monthly active users"
        result={data?.monthlyActiveUsers || 0}
      />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  selectedTeam: getSelectedTeam(state),
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(TotalNumbers);
