import styled from '@emotion/styled';

import { ReactComponent as User } from 'assets/usersActivity/users.svg';

interface IIconProps {
  color?: string;
}

interface IContainerProps {
  backgroundColor?: string;
}

type TUserIcon = IContainerProps & IIconProps;

const Container = styled.div<IContainerProps>((props) => ({
  width: 56,
  height: 56,
  borderRadius: 28,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.backgroundColor,
}));

const StyledIcon = styled(User)<IIconProps>((props) => ({
  path: {
    fill: props.color,
  },
}));

const UserIcon: React.FC<TUserIcon> = ({ color, backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    <StyledIcon color={color} />
  </Container>
);

export default UserIcon;
