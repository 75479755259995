import { connect } from 'react-redux';

import TableEmailComponent from 'components/TableEmailComponent';
import { getLicenseeEmailByUid } from 'features/Licensees/selectors';
import { IReduxState } from 'state/types';

export interface IOwnProps {
  uid: string;
}

export interface IMapStateToProps {
  email: string;
}

const mapStateToProps = (state: IReduxState, { uid }: IOwnProps) => ({
  email: getLicenseeEmailByUid(state, uid),
});

export default connect<IMapStateToProps, unknown, IOwnProps, IReduxState>(
  mapStateToProps
)(TableEmailComponent);
