import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';

import {
  addByUid,
  addUids,
  deleteByUid,
  deleteUid,
  updateSimpleValue,
} from 'utils/reducerHelpers';
import { IByUid } from 'types/common';
import resetReducer from 'utils/resetReducer';

import { IManageTeams, ITeams, TNormalizedTeam } from './types';
import {
  SET_LOADING,
  SET_SEARCH_VALUE,
  SET_TEAMS,
  SET_CREATE_TEAM_MODAL_VISIBLE,
  SET_NEW_TEAM_NAME,
  DELETE_TEAM,
  RESET_TEAMS,
} from './actionTypes';

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const searchValueReducer = handleAction<string, string>(
  SET_SEARCH_VALUE,
  updateSimpleValue,
  ''
);

const isCreateTeamModalVisibleReducer = handleAction<boolean, boolean>(
  SET_CREATE_TEAM_MODAL_VISIBLE,
  updateSimpleValue,
  false
);

const newTeamNameReducer = handleAction<string, string>(
  SET_NEW_TEAM_NAME,
  updateSimpleValue,
  ''
);

const teamsUidsReducer = handleActions<string[], any>(
  {
    [SET_TEAMS]: addUids,
    [DELETE_TEAM]: deleteUid,
  },
  []
);

const teamsByUidReducer = handleActions<IByUid<TNormalizedTeam>, any>(
  {
    [SET_TEAMS]: addByUid,
    [DELETE_TEAM]: deleteByUid,
  },
  {}
);

const teamsReducers = combineReducers<ITeams>({
  uids: teamsUidsReducer,
  byUid: teamsByUidReducer,
});

export default combineReducers<IManageTeams>({
  isLoading: isLoadingReducer,
  searchValue: searchValueReducer,
  teams: resetReducer([RESET_TEAMS], teamsReducers),
  isCreateTeamModalVisible: isCreateTeamModalVisibleReducer,
  newTeamName: newTeamNameReducer,
});
