import { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { PERIODS } from 'utils/constants';
import ContainerPlaceholder from 'components/ContainerPlaceholder';
import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import DropdownSelect from 'components/DropdownSelect';

import { useCircumstancesIcons, useCircumstances } from '../../queries';
import { circumstancesConstants } from '../../constants';

import { ICircumstance } from '../../types';
import CircumstancesLabels from './CircumstancesLabels';
import CircumstancesChart from './CircumstancesChart';
import CircumstancesIcon from './CircumstancesIcon';

const Content = styled(ContainerPlaceholder)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  minHeight: 377,
});

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const DropdownSelectContainer = styled.div({
  width: 158,
});

const Chart = styled.div({
  display: 'flex',
  position: 'relative',
});

const options = [
  {
    value: PERIODS.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: PERIODS.LAST_30_DAYS,
    label: 'Last 30 days',
  },
  {
    value: PERIODS.LAST_90_DAYS,
    label: 'Last 90 days',
  },
  {
    value: PERIODS.LAST_YEAR,
    label: 'Last year',
  },
];

const Circumstances: React.FC = () => {
  const [currentPeriod, setPeriod] = useState<PERIODS>(PERIODS.LAST_30_DAYS);

  const { data: iconsData = {} } = useCircumstancesIcons();

  const { data: circumstancesData = [], isLoading } =
    useCircumstances(currentPeriod);

  const circumstances: ICircumstance[] = useMemo(
    () =>
      circumstancesData
        .map(({ circumstance, ...rest }) => ({
          icon: iconsData[circumstance],
          color:
            circumstancesConstants[circumstance]?.color ??
            circumstancesConstants.OTHER.color,
          label:
            circumstancesConstants[circumstance]?.label ??
            circumstance.replace(/^\w/, (c) => c.toUpperCase()),
          ...rest,
        }))
        .sort((a, b) => b.percentage - a.percentage),
    [circumstancesData, iconsData]
  );

  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    setActiveIndex(0);
  }, [circumstances]);

  const currentCircumstance = circumstances[activeIndex];

  const backdropRef = useRef<HTMLDivElement>(null);

  return (
    <ChartContainer ref={backdropRef}>
      <Header>
        <ChartTitle>Circumstances</ChartTitle>
        <DropdownSelectContainer>
          <DropdownSelect<PERIODS>
            data={options}
            onChange={setPeriod}
            value={currentPeriod}
          />
        </DropdownSelectContainer>
      </Header>
      <Content
        isLoading={isLoading}
        hasNoData={!circumstances?.length}
        backdropNode={backdropRef.current}
      >
        {!!circumstances?.length && (
          <>
            <Chart>
              <CircumstancesChart
                circumstances={circumstances}
                activeIndex={activeIndex}
                onCellHover={setActiveIndex}
              />
              {currentCircumstance && (
                <CircumstancesIcon circumstance={currentCircumstance} />
              )}
            </Chart>
            <CircumstancesLabels
              circumstances={circumstances}
              onLabelHover={setActiveIndex}
            />
          </>
        )}
      </Content>
    </ChartContainer>
  );
};

export default Circumstances;
