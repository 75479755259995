import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import resetReducer from 'utils/resetReducer';
import { updateSimpleValue } from 'utils/reducerHelpers';
import { IPurchase } from './types';
import {
  RESET_STATE,
  SET_QUANTITY_OF_LICENSES,
  SET_TOTAL_PRICE,
  SET_GET_PRICE_LOADING,
  SET_CEILING_LIMIT_ERROR,
  SET_FLOOR_LIMIT_ERROR,
  SET_PAYMENT_ERROR,
  SET_LICENSE_QUANTITY_ERROR,
  SET_PURCHASE_LOADING,
} from './actionTypes';

const quantityOfLicensesReducer = handleAction<string, string>(
  SET_QUANTITY_OF_LICENSES,
  updateSimpleValue,
  ''
);

const totalPriceReducer = handleAction<string, string>(
  SET_TOTAL_PRICE,
  updateSimpleValue,
  ''
);

const paymentErrorReducer = handleAction<string, string>(
  SET_PAYMENT_ERROR,
  updateSimpleValue,
  ''
);

const licenseQuantityErrorReducer = handleAction<string, string>(
  SET_LICENSE_QUANTITY_ERROR,
  updateSimpleValue,
  ''
);

const isGetPriceLoadingReducer = handleAction<boolean, boolean>(
  SET_GET_PRICE_LOADING,
  updateSimpleValue,
  false
);

const isCeilingLimitErrorReducer = handleAction<boolean, boolean>(
  SET_CEILING_LIMIT_ERROR,
  updateSimpleValue,
  false
);

const isFloorLimitErrorReducer = handleAction<boolean, boolean>(
  SET_FLOOR_LIMIT_ERROR,
  updateSimpleValue,
  false
);

const isPurchaseLoadingReducer = handleAction<boolean, boolean>(
  SET_PURCHASE_LOADING,
  updateSimpleValue,
  false
);

const purchaseReducers = combineReducers<IPurchase>({
  quantityOfLicenses: quantityOfLicensesReducer,
  totalPrice: totalPriceReducer,
  isGetPriceLoading: isGetPriceLoadingReducer,
  isCeilingLimitError: isCeilingLimitErrorReducer,
  isFloorLimitError: isFloorLimitErrorReducer,
  paymentError: paymentErrorReducer,
  isPurchaseLoading: isPurchaseLoadingReducer,
  licenseQuantityError: licenseQuantityErrorReducer,
});

export default resetReducer([RESET_STATE], purchaseReducers);
