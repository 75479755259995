import { createSelector } from 'reselect';

import { IReduxState } from 'state/types';
import { TNormalizedLicensee } from 'features/Licensees/types';
import {
  getTeamMembersDataValues,
  getTeamMembersUids,
} from 'features/TeamMembers/selectors';

import { NAME } from './constants';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const getSearchValue = (state: IReduxState) =>
  state?.[NAME]?.searchValue;

export const getActionPopupVisibleUid = (state: IReduxState) =>
  state?.[NAME]?.actionPopupVisibleUid;

export const getUidsBySearchValue = createSelector(
  getTeamMembersUids,
  getSearchValue,
  getTeamMembersDataValues,
  (
    allUids: string[],
    searchValue: string,
    teamMembersValues: TNormalizedLicensee[]
  ) => {
    const foundValues: string[] = [];

    if (!searchValue) {
      return allUids;
    }

    teamMembersValues.forEach((teamMember) => {
      if (
        teamMember.email
          .toLocaleLowerCase()
          .search(searchValue.toLocaleLowerCase()) !== -1
      ) {
        foundValues.push(teamMember.uid);
      }
    });

    return foundValues;
  }
);
