import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
  Tooltip,
} from 'recharts';
import { useTheme } from '@emotion/react';

import { ICircumstance } from '../../types';

interface ICircumstancesChart {
  circumstances: ICircumstance[];
  activeIndex: number;
  onCellHover: (index: number) => void;
}

const CircumstancesChart: React.FC<ICircumstancesChart> = ({
  circumstances,
  activeIndex,
  onCellHover,
}) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer aspect={1} height={377}>
      <PieChart>
        <Pie
          data={circumstances}
          activeIndex={activeIndex}
          activeShape={({
            cx,
            cy,
            innerRadius,
            outerRadius = 0,
            startAngle,
            endAngle,
            fill,
          }) => (
            <g>
              <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius + 15}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
              />
            </g>
          )}
          dataKey="percentage"
          nameKey="label"
          innerRadius={93}
          onMouseEnter={(_data, index) => onCellHover(index)}
        >
          {circumstances.map(({ id, color }) => (
            <Cell key={id} fill={color} stroke={color} />
          ))}
        </Pie>
        <Tooltip
          formatter={(value: string, key: string) => [` (${value}%)`, key]}
          separator=""
          wrapperStyle={{
            zIndex: 1,
          }}
          itemStyle={{
            color: theme.colors.titleText,
            fontSize: 12,
            fontWeight: 700,
          }}
          contentStyle={{
            backgroundColor: '#FFFFFF',
            borderRadius: 10,
            border: 'none',
            padding: '4px 12px',
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CircumstancesChart;
