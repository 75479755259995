import styled from '@emotion/styled';

export default styled.div((props) => ({
  backgroundColor: props.theme.colors.formBackgroundGray,
  borderRadius: 20,
  padding: '28px 30px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: props.theme.shadows.light,
}));
