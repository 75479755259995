import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';
import { PERIODS } from 'utils/constants';

import {
  CATEGORIES_EXTRA_OPTIONS,
  IContentUsage,
  TCategorySelect,
} from './types';
import {
  SET_CONTENT_USAGE_PERIOD,
  SET_CATEGORY_FOR_SUBCATEGORIES,
} from './actionTypes';

const contentUsagePeriodReducer = handleAction<PERIODS, PERIODS>(
  SET_CONTENT_USAGE_PERIOD,
  updateSimpleValue,
  PERIODS.LAST_7_DAYS
);

const categoryForSubcategoriesReducer = handleAction<
  TCategorySelect,
  TCategorySelect
>(
  SET_CATEGORY_FOR_SUBCATEGORIES,
  updateSimpleValue,
  CATEGORIES_EXTRA_OPTIONS.MOST_POPULAR
);

export default combineReducers<IContentUsage>({
  contentUsagePeriod: contentUsagePeriodReducer,
  categoryForSubcategories: categoryForSubcategoriesReducer,
});
