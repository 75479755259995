import { useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { TooltipProps } from 'recharts';

import { PERIODS } from 'utils/constants';
import ChartContainer from 'components/ChartContainer';
import ChartTitle from 'components/ChartTitle';
import DropdownSelect from 'components/DropdownSelect';
import BlueAreaChart from 'components/BlueAreaChart';

import { useMoodsQuestions, useTeamsQuestions } from '../../queries';
import { MOODS, TEAM_QUESTIONS } from '../../constants';

import TeamQuestionsTooltip from './TeamQuestionsTooltip';
import ContainerPlaceholder from '../../../../components/ContainerPlaceholder';

interface ITeamQuestions {
  question: TEAM_QUESTIONS;
  title: string;
}

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const DropdownSelectContainer = styled.div({
  width: 158,
});

const options = [
  {
    value: PERIODS.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: PERIODS.LAST_30_DAYS,
    label: 'Last 30 days',
  },
  {
    value: PERIODS.LAST_90_DAYS,
    label: 'Last 90 days',
  },
  {
    value: PERIODS.LAST_YEAR,
    label: 'Last year',
  },
];

const TeamsQuestions: React.FC<ITeamQuestions> = ({ question, title }) => {
  const [currentPeriod, setPeriod] = useState<PERIODS>(PERIODS.LAST_YEAR);

  const questionData = useMoodsQuestions(question);

  const {
    renderTick,
    answers = [],
    id,
  } = useMemo(() => {
    if (!questionData) return {};

    const { answers, id } = questionData;

    const renderTick = (props: { index: MOODS; y: number }) => {
      const icon = answers[props.index].icon;
      const size = 24;
      return (
        <svg
          viewBox={`0 0 1000 1000`}
          x="0"
          y={props.y - size / 2}
          width={size}
          height={size}
        >
          <image
            x="0"
            y="0"
            width="1000"
            height="1000"
            preserveAspectRatio="none"
            xlinkHref={icon}
          />
        </svg>
      );
    };

    return { renderTick, answers, id };
  }, [questionData?.id]);

  const { data = [], isLoading } = useTeamsQuestions(id, currentPeriod);

  const backdropRef = useRef<HTMLDivElement>(null);
  return (
    <ChartContainer ref={backdropRef}>
      <Header>
        <ChartTitle>{title}</ChartTitle>
        <DropdownSelectContainer>
          <DropdownSelect<PERIODS>
            data={options}
            value={currentPeriod}
            onChange={setPeriod}
          />
        </DropdownSelectContainer>
      </Header>
      <ContainerPlaceholder
        isLoading={isLoading}
        backdropNode={backdropRef.current}
        hideNoDataPlaceholder
      >
        <BlueAreaChart
          data={data}
          dataKeyY="answer_score"
          dataKeyX="date"
          renderTooltip={(props: TooltipProps<any[], string>) =>
            answers.length ? (
              <TeamQuestionsTooltip
                dataMax={answers.length - 1}
                answers={answers}
                {...props}
              />
            ) : (
              <></>
            )
          }
          yAxisProps={{
            domain: [0, answers.length - 1],
            width: 45,
            tick: renderTick,
          }}
          tooltipProps={{
            offset: 0,
            allowEscapeViewBox: { x: true, y: true },
            cursor: {
              stroke: '#FFFFFF',
              strokeDasharray: '5 5',
              strokeWidth: 2,
            },
          }}
          areaProps={{
            activeDot: {
              stroke: '#FFFFFF',
              strokeWidth: 3,
              r: 6,
              fill: '#2D2D2D',
            },
          }}
        />
      </ContainerPlaceholder>
    </ChartContainer>
  );
};

export default TeamsQuestions;
