import React, { useState } from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Theme, withTheme } from '@emotion/react';

import Menu from './Menu';
import Arrow from 'assets/menu_arrow';
import { IReduxState } from 'state/types';
import { getFirstName, isAuthenticated } from 'features/Auth/selectors';
import hideable, { TWithHideableProp } from 'utils/hideable';

interface IMapStateToProps {
  name: string;
  nameLetters: string;
  elementColor: string;
}

interface IOwnProps {
  isScrollMenu?: boolean;
  theme: Theme;
}

interface IStyledProps {
  elementColor: string;
}

type TSettingsButton = IMapStateToProps;

const Container = styled.div({
  position: 'relative',
  cursor: 'pointer',
  marginLeft: 'auto',
});

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const NameLetter = styled.div<IStyledProps>((props) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  padding: '9px 10px',
  borderRadius: 30,
  color: props.elementColor,
  fontSize: 16,
}));

const NameContainer = styled.div<IStyledProps>((props) => ({
  color: props.elementColor,
  fontSize: 16,
  margin: '0 12px',
}));

const SettingsButton: React.FC<TSettingsButton> = ({ name, elementColor }) => {
  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);

  return (
    <Container>
      <ButtonContainer onClick={() => setMenuVisibility(!isMenuVisible)}>
        <NameLetter elementColor={elementColor}>
          {`${name.charAt(0)}${name.charAt(2).toLocaleUpperCase()}`}
        </NameLetter>
        <NameContainer elementColor={elementColor}>{name}</NameContainer>
        <Arrow fill={elementColor} />
      </ButtonContainer>
      <Menu
        isVisible={isMenuVisible}
        closeHandler={() => setMenuVisibility(false)}
      />
    </Container>
  );
};

const mapStateToProps = (
  state: IReduxState,
  { isScrollMenu, theme }: IOwnProps
) => {
  const firstName = getFirstName(state);

  return {
    name: firstName.toLocaleUpperCase(),
    isVisible: isAuthenticated(state),
    elementColor: isScrollMenu ? theme.colors.white : theme.colors.darkText,
    nameLetters: `
      ${firstName.charAt(0).toLocaleUpperCase()}${firstName
      .charAt(2)
      .toLocaleUpperCase()}
    `,
  };
};

export default withTheme(
  connect<TWithHideableProp<IMapStateToProps>, unknown, IOwnProps, IReduxState>(
    mapStateToProps
  )(hideable<TSettingsButton>(SettingsButton))
);
