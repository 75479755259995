import styled from '@emotion/styled';

import { ReactComponent as User } from 'assets/user.svg';
import Text from 'components/Text';

export interface ITableEmailComponent {
  email: string;
}

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const IconContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.actionBackgroundGray,
  borderRadius: 50,
  height: 27,
  width: 27,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 16,
}));

const StyledText = styled(Text)((props) => ({
  color: props.theme.colors.titleText,
}));

export const TableEmailComponent: React.FC<ITableEmailComponent> = ({
  email,
}) => (
  <Container>
    <IconContainer>
      <User />
    </IconContainer>
    <StyledText>{email}</StyledText>
  </Container>
);

export default TableEmailComponent;
