import styled from '@emotion/styled';

import Text from 'components/Text';
import Button from 'components/Button';
import Input from 'components/Input';
import hideable from 'utils/hideable';
import { BREAKPOINTS } from 'theme/constants';

interface IActionForm {
  title: string;
  description?: string;
  inputPlaceholder?: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  value: string;
  error: string;
  isButtonLoading: boolean;
  onChange: (value: string) => void;
  onCancelClick: () => void;
  onSubmitClick: () => void;
}

const ButtonsContainer = styled.div({
  display: 'flex',
  marginTop: 24,
  justifyContent: 'center',
  [BREAKPOINTS.XL_MOBILE]: {
    flexDirection: 'column-reverse',
  },
});

const CancelButton = styled(Button)({
  marginRight: 8,
  maxWidth: 140,
  [BREAKPOINTS.XL_MOBILE]: {
    marginRight: 0,
    marginTop: 8,
  },
});

const SubmitButton = styled(Button)({
  maxWidth: 140,
});

const Title = styled(Text)({
  marginBottom: 16,
});

const Description = styled(Text)({
  marginBottom: 16,
});

const ActionForm: React.FC<IActionForm> = ({
  value,
  error,
  isButtonLoading,
  title,
  description,
  inputPlaceholder,
  cancelButtonText = 'Cancel',
  submitButtonText = 'Submit',
  onCancelClick,
  onChange,
  onSubmitClick,
}) => (
  <>
    <Title type="h1" weight={600}>
      {title}
    </Title>
    {description && <Description>{description}</Description>}
    <Input
      placeholder={inputPlaceholder}
      onChange={onChange}
      value={value}
      error={error}
    />
    <ButtonsContainer>
      <CancelButton
        onClick={onCancelClick}
        text={cancelButtonText}
        buttonType="light"
      />
      <SubmitButton
        onClick={onSubmitClick}
        text={submitButtonText}
        isLoading={isButtonLoading}
        isDisabled={!value}
      />
    </ButtonsContainer>
  </>
);

export default hideable<IActionForm>(ActionForm);
