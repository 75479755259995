import { AxiosError } from 'axios';
import { createAction } from 'redux-actions';

import { TThunk } from 'types/common';
import { API_URLS, callCoreApi, METHODS } from 'api';
import {
  getTeamMemberRoleByUid,
  getTeamMemberByUid,
} from 'features/TeamMembers/selectors';
import {
  removeTeamMember,
  updateTeamMember,
} from 'features/TeamMembers/actions';

import { isLoading } from './selectors';
import { TRemoveMemberApiProps, IChangeRoleApiProps } from './types';
import { ROLE } from 'features/Licensees/types';
import {
  SET_LOADING,
  SET_SEARCH_VALUE,
  SET_ACTION_POPUP_VISIBLE_UID,
} from './actionTypes';

export const setLoading = createAction<boolean>(SET_LOADING);
export const setSearchValue = createAction<string>(SET_SEARCH_VALUE);
export const setActionPopupVisibleUid = createAction<string>(
  SET_ACTION_POPUP_VISIBLE_UID
);

export const handleChangeRoleClick =
  (uid: string, teamUid: string): TThunk =>
  (dispatch, getState) => {
    const state = getState();
    const shouldPrevent = isLoading(state);

    if (shouldPrevent) {
      return;
    }

    dispatch(setLoading(true));

    const isTeamLead = getTeamMemberRoleByUid(state, uid) === ROLE.TEAM_LEAD;
    const teamMember = getTeamMemberByUid(state, uid);

    if (!teamMember) {
      return;
    }

    callCoreApi<IChangeRoleApiProps>({
      method: METHODS.PATCH,
      url: API_URLS.TEAMS,
      path: `${teamUid}/licenses/${uid}/`,
      authorized: true,
      data: {
        is_team_lead: !isTeamLead,
      },
    })
      .then(() => {
        const updatedTeamMember = {
          ...teamMember,
          role: isTeamLead ? ROLE.MEMBER : ROLE.TEAM_LEAD,
        };

        dispatch(updateTeamMember(updatedTeamMember));
      })
      .catch((_error: AxiosError) => {
        // TODO: handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setActionPopupVisibleUid(''));
      });
  };

export const handleRemoveTeamMemberClick =
  (uid: string, teamUid: string): TThunk =>
  (dispatch, getState) => {
    const state = getState();
    const shouldPrevent = isLoading(state);

    if (shouldPrevent) {
      return;
    }

    dispatch(setLoading(true));

    callCoreApi<TRemoveMemberApiProps>({
      method: METHODS.POST,
      url: API_URLS.TEAMS,
      path: `${teamUid}/remove-licenses/`,
      authorized: true,
      data: [{ id: uid }],
    })
      .then(() => {
        dispatch(removeTeamMember(uid));
      })
      .catch((_error: AxiosError) => {
        // TODO: handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
