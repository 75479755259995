import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const isPurchasePopupVisible = (state: IReduxState) =>
  state?.[NAME]?.isPurchasePopupVisible;

export const getQuantityOfLicenses = (state: IReduxState) =>
  state?.[NAME]?.quantityOfLicenses;

export const getQuantityOfLicensesError = (state: IReduxState) =>
  state?.[NAME]?.quantityOfLicensesError;

export const isGetPriceLoading = (state: IReduxState) =>
  state?.[NAME]?.isGetPriceLoading;

export const isPurchaseLoading = (state: IReduxState) =>
  state?.[NAME]?.isPurchaseLoading;

export const getPrice = (state: IReduxState) => state?.[NAME]?.price;
