import { combineReducers } from 'redux';
import { handleActions, handleAction } from 'redux-actions';

import resetReducer from 'utils/resetReducer';
import { IByUid } from 'types/common';
import { IOption } from 'components/DropdownMultiSelect';
import { TNormalizedLicensee } from 'features/Licensees/types';
import {
  addByUid,
  addUids,
  deleteByUid,
  deleteUid,
  updateByUid,
  updateSimpleValue,
} from 'utils/reducerHelpers';

import { ITeamMembers, IData } from './types';
import {
  SET_TEAM_MEMBERS,
  SET_LOADING,
  REMOVE_TEAM_MEMBER,
  RESET_TEAM_MEMBERS_DATA,
  SET_NEW_MEMBER_MODAL_VISIBLE,
  SET_TEAM_NAME,
  SET_SELECTED_NEW_MEMBERS,
  UPDATE_TEAM_MEMBERS,
} from './actionTypes';

const isLoadingReducer = handleAction<boolean, boolean>(
  SET_LOADING,
  updateSimpleValue,
  false
);

const isNewMemberModalVisibleReducer = handleAction<boolean, boolean>(
  SET_NEW_MEMBER_MODAL_VISIBLE,
  updateSimpleValue,
  false
);

const selectedNewMembersReducer = handleAction<IOption[], IOption[]>(
  SET_SELECTED_NEW_MEMBERS,
  updateSimpleValue,
  []
);

const teamNameReducer = handleAction<string, string>(
  SET_TEAM_NAME,
  updateSimpleValue,
  'team1'
);

const teamMembersUidsReducer = handleActions<string[], any>(
  {
    [SET_TEAM_MEMBERS]: addUids,
    [REMOVE_TEAM_MEMBER]: deleteUid,
  },
  []
);

const teamMembersByUidReducer = handleActions<IByUid<TNormalizedLicensee>, any>(
  {
    [SET_TEAM_MEMBERS]: addByUid,
    [REMOVE_TEAM_MEMBER]: deleteByUid,
    [UPDATE_TEAM_MEMBERS]: updateByUid,
  },
  {}
);

const teamMembersDataReducers = combineReducers<IData>({
  teamMembersUids: teamMembersUidsReducer,
  teamMembersByUid: teamMembersByUidReducer,
});

export default combineReducers<ITeamMembers>({
  isLoading: isLoadingReducer,
  data: resetReducer([RESET_TEAM_MEMBERS_DATA], teamMembersDataReducers),
  teamName: resetReducer([RESET_TEAM_MEMBERS_DATA], teamNameReducer),
  isNewMemberModalVisible: isNewMemberModalVisibleReducer,
  selectedNewMembers: selectedNewMembersReducer,
});
