import { connect } from 'react-redux';

import { IReduxState } from 'state/types';
import Button from 'components/Button';

import { handleInviteLicenseeButtonClick } from '../actions';

interface IMapStateToProps {
  text: string;
}

interface IMapDispatchToProps {
  onClick: () => void;
}

const mapStateToProps = (_state: IReduxState) => ({
  text: '+ Add new licensee',
});

const mapDispatchToProps = {
  onClick: handleInviteLicenseeButtonClick,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(Button);
