import { Routes, Route } from 'react-router-dom';

import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import PurchaseLayout from 'layouts/PurchaseLayout';
import UsersManagement from 'layouts/UsersManagement';

import UsersActivity from 'pages/UsersActivity';
import ContentUsage from 'pages/ContentUsage';
import MoodTracker from 'pages/MoodTracker';
import Purchase from 'pages/Purchase';
import SignIn from 'pages/SignIn';
import SendPasswordReset from 'pages/SendPasswordReset';
import PasswordReset from 'pages/PasswordReset';
import Registration from 'pages/Registration';
import PageNotFound from 'pages/PageNotFound';

import LicenseesTable from 'features/LicenseesTable/components';
import ManageTeams from 'features/ManageTeams/components';
import TeamMembers from 'features/TeamMembers/components';

import hideable from 'utils/hideable';

import { ROUTES } from './constants';

const Navigation: React.FC = () => (
  <Routes>
    {/* PUBLIC ROUTES */}

    <Route element={<AuthLayout />}>
      <Route path={ROUTES.REGISTRATION} element={<Registration />} />
      <Route path={ROUTES.LOGIN} element={<SignIn />} />
      <Route
        path={ROUTES.SEND_PASSWORD_RESET_EMAIL}
        element={<SendPasswordReset />}
      />
      <Route path={ROUTES.RESET_PASSWORD} element={<PasswordReset />} />
    </Route>

    {/* PURCHASE ROUTES */}

    <Route element={<PurchaseLayout />}>
      <Route path={ROUTES.PURCHASE} element={<Purchase />} />
    </Route>

    {/* PRIVATE ROUTES */}

    <Route path={ROUTES.MAIN} element={<AppLayout />}>
      <Route element={<UsersManagement />}>
        <Route index element={<LicenseesTable />} />
        <Route path={ROUTES.TEAMS} element={<ManageTeams />} />
        <Route
          path={`${ROUTES.TEAMS}/:teamName/:teamUid`}
          element={<TeamMembers />}
        />
      </Route>
      <Route path={ROUTES.CONTENT_USAGE} element={<ContentUsage />} />
      <Route path={ROUTES.USERS_ACTIVITY} element={<UsersActivity />} />
      <Route path={ROUTES.MOOD_TRACKER} element={<MoodTracker />} />
    </Route>

    {/* 404 */}

    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default hideable(Navigation);
