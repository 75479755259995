import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { Table, IDataItem, IHeader } from 'components/Table';
import Text from 'components/Text';
import { IReduxState } from 'state/types';
import { getTeamMembersUids } from 'features/TeamMembers/selectors';
import hideable, { TWithHideableProp } from 'utils/hideable';

import MemberEmail from './MemberEmail';
import MemberRole from './MemberRole';
import MemberStatus from './MemberStatus';
import MemberAction from './MemberAction';
import { TABLE_KEYS } from '../constants';
import { getUidsBySearchValue } from '../selectors';

interface IMapStateToProps {
  header: IHeader[];
  data: IDataItem[];
}

const HeaderComponent = styled(Text)((props) => ({
  color: props.theme.colors.lightGray,
}));

const Header: IHeader[] = [
  {
    name: TABLE_KEYS.EMAIL,
    component: <HeaderComponent type="small">EMAIL</HeaderComponent>,
  },
  {
    name: TABLE_KEYS.ROLE,
    component: <HeaderComponent type="small">ROLE</HeaderComponent>,
  },
  {
    name: TABLE_KEYS.STATUS,
    component: <HeaderComponent type="small">STATUS</HeaderComponent>,
  },
  {
    name: TABLE_KEYS.ACTION,
    component: <HeaderComponent type="small">ACTION</HeaderComponent>,
  },
];

const renderRows = (uids: string[]) =>
  uids.map((uid) => ({
    key: uid,
    [TABLE_KEYS.EMAIL]: <MemberEmail uid={uid} />,
    [TABLE_KEYS.ROLE]: <MemberRole uid={uid} />,
    [TABLE_KEYS.STATUS]: <MemberStatus uid={uid} />,
    [TABLE_KEYS.ACTION]: <MemberAction uid={uid} />,
  }));

const mapStateToProps = (state: IReduxState) => {
  const uids = getUidsBySearchValue(state);

  return {
    header: Header,
    isVisible: !!getTeamMembersUids(state)?.length,
    data: renderRows(uids),
  };
};

export default connect<
  TWithHideableProp<IMapStateToProps>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(hideable(Table));
