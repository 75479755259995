import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
} from 'recharts';

interface IBlackLineChart {
  data: any;
  dataKeyY: string;
  dataKeyX: string;
}

const BlackLineChart: React.FC<IBlackLineChart> = ({
  data,
  dataKeyY,
  dataKeyX,
}) => (
  <ResponsiveContainer height={400}>
    <LineChart data={data} margin={{ bottom: 38, top: 48 }}>
      <XAxis
        dy={24}
        stroke="transparent"
        dataKey={dataKeyX}
        tick={{
          fill: '#8A939F',
          fontSize: 10,
          fontWeight: 600,
        }}
        domain={data?.length ? undefined : ['', '']}
      />
      <YAxis
        dx={-10}
        width={42}
        stroke="transparent"
        tick={{
          fill: '#8A939F',
          fontSize: 10,
          fontWeight: 600,
        }}
      />
      <CartesianGrid
        strokeDasharray="5 5"
        stroke="rgba(138, 147, 159, 0.32)"
        vertical={false}
      />
      <Tooltip
        cursor={{
          stroke: '#000000',
          strokeDasharray: '5 5',
          strokeWidth: 2,
        }}
        separator=""
        formatter={(value: string) => [value, '']}
        labelStyle={{
          display: 'none',
        }}
        itemStyle={{
          color: '#FFFFFF',
          fontSize: 12,
          fontWeight: 700,
        }}
        contentStyle={{
          backgroundColor: '#000000',
          borderRadius: 10,
          border: 'none',
          padding: '4px 24px',
        }}
      />
      <Line
        type="monotone"
        stroke="#000000"
        strokeWidth={3}
        dataKey={dataKeyY}
        activeDot={{
          stroke: '#000000',
          strokeWidth: 4,
          r: 6,
          fill: '#FFFFFF',
          enableBackground: 10,
        }}
      />
    </LineChart>
  </ResponsiveContainer>
);

export default BlackLineChart;
