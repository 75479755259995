import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { IUsersActivity } from './types';
import { SET_DAILY_ACTIVE_USERS_PERIOD } from './actionTypes';
import { PERIODS } from 'utils/constants';

const dailyActiveUsersPeriodReducer = handleAction<PERIODS, PERIODS>(
  SET_DAILY_ACTIVE_USERS_PERIOD,
  updateSimpleValue,
  PERIODS.LAST_7_DAYS
);

export default combineReducers<IUsersActivity>({
  dailyActiveUsersPeriod: dailyActiveUsersPeriodReducer,
});
