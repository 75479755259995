import { values } from 'ramda';
import { createSelector } from 'reselect';

import { IReduxState } from 'state/types';
import { getLicenseesByUidObjectValues } from 'features/Licensees/selectors';

import { NAME } from './constants';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const isNewMemberModalVisible = (state: IReduxState) =>
  state?.[NAME]?.isNewMemberModalVisible;

export const getSelectedNewMembers = (state: IReduxState) =>
  state?.[NAME]?.selectedNewMembers;

export const getTeamName = (state: IReduxState) =>
  state?.[NAME]?.teamName || '';

export const getTeamMembersUids = (state: IReduxState) =>
  state?.[NAME]?.data?.teamMembersUids || [];

export const getTeamMembersDataValues = (state: IReduxState) =>
  values(state?.[NAME]?.data?.teamMembersByUid || {});

export const getTeamMemberByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.data?.teamMembersByUid?.[uid];

export const getTeamMemberEmailByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.data?.teamMembersByUid?.[uid]?.email || '';

export const getTeamMemberStatusByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.data?.teamMembersByUid?.[uid]?.status || 'inactive';

export const getTeamMemberCreatedAtByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.data?.teamMembersByUid?.[uid]?.created_at || '';

export const getTeamMemberRoleByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.data?.teamMembersByUid?.[uid]?.role;

export const getLicenseesOptions = createSelector(
  getTeamMembersUids,
  getLicenseesByUidObjectValues,
  (teamMembersUids, licenseesObject) =>
    licenseesObject
      .map((licensee) => ({
        label: licensee.email,
        value: licensee.uid,
      }))
      .filter((licensee) => !teamMembersUids.includes(licensee.value))
);
