import styled from '@emotion/styled';

import InviteLicenseeButton from './InviteLicenseeButton';
import InviteLicenseePopup from './InviteLicenseePopup';

const ButtonContainer = styled.div({
  width: 185,
});

const InviteLicensee: React.FC = () => (
  <>
    <ButtonContainer>
      <InviteLicenseeButton />
    </ButtonContainer>
    <InviteLicenseePopup />
  </>
);

export default InviteLicensee;
