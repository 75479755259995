import { connect } from 'react-redux';

import { IReduxState } from 'state/types';
import { TDispatch } from 'types/common';
import TablePlaceholder from 'components/EmptyTablePlaceholder';
import hideable, { TWithHideableProp } from 'utils/hideable';

import { getTeamMembersUids } from '../selectors';
import { setNewMemberModalVisible } from '../actions';

interface IMapStateToProps {
  text: string;
}

interface IMapDispatchToProps {
  onClick: () => void;
}

const mapStateToProps = (state: IReduxState) => ({
  isVisible: !getTeamMembersUids(state).length,
  text: 'Invite the first member',
});

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onClick: () => dispatch(setNewMemberModalVisible(true)),
});

export default connect<
  TWithHideableProp<IMapStateToProps>,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(hideable(TablePlaceholder));
