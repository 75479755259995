import { createAction } from 'redux-actions';
import { AxiosError, AxiosResponse } from 'axios';
import { PaymentMethod } from '@stripe/stripe-js';

import { API_URLS, callCoreApi, METHODS } from 'api';
import { TThunk } from 'types/common';
import { getOrganizationId, getRefreshToken } from 'features/Auth/selectors';
import { refreshTokenRequest, setAuthData } from 'features/Auth/actions';
import { TSignInResponse } from 'features/Auth/types';
import { ERROR_TEXTS } from 'utils/constants';

import { getQuantityOfLicenses } from './selectors';
import {
  IGetPriceApiProps,
  IGetPriceApiResponse,
  ICompletePurchaseApiProps,
  ICompletePurchaseApiError,
} from './types';
import {
  RESET_STATE,
  SET_QUANTITY_OF_LICENSES,
  SET_TOTAL_PRICE,
  SET_GET_PRICE_LOADING,
  SET_FLOOR_LIMIT_ERROR,
  SET_CEILING_LIMIT_ERROR,
  SET_PAYMENT_ERROR,
  SET_PURCHASE_LOADING,
  SET_LICENSE_QUANTITY_ERROR,
} from './actionTypes';

export const setTotalPrice = createAction<string>(SET_TOTAL_PRICE);
export const setGetPriceLoading = createAction<boolean>(SET_GET_PRICE_LOADING);
export const setPaymentError = createAction<string>(SET_PAYMENT_ERROR);
export const resetState = createAction(RESET_STATE);
export const setFloorLimitError = createAction<boolean>(SET_FLOOR_LIMIT_ERROR);
export const setPurchaseLoading = createAction<boolean>(SET_PURCHASE_LOADING);
export const setQuantityOfLicenses = createAction<string>(
  SET_QUANTITY_OF_LICENSES
);
export const setLicenseQuantityError = createAction<string>(
  SET_LICENSE_QUANTITY_ERROR
);
export const setCeilingLimitError = createAction<boolean>(
  SET_CEILING_LIMIT_ERROR
);

export const handleNumberOfLicensesChange =
  (value: string): TThunk =>
  (dispatch) => {
    dispatch(setQuantityOfLicenses(value));
    dispatch(setFloorLimitError(false));
    dispatch(setCeilingLimitError(false));
    dispatch(setGetPriceLoading(true));
  };

export const getPriceApiRequest = (
  quantity: number,
  organizationId: string,
  currentQuantity?: number
) => {
  const productId = process.env.REACT_APP_STRIPE_BUSINESS_PLAN_ID as string;

  return callCoreApi<IGetPriceApiProps>({
    method: METHODS.POST,
    url: API_URLS.GET_PRICE,
    authorized: true,
    data: {
      provider_product_id: productId,
      organization_id: organizationId,
      quantity: quantity + (currentQuantity ? currentQuantity : 0),
    },
  });
};

export const getPrice = (): TThunk => (dispatch, getState) => {
  const state = getState();

  const quantity = getQuantityOfLicenses(state);
  const organizationId = getOrganizationId(state);
  const quantityNum = parseInt(quantity, 10);

  dispatch(setLicenseQuantityError(''));

  if (!quantity) {
    dispatch(setTotalPrice(''));
    dispatch(setGetPriceLoading(false));

    return;
  }

  if (quantityNum < 5) {
    dispatch(setTotalPrice(''));
    dispatch(setGetPriceLoading(false));
    dispatch(setFloorLimitError(true));

    return;
  }

  if (quantityNum > 100) {
    dispatch(setTotalPrice(''));
    dispatch(setGetPriceLoading(false));
    dispatch(setCeilingLimitError(true));

    return;
  }

  getPriceApiRequest(quantityNum, organizationId)
    .then((response: AxiosResponse<IGetPriceApiResponse>) => {
      dispatch(
        setTotalPrice(response?.data?.amount_due_next_period?.toString())
      );
    })
    .finally(() => {
      dispatch(setGetPriceLoading(false));
    });
};

export const handlePaymentStart = (): TThunk => (dispatch) => {
  dispatch(setPurchaseLoading(true));
  dispatch(setPaymentError(''));
  dispatch(setLicenseQuantityError(''));
};

export const handleQuantityValidationError = (): TThunk => (dispatch) => {
  dispatch(setLicenseQuantityError(ERROR_TEXTS.BLANK_FIELD));
  dispatch(setPurchaseLoading(false));
};

export const handlePaymentComplete =
  (paymentMethod: PaymentMethod): TThunk =>
  (dispatch, getState) => {
    const state = getState();

    const quantity = getQuantityOfLicenses(state);
    const organizationId = getOrganizationId(state);

    if (!quantity) {
      dispatch(setLicenseQuantityError(ERROR_TEXTS.BLANK_FIELD));
      dispatch(setPurchaseLoading(false));

      return;
    }

    const productId = process.env.REACT_APP_STRIPE_BUSINESS_PLAN_ID as string;
    const quantityNum = parseInt(quantity, 10);

    callCoreApi<ICompletePurchaseApiProps>({
      method: METHODS.POST,
      url: API_URLS.COMPLETE_PURCHASE,
      authorized: true,
      data: {
        organization_id: organizationId,
        provider_product_id: productId,
        payment_method_id: paymentMethod.id,
        quantity: quantityNum,
      },
    })
      .then(() => {
        dispatch(onPaymentSuccess());
      })
      .catch((error: AxiosError<ICompletePurchaseApiError>) => {
        const errorMessage =
          error.response?.data?.detail || ERROR_TEXTS.SOMETHING_WENT_WRONG;

        dispatch(setPaymentError(errorMessage));
      })
      .finally(() => {
        dispatch(setPurchaseLoading(false));
      });
  };

export const onPaymentSuccess = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const refreshToken = getRefreshToken(state);

  if (!refreshToken) {
    return;
  }

  refreshTokenRequest(refreshToken).then(
    (response: AxiosResponse<TSignInResponse>) => {
      dispatch(setAuthData(response.data));
    }
  );
};
