import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getLicenseeEmail = (state: IReduxState) =>
  state?.[NAME]?.licenseeEmail;

export const getLicenseeEmailError = (state: IReduxState) =>
  state?.[NAME]?.licenseeEmailError;

export const isPopupVisible = (state: IReduxState) =>
  state?.[NAME]?.isPopupVisible;

export const isLimitErrorVisible = (state: IReduxState) =>
  state?.[NAME]?.isLimitErrorVisible;

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;
