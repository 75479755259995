import { useState } from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';
import { ReactComponent as Visible } from 'assets/password_visible.svg';
import { ReactComponent as Invisible } from 'assets/password_invisible.svg';
import { ReactComponent as Search } from 'assets/search.svg';

type TInputType = 'password' | 'number' | 'email' | 'text';

interface IInput {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  type?: TInputType;
  error?: string;
  isSearch?: boolean;
  min?: number;
}

interface IErrorProp {
  error?: string;
}

type TInput = IInput & IErrorProp;

const Container = styled.div<IErrorProp>((props) => ({
  borderBottom: `1px solid ${
    !!props.error ? props.theme.colors.error : 'rgba(45, 45, 45, 0.25)'
  }`,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
}));

const Wrapper = styled.div({
  width: '100%',
  marginTop: '8px',
  marginBottom: '8px',
});

const Label = styled.p({
  color: 'rgba(45, 45, 45, 0.7)',
  fontSize: '10px',
  margin: 0,
  height: '16px',
});

const InputContainer = styled.div({
  width: '100%',
});

const StyledInput = styled.input((props) => ({
  border: 'none',
  background: 'transparent',
  fontSize: '15px',
  marginBottom: '8px',
  outline: 'none',
  width: '100%',
  color: props.theme.colors.darkText,
  '::placeholder': {
    color: 'rgba(45, 45, 45, 0.7)',
  },
}));

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
}));

const StyledSearch = styled(Search)({
  alignSelf: 'flex-end',
  paddingBottom: 8,
  paddingRight: 8,
});

const TogglePassword = styled.span({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-end',
  padding: '0 0 8px 8px',
});

const Input: React.FC<TInput> = ({
  onChange,
  value,
  type,
  placeholder,
  error,
  isSearch,
  min,
  ...rest
}) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);

  const onInputFocus = () => {
    setInputFocused(true);
  };

  const onInputBlur = () => {
    setInputFocused(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onPasswordToggleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setPasswordVisibility(!isPasswordVisible);
  };

  const isPassword = type === 'password';

  const inputType = isPassword && isPasswordVisible ? 'text' : type;

  return (
    <Wrapper {...rest}>
      <Container error={error}>
        {isSearch && <StyledSearch />}
        <InputContainer>
          <Label>{(isInputFocused || value) && placeholder}</Label>
          <StyledInput
            onChange={handleChange}
            value={value}
            type={inputType}
            placeholder={isInputFocused ? '' : placeholder}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            min={min}
          />
        </InputContainer>
        {isPassword && (
          <TogglePassword onClick={onPasswordToggleClick}>
            {isPasswordVisible ? <Visible /> : <Invisible />}
          </TogglePassword>
        )}
      </Container>
      {!!error && <Error type="small">{error}</Error>}
    </Wrapper>
  );
};

export default Input;
