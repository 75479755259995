import { CATEGORIES } from 'utils/constants';

export const NAME = 'contentUsage';

export const colors = {
  [CATEGORIES.SLEEP]: '#3F73A1',
  [CATEGORIES.MEDITATION]: '#2CBCDC',
  [CATEGORIES.BODY]: '#8CB9DE',
  [CATEGORIES.MUSIC]: '#0A171D',
  [CATEGORIES.PSYCHOLOGY]: '#789FFE',
};
