import { connect } from 'react-redux';
import styled from '@emotion/styled';

import Button from 'components/Button';
import { TDispatch } from 'types/common';
import { IReduxState } from 'state/types';

import PurchasePopup from './PurchasePopup';
import { setPurchasePopupVisible } from '../actions';

interface IMapDispatchToProps {
  onClick: () => void;
}

type TExtraLicensesPurchase = IMapDispatchToProps;

const StyledButton = styled(Button)({
  width: 170,
  marginRight: 16,
});

const ExtraLicensesPurchase: React.FC<TExtraLicensesPurchase> = ({
  onClick,
}) => (
  <>
    <StyledButton
      onClick={onClick}
      text="Buy extra licenses"
      buttonType="light"
    />
    <PurchasePopup />
  </>
);

const mapDispatchToProps = (dispatch: TDispatch) => ({
  onClick: () => dispatch(setPurchasePopupVisible(true)),
});

export default connect<unknown, IMapDispatchToProps, unknown, IReduxState>(
  null,
  mapDispatchToProps
)(ExtraLicensesPurchase);
