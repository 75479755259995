import { connect } from 'react-redux';
import styled from '@emotion/styled';

import FormContainer from 'components/FormContainer';
import Text from 'components/Text';
import Input from 'components/Input';
import Link from 'components/Link';
import Button from 'components/Button';
import StepsIndicator from 'components/StepsIndicator';
import { IReduxState } from 'state/types';
import { ROUTES } from 'navigation/constants';

import {
  getCompanyName,
  getEmail,
  getFirstName,
  getPassword,
  getLastName,
  getCompanyNameError,
  getEmailError,
  getFirstNameError,
  getLastNameError,
  getPasswordError,
  isLoading,
} from '../selectors';
import {
  setCompanyName,
  setEmail,
  setFirstName,
  setLastName,
  setPassword,
  handleRegistration,
} from '../actions';

interface IMapStateToProps {
  companyName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  lastNameError: string;
  firstNameError: string;
  emailError: string;
  passwordError: string;
  companyNameError: string;
  isButtonDisabled: boolean;
}

interface IMapDispatchToProps {
  onFirstNameChange: (value: string) => void;
  onEmailChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
  onCompanyNameChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onClick: () => void;
}

type TRegistration = IMapStateToProps & IMapDispatchToProps;

const StyledText = styled(Text)({
  opacity: 0.8,
  fontWeight: 400,
  marginTop: 12,
});

const StyledButton = styled(Button)({
  maxWidth: 149,
});

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 24,
});

const SignInText = styled(Text)({
  marginRight: 5,
});

const SignInLink = styled.div({
  marginTop: 16,
  display: 'flex',
});

const StyledLink = styled(Link)((props) => ({
  whiteSpace: 'nowrap',
  color: props.theme.colors.linkBlue,
}));

const Registration: React.FC<TRegistration> = ({
  companyName,
  email,
  password,
  firstName,
  lastName,
  firstNameError,
  lastNameError,
  companyNameError,
  emailError,
  passwordError,
  isButtonDisabled,
  onCompanyNameChange,
  onEmailChange,
  onFirstNameChange,
  onLastNameChange,
  onPasswordChange,
  onClick,
}) => (
  <FormContainer>
    <StepsIndicator steps={2} currentStep={1} />
    <Text type="h1">Hi, let&apos;s get you set up!</Text>
    <StyledText>
      Important: Your email and company name will be used to create your partner
      and admin login for Your Partner Portal.
    </StyledText>
    <Input
      placeholder="First name"
      value={firstName}
      onChange={onFirstNameChange}
      error={firstNameError}
    />
    <Input
      placeholder="Last name"
      value={lastName}
      onChange={onLastNameChange}
      error={lastNameError}
    />
    <Input
      placeholder="Company name"
      value={companyName}
      onChange={onCompanyNameChange}
      error={companyNameError}
    />
    <Input
      placeholder="Email"
      type="email"
      value={email}
      onChange={onEmailChange}
      error={emailError}
    />
    <Input
      placeholder="Password"
      type="password"
      value={password}
      onChange={onPasswordChange}
      error={passwordError}
    />
    <SignInLink>
      <SignInText>Do you have an account?</SignInText>
      <StyledLink to={ROUTES.LOGIN}>Sign in</StyledLink>
    </SignInLink>
    <ButtonContainer>
      <StyledButton
        text="Next step"
        onClick={onClick}
        isDisabled={isButtonDisabled}
      />
    </ButtonContainer>
  </FormContainer>
);

const mapStateToProps = (state: IReduxState) => ({
  companyName: getCompanyName(state),
  email: getEmail(state),
  firstName: getFirstName(state),
  lastName: getLastName(state),
  password: getPassword(state),
  lastNameError: getLastNameError(state),
  firstNameError: getFirstNameError(state),
  emailError: getEmailError(state),
  companyNameError: getCompanyNameError(state),
  passwordError: getPasswordError(state),
  isButtonDisabled: !!isLoading(state),
});

const mapDispatchToProps = {
  onFirstNameChange: setFirstName,
  onEmailChange: setEmail,
  onLastNameChange: setLastName,
  onCompanyNameChange: setCompanyName,
  onPasswordChange: setPassword,
  onClick: handleRegistration,
};

export default connect<
  IMapStateToProps,
  IMapDispatchToProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(Registration);
