import styled from '@emotion/styled';

import { useTitles } from 'navigation/hooks';

import SummaryStats from 'features/MoodTracker/components/SummaryStats';
import TeamsQuestions from 'features/MoodTracker/components/TeamsQuestions';
import EmployeesMoods from 'features/MoodTracker/components/EmployeesMoods';
import Circumstances from 'features/MoodTracker/components/Circumstances';

import { TEAM_QUESTIONS } from 'features/MoodTracker/constants';

const Container = styled.div({
  display: 'grid',
  gridRowGap: 24,
  marginBottom: 96,
});

const MoodTracker: React.FC = () => {
  useTitles('Mood tracker', 'Overview of reported moods by users');

  return (
    <Container>
      <SummaryStats />
      <TeamsQuestions
        question={TEAM_QUESTIONS.MENTAL}
        title={`Your Team's Mood`}
      />
      <TeamsQuestions
        question={TEAM_QUESTIONS.HEALTH}
        title={`Your Team's Health`}
      />
      <EmployeesMoods />
      <Circumstances />
    </Container>
  );
};

export default MoodTracker;
