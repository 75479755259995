import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from 'navigation/constants';

import { IReduxState } from 'state/types';
import { isAuthenticated, isAuthLoading } from 'features/Auth/selectors';
import { userHasSubscription } from 'features/Subscription/selectors';
import Header from 'features/Header/components/FixedHeader';

const Container = styled.div({});

interface IMapStateToProps {
  isAuthenticated: boolean;
  userHasSubscription: boolean;
  isAuthFinished: boolean;
}

type TPurchaseRoute = IMapStateToProps;

const PurchaseLayout: React.FC<TPurchaseRoute> = ({
  isAuthenticated,
  isAuthFinished,
  userHasSubscription,
}) => {
  const location = useLocation();

  if (isAuthFinished && !isAuthenticated) {
    return (
      <Navigate to={ROUTES.REGISTRATION} state={{ from: location }} replace />
    );
  }

  if (isAuthFinished && isAuthenticated && userHasSubscription) {
    return <Navigate to={ROUTES.MAIN} state={{ from: location }} replace />;
  }

  return (
    <Container>
      <Header />
      <Outlet />
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  isAuthenticated: isAuthenticated(state),
  isAuthFinished: !isAuthLoading(state),
  userHasSubscription: userHasSubscription(state) === true,
});

export default connect<IMapStateToProps, unknown, unknown, IReduxState>(
  mapStateToProps
)(PurchaseLayout);
