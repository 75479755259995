import styled from '@emotion/styled';

export default styled.form((props) => ({
  backgroundColor: props.theme.colors.formBackgroundGray,
  padding: '48px 70px',
  minHeight: 599,
  width: '100%',
  maxWidth: 364,
  margin: '120px 20px',
  borderRadius: 20,
  boxShadow: '0px 1px 40px rgba(24, 24, 24, 0.08)',
}));
