import { createAction } from 'redux-actions';
import { TThunk } from 'types/common';
import { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { API_URLS, callCoreApi, METHODS } from 'api';
import { IAddLicenseeApiProps, ILicensee } from 'features/Licensees/types';
import { setLicensees } from 'features/Licensees/actions';
import { getLicenseCount, getLicenseLimit } from 'features/Auth/selectors';
import { setLicenseCount } from 'features/Auth/actions';
import { setPurchasePopupVisible } from 'features/ExtraLicensesPurchase/actions';
import { ERROR_TEXTS } from 'utils/constants';
import { isEmailValid } from 'utils/helpers';

import { getLicenseeEmail, isLoading } from './selectors';
import { IInviteLicenseeApiError } from './types';
import {
  SET_LOADING,
  SET_LIMIT_ERROR_VISIBLE,
  SET_POPUP_VISIBLE,
  SET_LICENSEE_EMAIL,
  SET_LICENSEE_EMAIL_ERROR,
} from './actionTypes';

export const setLoading = createAction<boolean>(SET_LOADING);
export const setLicenseeEmail = createAction<string>(SET_LICENSEE_EMAIL);
export const setPopupVisible = createAction<boolean>(SET_POPUP_VISIBLE);
export const setLicenseeEmailError = createAction<string>(
  SET_LICENSEE_EMAIL_ERROR
);
export const setLimitErrorVisible = createAction<boolean>(
  SET_LIMIT_ERROR_VISIBLE
);

export const handleInviteLicenseeButtonClick =
  (): TThunk => (dispatch, getState) => {
    const state = getState();

    const licenseLimit = getLicenseLimit(state);
    const licenseCount = getLicenseCount(state);

    if (licenseCount >= licenseLimit) {
      dispatch(setLimitErrorVisible(true));
    }

    dispatch(setPopupVisible(true));
  };

export const handlePopupClose = (): TThunk => (dispatch) => {
  dispatch(setPopupVisible(false));
  dispatch(setLimitErrorVisible(false));
};

export const handleBuyExtraClick = (): TThunk => (dispatch) => {
  dispatch(setPopupVisible(false));
  dispatch(setPurchasePopupVisible(true));
};

export const addNewLicensee = (): TThunk => (dispatch, getState) => {
  const state = getState();
  const email = getLicenseeEmail(state);

  if (isLoading(state)) {
    return;
  }

  if (!isEmailValid(email)) {
    dispatch(setLicenseeEmailError(ERROR_TEXTS.VALID_EMAIL));

    return;
  }

  dispatch(setLicenseeEmailError(''));
  dispatch(setLoading(true));

  callCoreApi<IAddLicenseeApiProps>({
    method: METHODS.POST,
    url: API_URLS.LICENSEES,
    authorized: true,
    data: { email },
  })
    .then((response: AxiosResponse<ILicensee>) => {
      const formattedData = {
        uid: response.data.id,
        ...response.data,
      };

      dispatch(setLicensees([formattedData]));
      dispatch(setPopupVisible(false));
      dispatch(setLicenseeEmail(''));
      dispatch(setLicenseCount(getLicenseCount(state) + 1));
    })
    .catch((error: AxiosError<IInviteLicenseeApiError>) => {
      if (error?.response?.data?.detail) {
        dispatch(setLicenseeEmailError(ERROR_TEXTS.ASYNC_VALID_EMAIL));
      } else {
        toast.error(ERROR_TEXTS.SOMETHING_WENT_WRONG);
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
