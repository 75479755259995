import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const getQuantityOfLicenses = (state: IReduxState) =>
  state?.[NAME]?.quantityOfLicenses || '';

export const getTotalPrice = (state: IReduxState) =>
  state?.[NAME]?.totalPrice || '';

export const isGetPriceLoading = (state: IReduxState) =>
  state?.[NAME]?.isGetPriceLoading;

export const getLicenseQuantityError = (state: IReduxState) =>
  state?.[NAME]?.licenseQuantityError || '';

export const isFloorLimitError = (state: IReduxState) =>
  state?.[NAME]?.isFloorLimitError;

export const isCeilingLimitError = (state: IReduxState) =>
  state?.[NAME]?.isCeilingLimitError;

export const getPaymentError = (state: IReduxState) =>
  state?.[NAME]?.paymentError || '';

export const isPurchaseLoading = (state: IReduxState) =>
  state?.[NAME]?.isPurchaseLoading;
