import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CUSTOM_ERROR_CODES } from 'api';
import { ERROR_TEXTS } from 'utils/constants';
import { isEmailValid } from 'utils/helpers';

import { useSendPasswordResetEmail } from './queries';
import { ISendPasswordResetEmailLocationState } from './types';

export const useSendPasswordResetEmailState = () => {
  const location = useLocation();
  const { resend = false } = (location.state ||
    {}) as ISendPasswordResetEmailLocationState;

  const [email, setEmail] = useState('');
  const [asyncError, setAsyncError] = useState('');
  const [emailError, setEmailError] = useState('');

  const {
    mutate: sendPasswordResetEmail,
    isSuccess,
    isLoading,
  } = useSendPasswordResetEmail();

  const handleSubmit = useCallback(() => {
    setAsyncError('');
    setEmailError('');

    if (!email) return setEmailError(ERROR_TEXTS.EMAIL_REQUIRED);

    if (!isEmailValid(email)) return setEmailError(ERROR_TEXTS.VALID_EMAIL);

    sendPasswordResetEmail(email, {
      onError: (error) =>
        setAsyncError(
          error?.response?.data.custom_status_code ===
            CUSTOM_ERROR_CODES.STATUS_VALIDATION_ERROR
            ? error.response?.data.errors?.email?.[0] ?? ''
            : ERROR_TEXTS.SOMETHING_WENT_WRONG
        ),
    });
  }, [email, sendPasswordResetEmail, setAsyncError, setEmailError]);

  return {
    isSuccess,
    resend,
    email,
    setEmail,
    emailError,
    asyncError,
    handleSubmit,
    isLoading,
  };
};
