import styled from '@emotion/styled';
import { connect } from 'react-redux';

import Text from 'components/Text';
import TeamLogo from 'components/TeamLogo';
import { IReduxState } from 'state/types';

import { getTeamName } from '../selectors';
import { useParams } from 'react-router-dom';

interface IMapStateToProps {
  teamName: string;
}

type TTeamIndicator = IMapStateToProps;

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TeamIndicator: React.FC<TTeamIndicator> = () => {
  const { teamName } = useParams();

  if (!teamName) {
    return null;
  }

  return (
    <Container>
      <TeamLogo teamName={teamName} />
      <Text>{teamName}</Text>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  teamName: getTeamName(state),
});

export default connect(mapStateToProps)(TeamIndicator);
