import { connect } from 'react-redux';

import {
  PriceCalculatorResult,
  TPriceCalculatorResult,
} from 'components/PriceCalculator';
import { IReduxState } from 'state/types';
import { TWithHideableProp } from 'utils/hideable';

import {
  getQuantityOfLicenses,
  getPrice,
  isGetPriceLoading,
} from '../selectors';

const mapStateToProps = (state: IReduxState) => {
  const quantityOfLicenses = getQuantityOfLicenses(state);

  return {
    totalPrice: `$ ${getPrice(state)}`,
    quantityOfLicenses,
    isVisible: !!quantityOfLicenses,
    isCalculatorLoading: isGetPriceLoading(state),
    quantityTitle: 'Number of people covered',
    priceTitle: 'Total',
  };
};

export default connect<
  TWithHideableProp<TPriceCalculatorResult>,
  unknown,
  unknown,
  IReduxState
>(mapStateToProps)(PriceCalculatorResult);
