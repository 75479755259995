import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';

import { updateSimpleValue } from 'utils/reducerHelpers';

import { IStatsSelectedTeam } from './types';
import { SET_SELECTED_TEAM } from './actionTypes';

const selectedTeamReducer = handleAction<string, string>(
  SET_SELECTED_TEAM,
  updateSimpleValue,
  'all'
);

export default combineReducers<IStatsSelectedTeam>({
  selectedTeam: selectedTeamReducer,
});
