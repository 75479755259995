interface IMenuArrow {
  fill: string;
}

const MenuArrow: React.FC<IMenuArrow> = ({ fill }) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="arrow down"
      d="M5.49505 5.78413L9.79476 1.25827C10.0684 0.970376 10.0684 0.503597 9.79476 0.215837C9.52135 -0.0719452 9.0779 -0.0719452 8.80451 0.215837L4.99993 4.22052L1.19548 0.215953C0.921955 -0.0718291 0.478552 -0.0718291 0.205141 0.215953C-0.0683805 0.503736 -0.0683805 0.970492 0.205141 1.25839L4.50491 5.78424C4.64169 5.92814 4.82075 6 4.99991 6C5.17915 6 5.35835 5.928 5.49505 5.78413Z"
      fill={fill}
    />
  </svg>
);

export default MenuArrow;
