import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Loader from 'components/Loader';
import React from 'react';

interface IButton {
  onClick: () => void;
  text: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonType?: TButtonType;
}

interface IDisabledProps {
  disabled?: boolean;
}

interface IStyleProps {
  buttonType: TButtonType;
}

type TButtonType = 'primary' | 'secondary' | 'light';

interface IGetStyleByType {
  type?: TButtonType;
  theme: Theme;
}

export const getStyleByType = ({ type = 'primary', theme }: IGetStyleByType) =>
  ({
    primary: {
      border: 'none',
      backgroundColor: theme.buttons.primary.background,
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.darkBlue,
      },
    },
    secondary: {
      border: `1px solid ${theme.colors.white}`,
      background: 'transparent',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.white,
        color: theme.colors.mainBlack,
      },
    },
    light: {
      border: `1px solid ${theme.colors.extraLightGray}`,
      color: theme.colors.lightGray,
      background: 'transparent',
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.colors.extraLightGray,
      },
    },
  }[type]);

const getLoaderColorByType = ({ type = 'primary', theme }: IGetStyleByType) =>
  ({
    primary: theme.colors.white,
    secondary: theme.colors.mainBlack,
    light: theme.colors.lightGray,
  }[type]);

const StyledButton = styled.button<IDisabledProps & IStyleProps>((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '46px',
  borderRadius: 10,
  outline: 'none',
  width: '100%',
  fontSize: '16px',
  fontFamily: 'Poppins',
  opacity: props.disabled ? '60%' : '',
  color: props.theme.buttons?.[props.buttonType]?.text,
  border: props.theme.buttons?.[props.buttonType]?.border,
  backgroundColor: props.theme.buttons?.[props.buttonType]?.background,
  transition: 'all 300ms',
  ':hover': {
    cursor: 'pointer',
    color: props.theme.buttons?.[props.buttonType]?.hoverText,
    backgroundColor: props.theme.buttons?.[props.buttonType]?.hoverBackground,
    border: props.theme.buttons?.[props.buttonType]?.hoverBorder,
  },
}));

const Button: React.FC<IButton> = ({
  onClick,
  text,
  isDisabled,
  isLoading,
  buttonType = 'primary',
  ...rest
}) => {
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    !!e && e.preventDefault();

    onClick();
  };

  return (
    <StyledButton
      buttonType={buttonType}
      onClick={handleClick}
      disabled={isDisabled || isLoading}
      {...rest}
    >
      {isLoading ? (
        <Loader color={getLoaderColorByType({ type: buttonType, theme })} />
      ) : (
        text
      )}
    </StyledButton>
  );
};

export default Button;
