import styled from '@emotion/styled';

import { ICircumstance } from '../../types';

interface ICircumstanceIcon {
  circumstance: ICircumstance;
}

const IconContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 120,
});

const Icon = styled.img({
  width: 62,
  height: 62,
  objectFit: 'cover',
});

const IconLabel = styled.span(({ theme }) => ({
  fontSize: 14,
  lineHeight: '16px',
  marginTop: 8,
  color: theme.colors.textGray,
  textAlign: 'center',
}));

const CircumstancesIcon: React.FC<ICircumstanceIcon> = ({ circumstance }) => (
  <IconContainer>
    {circumstance.icon && <Icon src={circumstance.icon} />}
    <IconLabel>{circumstance.label}</IconLabel>
  </IconContainer>
);

export default CircumstancesIcon;
