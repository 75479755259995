import { values } from 'ramda';

import { IReduxState } from 'state/types';
import { NAME } from './constants';

export const isLoading = (state: IReduxState) => state?.[NAME]?.isLoading;

export const getLicenseesUids = (state: IReduxState) =>
  state?.[NAME]?.licenseesUids || [];

export const getLicenseesByUidObjectValues = (state: IReduxState) =>
  values(state?.[NAME]?.licenseesByUid || {});

export const getLicenseesByUidObject = (state: IReduxState) =>
  state?.[NAME]?.licenseesByUid || {};

export const getLicenseeEmailByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.licenseesByUid?.[uid]?.email || '';

export const getLicenseeStatusByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.licenseesByUid?.[uid]?.status || 'inactive';

export const getLicenseeCreatedAtByUid = (state: IReduxState, uid: string) =>
  state?.[NAME]?.licenseesByUid?.[uid]?.created_at || '';
