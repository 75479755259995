import { NAME } from './constants';

export const SET_EMAIL = `${NAME}.SET_EMAIL`;
export const SET_FIRST_NAME = `${NAME}.SET_FIRST_NAME`;
export const SET_LAST_NAME = `${NAME}.SET_LAST_NAME`;
export const SET_COMPANY_NAME = `${NAME}.SET_COMPANY_NAME`;
export const SET_PASSWORD = `${NAME}.SET_PASSWORD`;

export const SET_EMAIL_ERROR = `${NAME}.SET_EMAIL_ERROR`;
export const SET_FIRST_NAME_ERROR = `${NAME}.SET_FIRST_NAME_ERROR`;
export const SET_LAST_NAME_ERROR = `${NAME}.SET_LAST_NAME_ERROR`;
export const SET_COMPANY_NAME_ERROR = `${NAME}.SET_COMPANY_NAME_ERROR`;
export const SET_PASSWORD_ERROR = `${NAME}.SET_PASSWORD_ERROR`;

export const SET_LOADING = `${NAME}.SET_LOADING`;
export const RESET_STATE = `${NAME}.RESET_STATE`;
