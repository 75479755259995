import { connect } from 'react-redux';

import { IReduxState } from 'state/types';
import {
  PriceCalculatorInput,
  IInputDispatchProps,
  IInputStateProps,
} from 'components/PriceCalculator';

import { getQuantityOfLicenses, getLicenseQuantityError } from '../selectors';
import { getPrice, handleNumberOfLicensesChange } from '../actions';

const mapStateToProps = (state: IReduxState) => ({
  value: getQuantityOfLicenses(state),
  title: 'Plan Details',
  inputError: getLicenseQuantityError(state),
});

const mapDispatchToProps = {
  onChange: handleNumberOfLicensesChange,
  onDebounce: getPrice,
};

export default connect<
  IInputStateProps,
  IInputDispatchProps,
  unknown,
  IReduxState
>(
  mapStateToProps,
  mapDispatchToProps
)(PriceCalculatorInput);
