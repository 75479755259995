import styled from '@emotion/styled';

import Text from 'components/Text';

interface ITooltip {
  date?: string;
  value?: number;
  valueExtension?: string;
}

const TooltipContainer = styled.div((props) => ({
  backgroundColor: props.theme.colors.black,
  borderRadius: 4,
  padding: '5px 8px',
}));

const StyledText = styled(Text)((props) => ({
  color: props.theme.colors.white,
  fontSize: 12,
  lineHeight: '16px',
  letterSpacing: 0.1,
  fontWeight: 700,
}));

const DateText = styled(StyledText)((props) => ({
  fontSize: 10,
  color: props.theme.navigationColors.activeLinkBackground,
  opacity: 0.8,
}));

const Tooltip: React.FC<ITooltip> = ({ date, value, valueExtension }) => (
  <TooltipContainer>
    <StyledText>
      {value}
      {valueExtension}
    </StyledText>
    <DateText>{date}</DateText>
  </TooltipContainer>
);

export default Tooltip;
