import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { API_URLS, callCoreApi, METHODS } from 'api';
import { PERIODS } from 'utils/constants';
import { getDatesByPeriod } from 'utils/helpers';
import getDataAggregationByPeriod from 'utils/getDataAggregationByPeriod';
import formatPeriodData from 'utils/formatPeriodData';

import { IUserActivityApiResponse, IActiveUsersApiResponse } from './types';

export const useUserActivity = (period: PERIODS, selectedTeam?: string) =>
  useQuery(['userActivity', period, selectedTeam], () => {
    const periodDates = getDatesByPeriod(period);

    return callCoreApi({
      method: METHODS.GET,
      url: API_URLS.ACTIVITY_BY_PERIOD,
      queryParams: [
        { key: 'date_from', value: periodDates?.from || '' },
        { key: 'date_to', value: periodDates?.to || '' },
        { key: 'aggregation', value: getDataAggregationByPeriod(period) },
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<IUserActivityApiResponse[]>) =>
      formatPeriodData(period, response.data, 'date')
    );
  });

export const useTotalNumbers = (selectedTeam?: string) =>
  useQuery(['totalNumbers', selectedTeam], () =>
    callCoreApi({
      method: METHODS.GET,
      url: API_URLS.TOTAL_ACTIVITY,
      queryParams: [
        ...(selectedTeam ? [{ key: 'team', value: selectedTeam }] : []),
      ],
      authorized: true,
    }).then((response: AxiosResponse<IActiveUsersApiResponse>) => ({
      totalActiveUsers: response.data.total_active_users,
      dailyActiveUsers: response.data.daily_active_users,
      monthlyActiveUsers: response.data.monthly_active_users,
    }))
  );
