import { isDev } from 'utils/constants';

export const CORE_API = isDev ? '/v1' : 'https://api.yoursapp.com/v1';

export const STATUS_CODES = {
  NOT_FOUND: 404,
  UNAUTHORIZED: 401,
  HANDLED_ERROR: 400,
};

export const CUSTOM_ERROR_CODES = {
  STATUS_DEFAULT: 1000,
  STATUS_VALIDATION_ERROR: 1001,
  STATUS_AUTH_FAILED: 1002,
  STATUS_INVALID_TOKEN: 1003,
  STATUS_ACCOUNT_IS_NOT_VERIFIED: 1004,
  STATUS_VERIFICATION_TOKEN_IS_EXPIRED: 1005,
  STATUS_ACCOUNT_IS_ALREADY_VERIFIED: 1006,
  STATUS_RESET_PASSWORD_TOKEN_IS_EXPIRED: 1007,
} as const;

export enum METHODS {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export enum API_URLS {
  LOGIN = '/auth/login/token/',
  REFRESH_TOKEN = '/auth/login/token/refresh/',
  VERIFY_TOKEN = '/auth/login/token/verify/',
  AUTH_CODE_SIGNIN = '/auth/social/complete/',
  REGISTER = '/business/register/',
  LICENSEES = '/business/licenses/',
  VERIFY_ACCOUNT = '/users/verify/',
  SEND_PASSWORD_RESET_EMAIL = '/users/reset-password/',
  CHANGE_PASSWORD = '/users/change-password/',
  RESET_PASSWORD = '/users/reset-password/confirm/',
  VERIFY_PASSWORD_RESET_TOKEN = '/users/reset-password/token/verify/',
  RESEND_VERIFICATION_TOKEN = '/users/verification-token/',
  PLANS = '/payments/provider-products/',
  STRIPE_SESSION = '/payments/stripe/session/',
  GET_PRICE = '/payments/stripe/upcomming-invoice/',
  COMPLETE_PURCHASE = '/payments/stripe/subscriptions/',
  TOTAL_ACTIVITY = '/business/stats/user-activity/',
  ACTIVITY_BY_PERIOD = '/business/stats/user-activity/period/',
  CONTENT_USAGE_BY_PERIOD = '/business/stats/content-usage/period/',
  CATEGORY_USAGE = '/business/stats/content-usage/category/',
  SUBCATEGORY_USAGE = '/business/stats/content-usage/sub-category/',
  PLAYLISTS_USAGE = '/business/stats/content-usage/playlist/',
  MEDIA_USAGE = '/business/stats/content-usage/media/',
  TEAMS = '/business/teams/',
  SUMMARY_STATS = '/business/stats/mood-checker/summary',
  CIRCUMSTANCES = '/business/stats/mood-checker/circumstances',
  CIRCUMSTANCES_ICONS = '/mood-checker/circumstances',
  EMPLOYEES_MOODS = '/business/stats/mood-checker/moods',
  MOODS_ICONS = '/mood-checker/moods',
  MOODS_ANSWERS = '/business/stats/mood-checker/answers',
  MOODS_QUESTIONS = '/mood-checker/questions',
}
