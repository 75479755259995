import FullScreenWrapper from 'components/FullScreenWrapper';
import PasswordResetForm from 'features/PasswordReset';

const SendPasswordReset: React.FC = () => (
  <FullScreenWrapper>
    <PasswordResetForm />
  </FullScreenWrapper>
);

export default SendPasswordReset;
