import SignInFrom from 'features/SignIn/components';
import FullScreenWrapper from 'components/FullScreenWrapper';

const SignIn: React.FC = () => (
  <FullScreenWrapper>
    <SignInFrom />
  </FullScreenWrapper>
);

export default SignIn;
