import { NAME } from './constants';

export const SET_TEAMS = `${NAME}.SET_TEAMS`;
export const RESET_TEAMS = `${NAME}.RESET_TEAMS`;
export const DELETE_TEAM = `${NAME}.DELETE_TEAM`;

export const SET_SEARCH_VALUE = `${NAME}.SET_SEARCH_VALUE`;
export const SET_LOADING = `${NAME}.SET_LOADING`;
export const SET_CREATE_TEAM_MODAL_VISIBLE = `${NAME}.SET_CREATE_TEAM_MODAL_VISIBLE`;
export const SET_NEW_TEAM_NAME = `${NAME}.SET_NEW_TEAM_NAME`;
